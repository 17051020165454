import Vue from 'vue'
import VueRouter from 'vue-router'
import VueWechatTitle from 'vue-wechat-title'
import Home from '../views/Home.vue'
import Doc from '../views/Doc.vue'
import QmlBookEN from '../views/doc/qmlbook/en/QmlBookEN.vue'
import QmlBookCN from '../views/doc/qmlbook/cn/QmlBookCN.vue'
import Qt from '../views/doc/qt/Qt.vue'
import Download from '../views/download/Download.vue'
import QmlOnline from '../views/tool/qmlonline/QmlOnline.vue'
import RegularExpression from '../views/tool/regularexpression/RegularExpression.vue'
import RegexpCode from '../views/tool/RegexpCode/RegexpCode.vue'
import Tetrix from '../views/qtwebassembly/example/Tetrix.vue'
import HttpClient from '../views/doc/httpclient/HttpClient.vue'
import WindowTop from '../views/tool/WindowTop/WindowTop.vue'

Vue.use(VueRouter)
Vue.use(VueWechatTitle)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: "Qt小站"
    }
  },
  {
    path: '/doc',
    name: 'Doc',
    component: Doc,
    meta: {
      title: "文档"
    }
  },
  {
    path: '/doc/QmlBook/cn/',
    name: 'QmlBook中文版',
    component: QmlBookCN,
    meta: {
      title: "QmlBook中文版"
    }
  },
  {
    path: '/doc/QmlBook/en/',
    name: 'QmlBook英文原版',
    component: QmlBookEN,
    meta: {
      title: "QmlBook英文原版"
    }
  },
  {
    path: '/doc/Qt/',
    name: 'Qt文档',
    component: Qt,
    meta: {
      title: "Qt文档"
    }
  },
  {
    path: '/download',
    name: 'Download',
    component: Download,
    meta: {
      title: "资源下载"
    }
  },
  {
    path: '/tool/qmlonline',
    name: 'QmlOnline',
    component: QmlOnline,
    meta: {
      title: "Qml在线预览工具"
    }
  },
  {
    path: '/tool/regularexpression',
    name: 'RegularExpression',
    component: RegularExpression,
    meta: {
      title: "正则测试工具"
    }
  },
  {
    path: '/tool/RegExpCode',
    name: 'RegexpCode',
    component: RegexpCode,
    meta: {
      title: "正则代码生成器"
    }
  },
  {
    path: '/qtwebassembly/example/tetrix',
    name: 'Tetrix',
    component: Tetrix,
    meta: {
      title: "俄罗斯方块"
    }
  },
  {
    path: '/tool/WindowTop',
    name: 'WindowTop',
    component: WindowTop,
    meta: {
      title: "窗口置顶工具"
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      title: "关于"
    }
  },
  {
    path: '/doc/httpclient',
    name: 'HttpClient',
    component: HttpClient,
    meta: {
      title: "HttpClient客户端"
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
