<template>
    <v-container>
      <v-col>
        <a href="https://github.com/aeagean/WindowTop/releases">下载最新版本</a>
        <v-col v-for="item in items" :key="item.content" align-self="center">
          <v-col sm align-self="center">
            <v-img :src="item.img" width="600px" style="margin: 0 auto"></v-img>
            <div>{{item.content}}</div>
          </v-col>
        </v-col>
      </v-col>
    </v-container> 
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        content: "置顶演示",
        img: require("../../../../public/img/窗口置顶工具/置顶演示.gif")
      },
      {
        content: "鼠标穿透演示",
        img: require("../../../../public/img/窗口置顶工具/鼠标穿透演示.gif")
      },
      {
        content: "透明度演示",
        img: require("../../../../public/img/窗口置顶工具/透明度演示.gif")
      },
      {
        content: "拖动演示",
        img: require("../../../../public/img/窗口置顶工具/拖动演示-长版.gif")
      },
      {
        content: "画中画演示",
        img: require("../../../../public/img/窗口置顶工具/画中画开启演示.gif")
      },
    ]
  })
}
</script>