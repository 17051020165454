<template>
    <v-simple-table dense>
        <thead>
            <tr>
                <th class="text-center">文档</th>
                <th class="text-center">描述</th>
            </tr>
        </thead>     
        <tbody>
            <tr v-for="item in items" :key="item">
                <td><router-link v-bind="{to: item.to}">{{item.title}}</router-link></td>
                <td>{{item.detail}}</td>
            </tr>
        </tbody>
    </v-simple-table>        
</template>

<script>
export default {
    data() {
        return {
            items: [
                {
                    title: "Qt官方帮助文档",
                    detail: "Qt 5.15版本帮助手册",
                    to: "/doc/qt/"
                },
                {
                    title: "QmlBook中文版",
                    detail: "中文版",
                    to: "/doc/QmlBook/cn/"
                },
                {
                    title: "QmlBool英文版",
                    detail: "原版",
                    to: "/doc/QmlBook/en/"
                },
            ]
        }
    },
}
</script>

<style lang="scss">
    a {
        text-decoration: none;
    } 
</style>