<template>
    <v-container class="py-8 px-6" style="width: 70%" fluid>
      <v-row v-for="(item, index) in 1" :key="index">
        <v-col no-gutters v-for="(item, index) in demo" :key="index">
          <v-card target="_blank" :href="item.url" max-width="350">
            <v-img :src="item.img" height="180" class="align-end">
                <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="gray lighten-5"></v-progress-circular>
                    </v-row>
                </template>
            </v-img>

            <v-card-title>{{item.title}}</v-card-title>
            <v-card-subtitle align="left">{{item.subtitle}}</v-card-subtitle>
            <v-card-actions>
              <v-btn text>进入</v-btn>
              <v-btn text target="_blank" :href="item.download_url">下载源码</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>

export default {
  data: () => ({
    demo: [
      {
        title: "Qt键盘",
        img: require("../../public/img/QtKeyboard.gif"),
        subtitle: "支持中文输入的键盘，开源的轻量级Qt键盘",
        url: "https://github.com/aeagean/QtKeyboard",
        download_url: "https://github.com/aeagean/QtKeyboard/archive/refs/heads/master.zip"
      },
      {
        title: "Qt打包工具",
        img: require("../../public/img/Qt程序打包工具V1.0.gif"),
        subtitle: "Windows平台下一键生成程序依赖库",
        url: "https://github.com/aeagean/DeployQt/",
        download_url: "https://github.com/aeagean/DeployQt/archive/refs/heads/master.zip"
      },
      {
        title: "日历控件",
        img: require("../../public/img/Calendar.gif"),
        subtitle: "基于Qml实现,支持农历，节假日等信息的日历控件",
        url: "https://github.com/QtComponent/Calendar",
        download_url: "https://github.com/QtComponent/Calendar/archive/refs/heads/master.zip"
      },
      {
        title: "透明无边框窗口",
        img: require("../../public/img/qt_transparent_frameless_widget/demo.gif"),
        subtitle: "设置窗口透明，无边框和拖动",
        url: "https://github.com/aeagean/QtCustomWidget.git",
        download_url: "https://github.com/aeagean/QtCustomWidget/archive/refs/heads/master.zip"
      },
      {
        title: "Http客户端",
        img: require("../../public/img/Http客户端/main_page.png"),
        subtitle: "使用Builder模式封装网络请求接口get, post, put",
        url: "https://github.com/aeagean/QtNetworkService.git",
        download_url: "https://github.com/aeagean/QtNetworkService/archive/refs/heads/master.zip"
      },
      {
        title: "歌词解析器",
        img: require("../../public/img/qt_lyric_parser/1.png"),
        subtitle: "100行左右的歌词解析器",
        url: "https://github.com/aeagean/LyricParser.git",
        download_url: "https://github.com/aeagean/LyricParser/archive/refs/heads/master.zip"
      },
      {
        title: "QmlUI",
        img: require("../../public/img/QmlUI/main_page.png"),
        subtitle: "一套扁平化风格的Qml控件",
        url: "https://github.com/aeagean/QmlUI.git",
        download_url: "https://github.com/aeagean/QmlUI/archive/refs/heads/master.zip"
      },
    ]
  })
}

</script>