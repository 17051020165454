<template>
    <v-container>
        <v-data-table
            :headers="headers"
            :items="items"
            :expanded.sync="expanded"
            :search="search"
            :items-per-page="15"
            @click:row="onRowClicked"
            disable-sort
            class="elevation-1"
            item-key="version"
            show-expand
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Qt版本下载</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        label="search"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-toolbar>
            </template>

            <template v-slot:[`item.preview_list`]="{item}">
                <code class="mr-2" v-for="iter in item.preview_list" :key="iter">{{iter|capitalize}}</code>
            </template>

            <template v-slot:expanded-item="{headers, item}">
                <td :colspan="headers.length">
                    <v-container>
                        <v-row v-for="iter in item.data" :key="iter">
                                <v-col cols="12" sm="4" :key="iter">
                                        <div height="40">
                                            {{iter.file_name}}
                                        </div>
                                </v-col>
                                <v-col cols="12" sm="1" :key="iter">
                                        <div height="40">
                                            {{iter.size_string}}
                                        </div>
                                </v-col>
                                <v-col cols="12" sm="4" :key="iter">
                                        <div height="40">
                                            MD5: {{iter.md5}}
                                        </div>
                                </v-col>
                                <v-col cols="12" sm="1" :key="iter">
                                    <code v-if="iter.platform!='unknow'" >{{iter.platform|platform}}</code>
                                </v-col>
                                <v-col cols="12" sm="2" :key="iter">
                                        <a v-bind="{href: iter.url}">{{iter.type|type}}</a>
                                </v-col>
                        </v-row>
                    </v-container>
                </td>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>

import QtPackageUrlList from './qt_package_url.js'

export default {
    data() {
        return {
            search: '',
            expanded: [],
            singleExpand: false,
            headers: [
                {
                    text: '版本',
                    align: 'start',
                    sortable: false,
                    value: 'version'
                },
                {
                    text: '文件',
                    value: 'preview_list'
                },
                {
                    text: '',
                    value: 'data-table-expand'
                }
            ],
            items: QtPackageUrlList.qt_package_url_list()
        }
    },
    methods: {
        onRowClicked(row, value) {
            value.isExpanded = !value.isExpanded
            console.log(value.isExpanded)
        }
    },
    filters: {
        capitalize: function (value) {
            if (value == "source") {
                return "源码包"
            }
            else if (value == "bin") {
                return "安装包"
            }
            else {
                return "其他文件"
            }
        },
        type: function (value) {
            if (value == "source") {
                return "源码"
            }
            else if (value == "bin") {
                return "安装包"
            }
            else {
                return "其他文件"
            }
        },
        platform: function (value) {
            if (value == "unknow") {
                return ""
            }
            else {
                return value
            }

        }

    }
}
</script>

<style lang="scss">
    a {
        text-decoration: none;
    } 
</style>