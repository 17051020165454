const qt_package_url_list = 
[
    {
        "data": [
            {
                "file_name": "qt-everywhere-src-6.0.3.zip",
                "md5": "8458f776dbeed45d14dfac8c229e56ab",
                "platform": "unknow",
                "size": 237703216,
                "size_string": "227M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/6.0/6.0.3/single/qt-everywhere-src-6.0.3.zip",
                "version": "6.0.3"
            },
            {
                "file_name": "qt-everywhere-src-6.0.3.tar.xz",
                "md5": "605837e0a7212405b76f715bf42af485",
                "platform": "unknow",
                "size": 142136880,
                "size_string": "136M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/6.0/6.0.3/single/qt-everywhere-src-6.0.3.tar.xz",
                "version": "6.0.3"
            }
        ],
        "preview_list": [
            "source"
        ],
        "version": "6.0.3"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-src-6.0.2.zip",
                "md5": "9d170baba6deedf4a39cb1b81d9f6522",
                "platform": "unknow",
                "size": 237608345,
                "size_string": "227M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/6.0/6.0.2/single/qt-everywhere-src-6.0.2.zip",
                "version": "6.0.2"
            },
            {
                "file_name": "qt-everywhere-src-6.0.2.tar.xz",
                "md5": "2184cb968ee2086e9a73358133db6d66",
                "platform": "unknow",
                "size": 142101216,
                "size_string": "136M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/6.0/6.0.2/single/qt-everywhere-src-6.0.2.tar.xz",
                "version": "6.0.2"
            }
        ],
        "preview_list": [
            "source"
        ],
        "version": "6.0.2"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-src-6.0.1.zip",
                "md5": "cf66027e97e327edfb53bea5d8bdaeb5",
                "platform": "unknow",
                "size": 237558737,
                "size_string": "227M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/6.0/6.0.1/single/qt-everywhere-src-6.0.1.zip",
                "version": "6.0.1"
            },
            {
                "file_name": "qt-everywhere-src-6.0.1.tar.xz",
                "md5": "0fa9c87e0970abe2cb426cc48a7cca35",
                "platform": "unknow",
                "size": 142091636,
                "size_string": "136M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/6.0/6.0.1/single/qt-everywhere-src-6.0.1.tar.xz",
                "version": "6.0.1"
            }
        ],
        "preview_list": [
            "source"
        ],
        "version": "6.0.1"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-src-6.0.0.tar.xz",
                "md5": "794aae9cc11898a648e671c7365b8623",
                "platform": "unknow",
                "size": 157088672,
                "size_string": "150M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/6.0/6.0.0/single/qt-everywhere-src-6.0.0.tar.xz",
                "version": "6.0.0"
            },
            {
                "file_name": "qt-everywhere-src-6.0.0.zip",
                "md5": "1533d2ec6e08d1a33a76090733231963",
                "platform": "unknow",
                "size": 254162450,
                "size_string": "242M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/6.0/6.0.0/single/qt-everywhere-src-6.0.0.zip",
                "version": "6.0.0"
            }
        ],
        "preview_list": [
            "source"
        ],
        "version": "6.0.0"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-src-5.15.2.zip",
                "md5": "40fc0bda97fbcc1eff8ab905ce2b0300",
                "platform": "unknow",
                "size": 1009224272,
                "size_string": "962M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.15/5.15.2/single/qt-everywhere-src-5.15.2.zip",
                "version": "5.15.2"
            },
            {
                "file_name": "qt-everywhere-src-5.15.2.tar.xz",
                "md5": "e1447db4f06c841d8947f0a6ce83a7b5",
                "platform": "unknow",
                "size": 586690220,
                "size_string": "560M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.15/5.15.2/single/qt-everywhere-src-5.15.2.tar.xz",
                "version": "5.15.2"
            }
        ],
        "preview_list": [
            "source"
        ],
        "version": "5.15.2"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-src-5.15.1.zip",
                "md5": "beb2ea45939250e46e6296558578b2d2",
                "platform": "unknow",
                "size": 954061267,
                "size_string": "910M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.15/5.15.1/single/qt-everywhere-src-5.15.1.zip",
                "version": "5.15.1"
            },
            {
                "file_name": "qt-everywhere-src-5.15.1.tar.xz",
                "md5": "ab16c28f4f9e6db8299096312decdbc4",
                "platform": "unknow",
                "size": 581305680,
                "size_string": "554M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.15/5.15.1/single/qt-everywhere-src-5.15.1.tar.xz",
                "version": "5.15.1"
            }
        ],
        "preview_list": [
            "source"
        ],
        "version": "5.15.1"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-src-5.15.0.tar.xz",
                "md5": "610a228dba6ef469d14d145b71ab3b88",
                "platform": "unknow",
                "size": 592401236,
                "size_string": "565M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.15/5.15.0/single/qt-everywhere-src-5.15.0.tar.xz",
                "version": "5.15.0"
            },
            {
                "file_name": "qt-everywhere-src-5.15.0.zip",
                "md5": "13f45c990900f151dbe9aba19c2c8954",
                "platform": "unknow",
                "size": 963516891,
                "size_string": "919M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.15/5.15.0/single/qt-everywhere-src-5.15.0.zip",
                "version": "5.15.0"
            }
        ],
        "preview_list": [
            "source"
        ],
        "version": "5.15.0"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-src-5.14.2.zip",
                "md5": "93a3d5cae271fdab6b3cd9a8e0c4a260",
                "platform": "unknow",
                "size": 890204360,
                "size_string": "849M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.14/5.14.2/single/qt-everywhere-src-5.14.2.zip",
                "version": "5.14.2"
            },
            {
                "file_name": "qt-everywhere-src-5.14.2.tar.xz",
                "md5": "b3d2b6d00e6ca8a8ede6d1c9bdc74daf",
                "platform": "unknow",
                "size": 549205340,
                "size_string": "524M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.14/5.14.2/single/qt-everywhere-src-5.14.2.tar.xz",
                "version": "5.14.2"
            },
            {
                "file_name": "qt-opensource-windows-x86-5.14.2.exe",
                "md5": "077a9888560bace2d56f8eed995db58c",
                "platform": "window",
                "size": 0,
                "size_string": "2.3G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.14/5.14.2/qt-opensource-windows-x86-5.14.2.exe",
                "version": "5.14.2"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.14.2.run",
                "md5": "dce0588874fd369ce493ea5bc2a21d99",
                "platform": "linux",
                "size": 1335706944,
                "size_string": "1.2G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.14/5.14.2/qt-opensource-linux-x64-5.14.2.run",
                "version": "5.14.2"
            },
            {
                "file_name": "qt-opensource-mac-x64-5.14.2.dmg",
                "md5": "983de283991ceb39ab4ac043b5b49d35",
                "platform": "mac",
                "size": 0,
                "size_string": "2.6G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.14/5.14.2/qt-opensource-mac-x64-5.14.2.dmg",
                "version": "5.14.2"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.14.2"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-src-5.14.1.tar.xz",
                "md5": "781c3179410aff7ef84607214e1e91b4",
                "platform": "unknow",
                "size": 549084824,
                "size_string": "524M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.14/5.14.1/single/qt-everywhere-src-5.14.1.tar.xz",
                "version": "5.14.1"
            },
            {
                "file_name": "qt-everywhere-src-5.14.1.zip",
                "md5": "4a0c345197dc4333977146da0f746934",
                "platform": "unknow",
                "size": 889940492,
                "size_string": "849M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.14/5.14.1/single/qt-everywhere-src-5.14.1.zip",
                "version": "5.14.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-5.14.1.exe",
                "md5": "e0e275eae57c4eb157fbc064260132e2",
                "platform": "window",
                "size": 0,
                "size_string": "2.3G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.14/5.14.1/qt-opensource-windows-x86-5.14.1.exe",
                "version": "5.14.1"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.14.1.run",
                "md5": "2541b22b975851620877608c791130d5",
                "platform": "linux",
                "size": 1320027012,
                "size_string": "1.2G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.14/5.14.1/qt-opensource-linux-x64-5.14.1.run",
                "version": "5.14.1"
            },
            {
                "file_name": "qt-opensource-mac-x64-5.14.1.dmg",
                "md5": "625610c13aa47f4f6748e9e6a31b77cb",
                "platform": "mac",
                "size": 0,
                "size_string": "2.6G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.14/5.14.1/qt-opensource-mac-x64-5.14.1.dmg",
                "version": "5.14.1"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.14.1"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-src-5.14.0.zip",
                "md5": "3d74570a0d9b0d3b23ebbdf9af4c4767",
                "platform": "unknow",
                "size": 885928986,
                "size_string": "845M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.14/5.14.0/single/qt-everywhere-src-5.14.0.zip",
                "version": "5.14.0"
            },
            {
                "file_name": "qt-everywhere-src-5.14.0.tar.xz",
                "md5": "e7ea948ab99a2ef0726db5eac8e4fd8e",
                "platform": "unknow",
                "size": 547854788,
                "size_string": "522M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.14/5.14.0/single/qt-everywhere-src-5.14.0.tar.xz",
                "version": "5.14.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-5.14.0.exe",
                "md5": "c09206a384ad77e0467ff9b7d014b2b6",
                "platform": "window",
                "size": 0,
                "size_string": "2.5G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.14/5.14.0/qt-opensource-windows-x86-5.14.0.exe",
                "version": "5.14.0"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.14.0.run",
                "md5": "d382858ba5b589ac13b401560d2c5f45",
                "platform": "linux",
                "size": 1325519022,
                "size_string": "1.2G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.14/5.14.0/qt-opensource-linux-x64-5.14.0.run",
                "version": "5.14.0"
            },
            {
                "file_name": "qt-opensource-mac-x64-5.14.0.dmg",
                "md5": "d0b7d7f07bb64f16f2ca721bdf34fe88",
                "platform": "mac",
                "size": 0,
                "size_string": "2.6G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.14/5.14.0/qt-opensource-mac-x64-5.14.0.dmg",
                "version": "5.14.0"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.14.0"
    },
    {
        "data": [
            {
                "file_name": "qt-opensource-macos-debug-information-files-5.13.2.7z",
                "md5": "764d8d91fd18e6f033a82db2eac8bf8f",
                "platform": "unknow",
                "size": 648647757,
                "size_string": "619M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.13/5.13.2/qt-opensource-macos-debug-information-files-5.13.2.7z",
                "version": "5.13.2"
            },
            {
                "file_name": "qt-everywhere-src-5.13.2.zip",
                "md5": "39a0465610f70d9f877f42fc5337d1ac",
                "platform": "unknow",
                "size": 827143277,
                "size_string": "789M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.13/5.13.2/single/qt-everywhere-src-5.13.2.zip",
                "version": "5.13.2"
            },
            {
                "file_name": "qt-everywhere-src-5.13.2.tar.xz",
                "md5": "7c04c678d4ecd9e9c06747e7c17e0bb9",
                "platform": "unknow",
                "size": 500830260,
                "size_string": "478M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.13/5.13.2/single/qt-everywhere-src-5.13.2.tar.xz",
                "version": "5.13.2"
            },
            {
                "file_name": "qt-opensource-windows-x86-5.13.2.exe",
                "md5": "1b11b83f60c513c9d8d2593531ffef80",
                "platform": "window",
                "size": 0,
                "size_string": "3.6G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.13/5.13.2/qt-opensource-windows-x86-5.13.2.exe",
                "version": "5.13.2"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.13.2.run",
                "md5": "40b9b45c6d4fe0dc37f3af0734a2e5db",
                "platform": "linux",
                "size": 1341428834,
                "size_string": "1.2G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.13/5.13.2/qt-opensource-linux-x64-5.13.2.run",
                "version": "5.13.2"
            },
            {
                "file_name": "qt-opensource-mac-x64-5.13.2.dmg",
                "md5": "7c1b03c84d1014d8711cd64bd6ab1a88",
                "platform": "mac",
                "size": 0,
                "size_string": "2.6G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.13/5.13.2/qt-opensource-mac-x64-5.13.2.dmg",
                "version": "5.13.2"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.13.2"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-src-5.13.1.tar.xz",
                "md5": "d66b1da335d0c25325fdf493e9044c38",
                "platform": "unknow",
                "size": 502389648,
                "size_string": "479M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.13/5.13.1/single/qt-everywhere-src-5.13.1.tar.xz",
                "version": "5.13.1"
            },
            {
                "file_name": "qt-everywhere-src-5.13.1.zip",
                "md5": "e44e91714ff12a7e35c83a0c5901ea89",
                "platform": "unknow",
                "size": 831033225,
                "size_string": "793M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.13/5.13.1/single/qt-everywhere-src-5.13.1.zip",
                "version": "5.13.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-5.13.1.exe",
                "md5": "af11d253d5664572e0c175bafc9a8e56",
                "platform": "window",
                "size": 0,
                "size_string": "3.6G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.13/5.13.1/qt-opensource-windows-x86-5.13.1.exe",
                "version": "5.13.1"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.13.1.run",
                "md5": "21c3b16f851697fa8da8009f73694373",
                "platform": "linux",
                "size": 1404105115,
                "size_string": "1.3G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.13/5.13.1/qt-opensource-linux-x64-5.13.1.run",
                "version": "5.13.1"
            },
            {
                "file_name": "qt-opensource-mac-x64-5.13.1.dmg",
                "md5": "a5cad5624219dc32c0fb4b296e1c7f97",
                "platform": "mac",
                "size": 0,
                "size_string": "3.2G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.13/5.13.1/qt-opensource-mac-x64-5.13.1.dmg",
                "version": "5.13.1"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.13.1"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-src-5.13.0.tar.xz",
                "md5": "3c168d9a3a08248ff36f4f54c82e437f",
                "platform": "unknow",
                "size": 504491324,
                "size_string": "481M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.13/5.13.0/single/qt-everywhere-src-5.13.0.tar.xz",
                "version": "5.13.0"
            },
            {
                "file_name": "qt-everywhere-src-5.13.0.zip",
                "md5": "85137b9079133b880849538f29524a77",
                "platform": "unknow",
                "size": 832383560,
                "size_string": "794M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.13/5.13.0/single/qt-everywhere-src-5.13.0.zip",
                "version": "5.13.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-5.13.0.exe",
                "md5": "20dd0c841359cb292c3d154c50b8094c",
                "platform": "window",
                "size": 0,
                "size_string": "3.7G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.13/5.13.0/qt-opensource-windows-x86-5.13.0.exe",
                "version": "5.13.0"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.13.0.run",
                "md5": "c839aed9d551bdc8411a0a33e5ae46f3",
                "platform": "linux",
                "size": 1454214582,
                "size_string": "1.4G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.13/5.13.0/qt-opensource-linux-x64-5.13.0.run",
                "version": "5.13.0"
            },
            {
                "file_name": "qt-opensource-mac-x64-5.13.0.dmg",
                "md5": "7a46bff65acc88f06be62631a7ae790d",
                "platform": "mac",
                "size": 0,
                "size_string": "3.3G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.13/5.13.0/qt-opensource-mac-x64-5.13.0.dmg",
                "version": "5.13.0"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.13.0"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-src-5.12.10.tar.xz",
                "md5": "a781a0e247400e764c0730b8fb54226f",
                "platform": "unknow",
                "size": 509709284,
                "size_string": "486M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.10/single/qt-everywhere-src-5.12.10.tar.xz",
                "version": "5.12.10"
            },
            {
                "file_name": "qt-everywhere-src-5.12.10.zip",
                "md5": "a067fcd4576bfc078d8f3a028cdac48c",
                "platform": "unknow",
                "size": 870433789,
                "size_string": "830M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.10/single/qt-everywhere-src-5.12.10.zip",
                "version": "5.12.10"
            },
            {
                "file_name": "qt-opensource-windows-x86-5.12.10.exe",
                "md5": "0377b907bc63c9468796e064289032fd",
                "platform": "window",
                "size": 0,
                "size_string": "3.7G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.10/qt-opensource-windows-x86-5.12.10.exe",
                "version": "5.12.10"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.12.10.run",
                "md5": "f85662eefe38687b9e78ab3ba8efba89",
                "platform": "linux",
                "size": 1398857648,
                "size_string": "1.3G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.10/qt-opensource-linux-x64-5.12.10.run",
                "version": "5.12.10"
            },
            {
                "file_name": "qt-opensource-mac-x64-5.12.10.dmg",
                "md5": "e0b39344d38bd7aea9aa3335ae7f6e05",
                "platform": "mac",
                "size": 0,
                "size_string": "2.7G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.10/qt-opensource-mac-x64-5.12.10.dmg",
                "version": "5.12.10"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.12.10"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-src-5.12.9.tar.xz",
                "md5": "fa2646280cf38180689c29c393cddd05",
                "platform": "unknow",
                "size": 505857368,
                "size_string": "482M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.9/single/qt-everywhere-src-5.12.9.tar.xz",
                "version": "5.12.9"
            },
            {
                "file_name": "qt-everywhere-src-5.12.9.zip",
                "md5": "872ca27e9038d8fdbefff51a9db3503b",
                "platform": "unknow",
                "size": 828578434,
                "size_string": "790M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.9/single/qt-everywhere-src-5.12.9.zip",
                "version": "5.12.9"
            },
            {
                "file_name": "qt-opensource-windows-x86-5.12.9.exe",
                "md5": "b9257df07dac3cbac910759c3b962aef",
                "platform": "window",
                "size": 0,
                "size_string": "3.7G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.9/qt-opensource-windows-x86-5.12.9.exe",
                "version": "5.12.9"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.12.9.run",
                "md5": "fb88e506c7d733aea85d63c0e3f35649",
                "platform": "linux",
                "size": 1410748558,
                "size_string": "1.3G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.9/qt-opensource-linux-x64-5.12.9.run",
                "version": "5.12.9"
            },
            {
                "file_name": "qt-opensource-mac-x64-5.12.9.dmg",
                "md5": "703bc1e69eb66cc6520fc49226f53b01",
                "platform": "mac",
                "size": 0,
                "size_string": "2.7G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.9/qt-opensource-mac-x64-5.12.9.dmg",
                "version": "5.12.9"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.12.9"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-src-5.12.8.zip",
                "md5": "3bfe0d92ce856109caae7e323fed2a1a",
                "platform": "unknow",
                "size": 828312239,
                "size_string": "790M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.8/single/qt-everywhere-src-5.12.8.zip",
                "version": "5.12.8"
            },
            {
                "file_name": "qt-everywhere-src-5.12.8.tar.xz",
                "md5": "8ec2a0458f3b8e9c995b03df05e006e4",
                "platform": "unknow",
                "size": 505606824,
                "size_string": "482M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.8/single/qt-everywhere-src-5.12.8.tar.xz",
                "version": "5.12.8"
            },
            {
                "file_name": "qt-opensource-windows-x86-5.12.8.exe",
                "md5": "74eb9eb181259808e353794f8ca30bc3",
                "platform": "window",
                "size": 0,
                "size_string": "3.7G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.8/qt-opensource-windows-x86-5.12.8.exe",
                "version": "5.12.8"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.12.8.run",
                "md5": "3dcc6d268e72d39fc2923ab7fdd6c6cf",
                "platform": "linux",
                "size": 1409872850,
                "size_string": "1.3G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.8/qt-opensource-linux-x64-5.12.8.run",
                "version": "5.12.8"
            },
            {
                "file_name": "qt-opensource-mac-x64-5.12.8.dmg",
                "md5": "d4a83d12e6e07e97b02a07fb62615c1e",
                "platform": "mac",
                "size": 0,
                "size_string": "2.7G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.8/qt-opensource-mac-x64-5.12.8.dmg",
                "version": "5.12.8"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.12.8"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-src-5.12.7.zip",
                "md5": "753e0147d5e32d61c30d6a1b498bed3e",
                "platform": "unknow",
                "size": 828205075,
                "size_string": "790M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.7/single/qt-everywhere-src-5.12.7.zip",
                "version": "5.12.7"
            },
            {
                "file_name": "qt-everywhere-src-5.12.7.tar.xz",
                "md5": "ce2c5661c028b9de6183245982d7c120",
                "platform": "unknow",
                "size": 505558724,
                "size_string": "482M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.7/single/qt-everywhere-src-5.12.7.tar.xz",
                "version": "5.12.7"
            },
            {
                "file_name": "qt-opensource-windows-x86-5.12.7.exe",
                "md5": "ec0531d3e36ed773d1a3828745c9c137",
                "platform": "window",
                "size": 0,
                "size_string": "3.7G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.7/qt-opensource-windows-x86-5.12.7.exe",
                "version": "5.12.7"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.12.7.run",
                "md5": "f9db478af66f35fa7419169e135c3921",
                "platform": "linux",
                "size": 1399630411,
                "size_string": "1.3G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.7/qt-opensource-linux-x64-5.12.7.run",
                "version": "5.12.7"
            },
            {
                "file_name": "qt-opensource-mac-x64-5.12.7.dmg",
                "md5": "05df807ece168765c7331e12e4cbf001",
                "platform": "mac",
                "size": 0,
                "size_string": "2.7G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.7/qt-opensource-mac-x64-5.12.7.dmg",
                "version": "5.12.7"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.12.7"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-src-5.12.6.zip",
                "md5": "0856193178c493611cb229843dc6fd82",
                "platform": "unknow",
                "size": 823422709,
                "size_string": "785M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.6/single/qt-everywhere-src-5.12.6.zip",
                "version": "5.12.6"
            },
            {
                "file_name": "qt-everywhere-src-5.12.6.tar.xz",
                "md5": "287d71e71ebd97f77220873e7b131b1a",
                "platform": "unknow",
                "size": 502428928,
                "size_string": "479M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.6/single/qt-everywhere-src-5.12.6.tar.xz",
                "version": "5.12.6"
            },
            {
                "file_name": "qt-opensource-windows-x86-5.12.6.exe",
                "md5": "bc3442a7dcdbdd3e55b17366802c07dc",
                "platform": "window",
                "size": 0,
                "size_string": "3.7G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.6/qt-opensource-windows-x86-5.12.6.exe",
                "version": "5.12.6"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.12.6.run",
                "md5": "e95c2851a72c8695ab70279a6a0462d8",
                "platform": "linux",
                "size": 1378033082,
                "size_string": "1.3G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.6/qt-opensource-linux-x64-5.12.6.run",
                "version": "5.12.6"
            },
            {
                "file_name": "qt-opensource-mac-x64-5.12.6.dmg",
                "md5": "8d588248cc95d7ee1fa75c157bad862f",
                "platform": "mac",
                "size": 0,
                "size_string": "2.6G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.6/qt-opensource-mac-x64-5.12.6.dmg",
                "version": "5.12.6"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.12.6"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-src-5.12.5.tar.xz",
                "md5": "d8c9ed842d39f1a5f31a7ab31e4e886c",
                "platform": "unknow",
                "size": 505151928,
                "size_string": "482M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.5/single/qt-everywhere-src-5.12.5.tar.xz",
                "version": "5.12.5"
            },
            {
                "file_name": "qt-everywhere-src-5.12.5.zip",
                "md5": "157fa5d9c897737ce06ba4f9a20151e0",
                "platform": "unknow",
                "size": 827580750,
                "size_string": "789M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.5/single/qt-everywhere-src-5.12.5.zip",
                "version": "5.12.5"
            },
            {
                "file_name": "qt-opensource-windows-x86-5.12.5.exe",
                "md5": "6cf26a7bdbbcd00c2f80f330477659e2",
                "platform": "window",
                "size": 0,
                "size_string": "3.7G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.5/qt-opensource-windows-x86-5.12.5.exe",
                "version": "5.12.5"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.12.5.run",
                "md5": "00ad04a24cac6239cd050381e4b5d6f4",
                "platform": "linux",
                "size": 1371387292,
                "size_string": "1.3G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.5/qt-opensource-linux-x64-5.12.5.run",
                "version": "5.12.5"
            },
            {
                "file_name": "qt-opensource-mac-x64-5.12.5.dmg",
                "md5": "321a980a9a3237a3d1a5f7fb4aa008d8",
                "platform": "mac",
                "size": 0,
                "size_string": "3.2G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.5/qt-opensource-mac-x64-5.12.5.dmg",
                "version": "5.12.5"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.12.5"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-src-5.12.4.zip",
                "md5": "16526e08adfad46e8e686b8af984b9b5",
                "platform": "unknow",
                "size": 830729617,
                "size_string": "792M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.4/single/qt-everywhere-src-5.12.4.zip",
                "version": "5.12.4"
            },
            {
                "file_name": "qt-everywhere-src-5.12.4.tar.xz",
                "md5": "dda95b0239d13c5276834177af3a8588",
                "platform": "unknow",
                "size": 507958196,
                "size_string": "484M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.4/single/qt-everywhere-src-5.12.4.tar.xz",
                "version": "5.12.4"
            },
            {
                "file_name": "qt-opensource-windows-x86-5.12.4.exe",
                "md5": "23432d96c0cd4a4fd852af69ae87dc85",
                "platform": "window",
                "size": 0,
                "size_string": "3.6G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.4/qt-opensource-windows-x86-5.12.4.exe",
                "version": "5.12.4"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.12.4.run",
                "md5": "d67d44b7188e6b6b27bce3684e5858df",
                "platform": "linux",
                "size": 1429473710,
                "size_string": "1.3G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.4/qt-opensource-linux-x64-5.12.4.run",
                "version": "5.12.4"
            },
            {
                "file_name": "qt-opensource-mac-x64-5.12.4.dmg",
                "md5": "64a5c5a2a05d95100282d3f40ed01608",
                "platform": "mac",
                "size": 0,
                "size_string": "3.2G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.4/qt-opensource-mac-x64-5.12.4.dmg",
                "version": "5.12.4"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.12.4"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-src-5.12.3.tar.xz",
                "md5": "38017e0ed88b9baba063bd723d9ca8b2",
                "platform": "unknow",
                "size": 507822332,
                "size_string": "484M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.3/single/qt-everywhere-src-5.12.3.tar.xz",
                "version": "5.12.3"
            },
            {
                "file_name": "qt-everywhere-src-5.12.3.zip",
                "md5": "5da2e14a9f5db620c578fafd219592d7",
                "platform": "unknow",
                "size": 830430796,
                "size_string": "792M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.3/single/qt-everywhere-src-5.12.3.zip",
                "version": "5.12.3"
            },
            {
                "file_name": "qt-opensource-windows-x86-5.12.3.exe",
                "md5": "13e713198965a69c7ef6a305848d7e8d",
                "platform": "window",
                "size": 0,
                "size_string": "3.7G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.3/qt-opensource-windows-x86-5.12.3.exe",
                "version": "5.12.3"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.12.3.run",
                "md5": "9ae8055db5efb21e419dc6b9e77abc61",
                "platform": "linux",
                "size": 1458369997,
                "size_string": "1.4G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.3/qt-opensource-linux-x64-5.12.3.run",
                "version": "5.12.3"
            },
            {
                "file_name": "qt-opensource-mac-x64-5.12.3.dmg",
                "md5": "6d2bf14c48f324ad870f0f841683bff3",
                "platform": "mac",
                "size": 0,
                "size_string": "3.2G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.3/qt-opensource-mac-x64-5.12.3.dmg",
                "version": "5.12.3"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.12.3"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-src-5.12.2.zip",
                "md5": "f42a7dd739a248d64d6be0dbaf6ad187",
                "platform": "unknow",
                "size": 830295086,
                "size_string": "792M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.2/single/qt-everywhere-src-5.12.2.zip",
                "version": "5.12.2"
            },
            {
                "file_name": "qt-everywhere-src-5.12.2.tar.xz",
                "md5": "99c2eb46e533371798b4ca2d1458e065",
                "platform": "unknow",
                "size": 507757480,
                "size_string": "484M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.2/single/qt-everywhere-src-5.12.2.tar.xz",
                "version": "5.12.2"
            },
            {
                "file_name": "qt-opensource-windows-x86-5.12.2.exe",
                "md5": "886027e57bfaa48c98dc984129c7147f",
                "platform": "window",
                "size": 0,
                "size_string": "3.7G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.2/qt-opensource-windows-x86-5.12.2.exe",
                "version": "5.12.2"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.12.2.run",
                "md5": "a87801bd1ba90e9292e0305e124f2625",
                "platform": "linux",
                "size": 1456203550,
                "size_string": "1.4G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.2/qt-opensource-linux-x64-5.12.2.run",
                "version": "5.12.2"
            },
            {
                "file_name": "qt-opensource-mac-x64-5.12.2.dmg",
                "md5": "48e7bf05a3509c33dd8ab7a6ec853084",
                "platform": "mac",
                "size": 0,
                "size_string": "3.2G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.2/qt-opensource-mac-x64-5.12.2.dmg",
                "version": "5.12.2"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.12.2"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-src-5.12.1.zip",
                "md5": "4649d4e51ca836fbde08565582353140",
                "platform": "unknow",
                "size": 829455991,
                "size_string": "791M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.1/single/qt-everywhere-src-5.12.1.zip",
                "version": "5.12.1"
            },
            {
                "file_name": "qt-everywhere-src-5.12.1.tar.xz",
                "md5": "6a37466c8c40e87d4a19c3f286ec2542",
                "platform": "unknow",
                "size": 507568064,
                "size_string": "484M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.1/single/qt-everywhere-src-5.12.1.tar.xz",
                "version": "5.12.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-5.12.1.exe",
                "md5": "161e9042a35df2806ba3cfcafd20ae50",
                "platform": "window",
                "size": 0,
                "size_string": "2.9G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.1/qt-opensource-windows-x86-5.12.1.exe",
                "version": "5.12.1"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.12.1.run",
                "md5": "f055c32d3299fcb72a5ef0af2f267904",
                "platform": "linux",
                "size": 1464863926,
                "size_string": "1.4G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.1/qt-opensource-linux-x64-5.12.1.run",
                "version": "5.12.1"
            },
            {
                "file_name": "qt-opensource-mac-x64-5.12.1.dmg",
                "md5": "11a44350b40cf2785ff28b2a4f502466",
                "platform": "mac",
                "size": 0,
                "size_string": "3.2G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.1/qt-opensource-mac-x64-5.12.1.dmg",
                "version": "5.12.1"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.12.1"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-src-5.12.0.zip",
                "md5": "66eaa8f2ad1b8a1867458187a31d0a88",
                "platform": "unknow",
                "size": 827333766,
                "size_string": "789M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.0/single/qt-everywhere-src-5.12.0.zip",
                "version": "5.12.0"
            },
            {
                "file_name": "qt-everywhere-src-5.12.0.tar.xz",
                "md5": "af569de3eb42da4457b0897e5759dc91",
                "platform": "unknow",
                "size": 510292820,
                "size_string": "487M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.0/single/qt-everywhere-src-5.12.0.tar.xz",
                "version": "5.12.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-5.12.0.exe",
                "md5": "29cb97f903c6bf54c8f259f366cec10e",
                "platform": "window",
                "size": 0,
                "size_string": "2.8G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.0/qt-opensource-windows-x86-5.12.0.exe",
                "version": "5.12.0"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.12.0.run",
                "md5": "1ee2c6c62df5e7cca3ceb946789d3626",
                "platform": "linux",
                "size": 1443472852,
                "size_string": "1.3G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.0/qt-opensource-linux-x64-5.12.0.run",
                "version": "5.12.0"
            },
            {
                "file_name": "qt-opensource-mac-x64-5.12.0.dmg",
                "md5": "6fe192b2b63bd0f3203266b677510a22",
                "platform": "mac",
                "size": 0,
                "size_string": "3.2G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.12/5.12.0/qt-opensource-mac-x64-5.12.0.dmg",
                "version": "5.12.0"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.12.0"
    },
    {
        "data": [
            {
                "file_name": "qt-opensource-linux-debug-information-files-5.11.3.7z",
                "md5": "82d1223f7a14558d34ebb3a0c43f0f40",
                "platform": "unknow",
                "size": 543157703,
                "size_string": "518M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.11/5.11.3/qt-opensource-linux-debug-information-files-5.11.3.7z",
                "version": "5.11.3"
            },
            {
                "file_name": "qt-everywhere-src-5.11.3.zip",
                "md5": "9a57b251658d985c26acb6de18b69328",
                "platform": "unknow",
                "size": 773064842,
                "size_string": "737M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.11/5.11.3/single/qt-everywhere-src-5.11.3.zip",
                "version": "5.11.3"
            },
            {
                "file_name": "qt-everywhere-src-5.11.3.tar.xz",
                "md5": "02b353bfe7a40a8dc4274e1d17226d2b",
                "platform": "unknow",
                "size": 489731532,
                "size_string": "467M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.11/5.11.3/single/qt-everywhere-src-5.11.3.tar.xz",
                "version": "5.11.3"
            },
            {
                "file_name": "qt-opensource-windows-x86-5.11.3.exe",
                "md5": "abe6ed847ee8a8640c24ef03b249335d",
                "platform": "window",
                "size": 0,
                "size_string": "2.4G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.11/5.11.3/qt-opensource-windows-x86-5.11.3.exe",
                "version": "5.11.3"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.11.3.run",
                "md5": "a9219194938e3e4ebe999a853a379a3f",
                "platform": "linux",
                "size": 1363840220,
                "size_string": "1.3G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.11/5.11.3/qt-opensource-linux-x64-5.11.3.run",
                "version": "5.11.3"
            },
            {
                "file_name": "qt-opensource-mac-x64-5.11.3.dmg",
                "md5": "3bcb081a5c4b3eb366338be70d2d27cf",
                "platform": "mac",
                "size": 0,
                "size_string": "2.8G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.11/5.11.3/qt-opensource-mac-x64-5.11.3.dmg",
                "version": "5.11.3"
            },
            {
                "file_name": "qt-opensource-windows-x86-pdb-files-uwp-5.11.3.7z",
                "md5": "e23ef13a2b5e48582a53804d57f0b16f",
                "platform": "unknow",
                "size": 1430039587,
                "size_string": "1.3G",
                "type": "other",
                "url": "https://download.qt.io/new_archive/qt/5.11/5.11.3/qt-opensource-windows-x86-pdb-files-uwp-5.11.3.7z",
                "version": "5.11.3"
            },
            {
                "file_name": "qt-opensource-windows-x86-pdb-files-desktop-5.11.3.7z",
                "md5": "92680cebf38c581d39d3e47f3c7ca8ac",
                "platform": "unknow",
                "size": 1310728724,
                "size_string": "1.2G",
                "type": "other",
                "url": "https://download.qt.io/new_archive/qt/5.11/5.11.3/qt-opensource-windows-x86-pdb-files-desktop-5.11.3.7z",
                "version": "5.11.3"
            }
        ],
        "preview_list": [
            "source",
            "bin",
            "other"
        ],
        "version": "5.11.3"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-src-5.11.2.zip",
                "md5": "f6647d66d1c062b0603831290a4c7e4a",
                "platform": "unknow",
                "size": 772072567,
                "size_string": "736M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.11/5.11.2/single/qt-everywhere-src-5.11.2.zip",
                "version": "5.11.2"
            },
            {
                "file_name": "qt-everywhere-src-5.11.2.tar.xz",
                "md5": "152a8ade9c11fe33ff5bc95310a1bb64",
                "platform": "unknow",
                "size": 489621768,
                "size_string": "467M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.11/5.11.2/single/qt-everywhere-src-5.11.2.tar.xz",
                "version": "5.11.2"
            },
            {
                "file_name": "qt-opensource-linux-debug-information-files-5.11.2.7z",
                "md5": "aa1efe5e2f1f5d949b552ea083a3bd85",
                "platform": "unknow",
                "size": 542898612,
                "size_string": "518M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.11/5.11.2/qt-opensource-linux-debug-information-files-5.11.2.7z",
                "version": "5.11.2"
            },
            {
                "file_name": "qt-opensource-windows-x86-5.11.2.exe",
                "md5": "d7a6bbca13f005dc69b341640343addd",
                "platform": "window",
                "size": 0,
                "size_string": "2.4G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.11/5.11.2/qt-opensource-windows-x86-5.11.2.exe",
                "version": "5.11.2"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.11.2.run",
                "md5": "974fda61267cfb6e45984ee5f0a285f8",
                "platform": "linux",
                "size": 1363330470,
                "size_string": "1.3G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.11/5.11.2/qt-opensource-linux-x64-5.11.2.run",
                "version": "5.11.2"
            },
            {
                "file_name": "qt-opensource-mac-x64-5.11.2.dmg",
                "md5": "16e4d49466bfeabe567062f82c274b3e",
                "platform": "mac",
                "size": 0,
                "size_string": "2.8G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.11/5.11.2/qt-opensource-mac-x64-5.11.2.dmg",
                "version": "5.11.2"
            },
            {
                "file_name": "qt-opensource-windows-x86-pdb-files-uwp-5.11.2.7z",
                "md5": "f93a1b87b4620c13b1518ce7737c672b",
                "platform": "unknow",
                "size": 1434475492,
                "size_string": "1.3G",
                "type": "other",
                "url": "https://download.qt.io/new_archive/qt/5.11/5.11.2/qt-opensource-windows-x86-pdb-files-uwp-5.11.2.7z",
                "version": "5.11.2"
            },
            {
                "file_name": "qt-opensource-windows-x86-pdb-files-desktop-5.11.2.7z",
                "md5": "a4fc6560e35db7f6b69e212c84facbf1",
                "platform": "unknow",
                "size": 1311931139,
                "size_string": "1.2G",
                "type": "other",
                "url": "https://download.qt.io/new_archive/qt/5.11/5.11.2/qt-opensource-windows-x86-pdb-files-desktop-5.11.2.7z",
                "version": "5.11.2"
            }
        ],
        "preview_list": [
            "source",
            "bin",
            "other"
        ],
        "version": "5.11.2"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-src-5.11.1.zip",
                "md5": "c30891e1bf4c24739d5e4043be3add2c",
                "platform": "unknow",
                "size": 772488455,
                "size_string": "737M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.11/5.11.1/single/qt-everywhere-src-5.11.1.zip",
                "version": "5.11.1"
            },
            {
                "file_name": "qt-everywhere-src-5.11.1.tar.xz",
                "md5": "c6f0854d7de7bde80cfd8cc85bb7152b",
                "platform": "unknow",
                "size": 489237256,
                "size_string": "467M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.11/5.11.1/single/qt-everywhere-src-5.11.1.tar.xz",
                "version": "5.11.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-5.11.1.exe",
                "md5": "9e9c7b0cd026e597215e5dc695b82e0d",
                "platform": "window",
                "size": 0,
                "size_string": "2.4G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.11/5.11.1/qt-opensource-windows-x86-5.11.1.exe",
                "version": "5.11.1"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.11.1.run",
                "md5": "c6aef9ae58a0f65fceb50ff128a61198",
                "platform": "linux",
                "size": 1307835489,
                "size_string": "1.2G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.11/5.11.1/qt-opensource-linux-x64-5.11.1.run",
                "version": "5.11.1"
            },
            {
                "file_name": "qt-opensource-mac-x64-5.11.1.dmg",
                "md5": "04b6cf7f25fde9fa93e2b93d9ba03ada",
                "platform": "mac",
                "size": 0,
                "size_string": "2.7G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.11/5.11.1/qt-opensource-mac-x64-5.11.1.dmg",
                "version": "5.11.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-pdb-files-uwp-5.11.1.7z",
                "md5": "206dd779bb104b5ad80f52f582be26fa",
                "platform": "unknow",
                "size": 1438023470,
                "size_string": "1.3G",
                "type": "other",
                "url": "https://download.qt.io/new_archive/qt/5.11/5.11.1/qt-opensource-windows-x86-pdb-files-uwp-5.11.1.7z",
                "version": "5.11.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-pdb-files-desktop-5.11.1.7z",
                "md5": "379f501e8dbedd235a49ce90b1fe5aa2",
                "platform": "unknow",
                "size": 1314105278,
                "size_string": "1.2G",
                "type": "other",
                "url": "https://download.qt.io/new_archive/qt/5.11/5.11.1/qt-opensource-windows-x86-pdb-files-desktop-5.11.1.7z",
                "version": "5.11.1"
            }
        ],
        "preview_list": [
            "source",
            "bin",
            "other"
        ],
        "version": "5.11.1"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-src-5.11.0.zip",
                "md5": "723e6526781937161182648317aab82e",
                "platform": "unknow",
                "size": 770718211,
                "size_string": "735M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.11/5.11.0/single/qt-everywhere-src-5.11.0.zip",
                "version": "5.11.0"
            },
            {
                "file_name": "qt-everywhere-src-5.11.0.tar.xz",
                "md5": "68b7c9dd5c5df84b5c82730474c4c1f3",
                "platform": "unknow",
                "size": 488872460,
                "size_string": "466M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.11/5.11.0/single/qt-everywhere-src-5.11.0.tar.xz",
                "version": "5.11.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-5.11.0.exe",
                "md5": "1747e9f80b8dc9d11d37b9ea0b80f6a1",
                "platform": "window",
                "size": 0,
                "size_string": "2.4G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.11/5.11.0/qt-opensource-windows-x86-5.11.0.exe",
                "version": "5.11.0"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.11.0.run",
                "md5": "8aae67a5e0d22241d1d6066da266b01b",
                "platform": "linux",
                "size": 1305385569,
                "size_string": "1.2G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.11/5.11.0/qt-opensource-linux-x64-5.11.0.run",
                "version": "5.11.0"
            },
            {
                "file_name": "qt-opensource-mac-x64-5.11.0.dmg",
                "md5": "4a6007da4871a8c61424f0e9559152d6",
                "platform": "mac",
                "size": 0,
                "size_string": "2.7G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.11/5.11.0/qt-opensource-mac-x64-5.11.0.dmg",
                "version": "5.11.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-pdb-files-uwp-5.11.0.7z",
                "md5": "82ac3ab99c035a8e26074669a3246914",
                "platform": "unknow",
                "size": 1282949752,
                "size_string": "1.2G",
                "type": "other",
                "url": "https://download.qt.io/new_archive/qt/5.11/5.11.0/qt-opensource-windows-x86-pdb-files-uwp-5.11.0.7z",
                "version": "5.11.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-pdb-files-desktop-5.11.0.7z",
                "md5": "996e7fd9f43c40b7ced230392f499452",
                "platform": "unknow",
                "size": 1217713442,
                "size_string": "1.1G",
                "type": "other",
                "url": "https://download.qt.io/new_archive/qt/5.11/5.11.0/qt-opensource-windows-x86-pdb-files-desktop-5.11.0.7z",
                "version": "5.11.0"
            }
        ],
        "preview_list": [
            "source",
            "bin",
            "other"
        ],
        "version": "5.11.0"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-src-5.10.1.zip",
                "md5": "60c4ea41950857c65015fb6cffcb2497",
                "platform": "unknow",
                "size": 668680252,
                "size_string": "638M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.10/5.10.1/single/qt-everywhere-src-5.10.1.zip",
                "version": "5.10.1"
            },
            {
                "file_name": "qt-everywhere-src-5.10.1.tar.xz",
                "md5": "7e167b9617e7bd64012daaacb85477af",
                "platform": "unknow",
                "size": 409120260,
                "size_string": "390M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.10/5.10.1/single/qt-everywhere-src-5.10.1.tar.xz",
                "version": "5.10.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-5.10.1.exe",
                "md5": "13c6e832fee4f692226bcd8d03bc000c",
                "platform": "window",
                "size": 0,
                "size_string": "2.3G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.10/5.10.1/qt-opensource-windows-x86-5.10.1.exe",
                "version": "5.10.1"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.10.1.run",
                "md5": "8bc46db7cd82d738fa0015aea0bf7cb1",
                "platform": "linux",
                "size": 1035529337,
                "size_string": "1.0G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.10/5.10.1/qt-opensource-linux-x64-5.10.1.run",
                "version": "5.10.1"
            },
            {
                "file_name": "qt-opensource-mac-x64-5.10.1.dmg",
                "md5": "5f9748f49f612611e1dcbfd2f968c220",
                "platform": "mac",
                "size": 0,
                "size_string": "2.5G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.10/5.10.1/qt-opensource-mac-x64-5.10.1.dmg",
                "version": "5.10.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-pdb-files-uwp-5.10.1.zip",
                "md5": "7b9ad78fa01b907654458093ba8cfba1",
                "platform": "unknow",
                "size": 1478602183,
                "size_string": "1.4G",
                "type": "other",
                "url": "https://download.qt.io/new_archive/qt/5.10/5.10.1/qt-opensource-windows-x86-pdb-files-uwp-5.10.1.zip",
                "version": "5.10.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-pdb-files-desktop-5.10.1.zip",
                "md5": "2253d882a2ad95e096b56d8619a0716a",
                "platform": "unknow",
                "size": 1928861364,
                "size_string": "1.8G",
                "type": "other",
                "url": "https://download.qt.io/new_archive/qt/5.10/5.10.1/qt-opensource-windows-x86-pdb-files-desktop-5.10.1.zip",
                "version": "5.10.1"
            }
        ],
        "preview_list": [
            "source",
            "bin",
            "other"
        ],
        "version": "5.10.1"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-src-5.10.0.tar.xz",
                "md5": "c5e275ab0ed7ee61d0f4b82cd471770d",
                "platform": "unknow",
                "size": 408346584,
                "size_string": "389M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.10/5.10.0/single/qt-everywhere-src-5.10.0.tar.xz",
                "version": "5.10.0"
            },
            {
                "file_name": "qt-everywhere-src-5.10.0.zip",
                "md5": "db6a623759cdf9399bac95802742e40b",
                "platform": "unknow",
                "size": 666162248,
                "size_string": "635M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.10/5.10.0/single/qt-everywhere-src-5.10.0.zip",
                "version": "5.10.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-5.10.0.exe",
                "md5": "04976a16ab67f95c4efcf7299df7a573",
                "platform": "window",
                "size": 0,
                "size_string": "2.3G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.10/5.10.0/qt-opensource-windows-x86-5.10.0.exe",
                "version": "5.10.0"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.10.0.run",
                "md5": "9f13c6f346ac74de85ba01a5328a1e1c",
                "platform": "linux",
                "size": 1040997932,
                "size_string": "1.0G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.10/5.10.0/qt-opensource-linux-x64-5.10.0.run",
                "version": "5.10.0"
            },
            {
                "file_name": "qt-opensource-mac-x64-5.10.0.dmg",
                "md5": "807f67463c21ced0883edfb2dc917437",
                "platform": "mac",
                "size": 0,
                "size_string": "2.5G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.10/5.10.0/qt-opensource-mac-x64-5.10.0.dmg",
                "version": "5.10.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-pdb-files-uwp-5.10.0.zip",
                "md5": "4714d97920ee072db46b6720f7a85663",
                "platform": "unknow",
                "size": 1471007467,
                "size_string": "1.4G",
                "type": "other",
                "url": "https://download.qt.io/new_archive/qt/5.10/5.10.0/qt-opensource-windows-x86-pdb-files-uwp-5.10.0.zip",
                "version": "5.10.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-pdb-files-desktop-5.10.0.zip",
                "md5": "e51eb62ec41b691da684a3884fd41d36",
                "platform": "unknow",
                "size": 0,
                "size_string": "2.1G",
                "type": "other",
                "url": "https://download.qt.io/new_archive/qt/5.10/5.10.0/qt-opensource-windows-x86-pdb-files-desktop-5.10.0.zip",
                "version": "5.10.0"
            }
        ],
        "preview_list": [
            "source",
            "bin",
            "other"
        ],
        "version": "5.10.0"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-5.9.9.tar.xz",
                "md5": "97e81709b57e82ab2b279408eaa9270e",
                "platform": "unknow",
                "size": 460597056,
                "size_string": "439M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.9/single/qt-everywhere-opensource-src-5.9.9.tar.xz",
                "version": "5.9.9"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.9.9.zip",
                "md5": "0b41f3c62bc89f26cc111a3bdafbad6e",
                "platform": "unknow",
                "size": 712955395,
                "size_string": "680M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.9/single/qt-everywhere-opensource-src-5.9.9.zip",
                "version": "5.9.9"
            },
            {
                "file_name": "qt-opensource-windows-x86-5.9.9.exe",
                "md5": "8a6e85980361c539267b2b44a56fe063",
                "platform": "window",
                "size": 0,
                "size_string": "2.4G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.9/qt-opensource-windows-x86-5.9.9.exe",
                "version": "5.9.9"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.9.9.run",
                "md5": "a7d95a18005d9fe1c88b5e7fae5ef771",
                "platform": "linux",
                "size": 1148997611,
                "size_string": "1.1G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.9/qt-opensource-linux-x64-5.9.9.run",
                "version": "5.9.9"
            },
            {
                "file_name": "qt-opensource-mac-x64-5.9.9.dmg",
                "md5": "e57f7ed8a7df6d222328b12ab54faaad",
                "platform": "mac",
                "size": 0,
                "size_string": "3.4G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.9/qt-opensource-mac-x64-5.9.9.dmg",
                "version": "5.9.9"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.9.9"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-5.9.8.zip",
                "md5": "4aad492c1798238c954ce71393efae38",
                "platform": "unknow",
                "size": 717157409,
                "size_string": "684M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.8/single/qt-everywhere-opensource-src-5.9.8.zip",
                "version": "5.9.8"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.9.8.tar.xz",
                "md5": "bfeb4795c9446b9ff7c6c3c9042eb498",
                "platform": "unknow",
                "size": 461084404,
                "size_string": "440M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.8/single/qt-everywhere-opensource-src-5.9.8.tar.xz",
                "version": "5.9.8"
            },
            {
                "file_name": "qt-opensource-windows-x86-5.9.8.exe",
                "md5": "9fdbfc9cb959b73de42cd7efd1534dfe",
                "platform": "window",
                "size": 0,
                "size_string": "2.4G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.8/qt-opensource-windows-x86-5.9.8.exe",
                "version": "5.9.8"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.9.8.run",
                "md5": "afa3092203831f9eaf52b07ccf4fd738",
                "platform": "linux",
                "size": 1130496883,
                "size_string": "1.1G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.8/qt-opensource-linux-x64-5.9.8.run",
                "version": "5.9.8"
            },
            {
                "file_name": "qt-opensource-mac-x64-5.9.8.dmg",
                "md5": "04ba76912ce398f833aa96b008c4014b",
                "platform": "mac",
                "size": 0,
                "size_string": "3.6G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.8/qt-opensource-mac-x64-5.9.8.dmg",
                "version": "5.9.8"
            },
            {
                "file_name": "qt-opensource-windows-x86-pdb-files-desktop-5.9.8.7z",
                "md5": "5b19cf9588a64e2b0eb6611ae3ba6fb1",
                "platform": "unknow",
                "size": 1807196689,
                "size_string": "1.7G",
                "type": "other",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.8/qt-opensource-windows-x86-pdb-files-desktop-5.9.8.7z",
                "version": "5.9.8"
            },
            {
                "file_name": "qt-opensource-windows-x86-pdb-files-uwp-5.9.8.7z",
                "md5": "959528b498cdfa3698a76ac22729e4c4",
                "platform": "unknow",
                "size": 1317192924,
                "size_string": "1.2G",
                "type": "other",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.8/qt-opensource-windows-x86-pdb-files-uwp-5.9.8.7z",
                "version": "5.9.8"
            }
        ],
        "preview_list": [
            "source",
            "bin",
            "other"
        ],
        "version": "5.9.8"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-5.9.7.zip",
                "md5": "98102a8e9eefa2f05ecaa9a349c414d4",
                "platform": "unknow",
                "size": 719137790,
                "size_string": "686M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.7/single/qt-everywhere-opensource-src-5.9.7.zip",
                "version": "5.9.7"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.9.7.tar.xz",
                "md5": "70e617aeb1f9bbf84a12b8cf09b01ece",
                "platform": "unknow",
                "size": 462427728,
                "size_string": "441M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.7/single/qt-everywhere-opensource-src-5.9.7.tar.xz",
                "version": "5.9.7"
            },
            {
                "file_name": "qt-opensource-windows-x86-5.9.7.exe",
                "md5": "8ce733e3d1f0c134de629692f9d522ab",
                "platform": "window",
                "size": 0,
                "size_string": "2.4G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.7/qt-opensource-windows-x86-5.9.7.exe",
                "version": "5.9.7"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.9.7.run",
                "md5": "4c343350696bddd0882c0d6dd0e6e7a6",
                "platform": "linux",
                "size": 1118413395,
                "size_string": "1.0G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.7/qt-opensource-linux-x64-5.9.7.run",
                "version": "5.9.7"
            },
            {
                "file_name": "qt-opensource-mac-x64-5.9.7.dmg",
                "md5": "cd10f566022dba37036ac9b8cc86781a",
                "platform": "mac",
                "size": 0,
                "size_string": "3.6G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.7/qt-opensource-mac-x64-5.9.7.dmg",
                "version": "5.9.7"
            },
            {
                "file_name": "qt-opensource-windows-x86-pdb-files-uwp-5.9.7.7z",
                "md5": "a33a84be6962d88060120e0e56e8075f",
                "platform": "unknow",
                "size": 1307252757,
                "size_string": "1.2G",
                "type": "other",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.7/qt-opensource-windows-x86-pdb-files-uwp-5.9.7.7z",
                "version": "5.9.7"
            },
            {
                "file_name": "qt-opensource-windows-x86-pdb-files-desktop-5.9.7.7z",
                "md5": "18c7ef824425b0edb14d6b6a6b4d96fe",
                "platform": "unknow",
                "size": 1626417540,
                "size_string": "1.5G",
                "type": "other",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.7/qt-opensource-windows-x86-pdb-files-desktop-5.9.7.7z",
                "version": "5.9.7"
            }
        ],
        "preview_list": [
            "source",
            "bin",
            "other"
        ],
        "version": "5.9.7"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-5.9.6.zip",
                "md5": "a06dcc454bbaaeba5c8f25bc6d4b7a91",
                "platform": "unknow",
                "size": 713886437,
                "size_string": "681M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.6/single/qt-everywhere-opensource-src-5.9.6.zip",
                "version": "5.9.6"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.9.6.tar.xz",
                "md5": "a427428c65f35909ef6ae865b8ee0da6",
                "platform": "unknow",
                "size": 462314316,
                "size_string": "441M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.6/single/qt-everywhere-opensource-src-5.9.6.tar.xz",
                "version": "5.9.6"
            },
            {
                "file_name": "qt-opensource-windows-x86-5.9.6.exe",
                "md5": "8ae9f7a7912310e750e0a0dac49f6b0a",
                "platform": "window",
                "size": 0,
                "size_string": "2.3G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.6/qt-opensource-windows-x86-5.9.6.exe",
                "version": "5.9.6"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.9.6.run",
                "md5": "121cb276b6a31b1d1d8a545f8dcf6807",
                "platform": "linux",
                "size": 1113728992,
                "size_string": "1.0G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.6/qt-opensource-linux-x64-5.9.6.run",
                "version": "5.9.6"
            },
            {
                "file_name": "qt-opensource-mac-x64-5.9.6.dmg",
                "md5": "367ac2b4ad208983cb11d50c4e1d14a2",
                "platform": "mac",
                "size": 0,
                "size_string": "3.6G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.6/qt-opensource-mac-x64-5.9.6.dmg",
                "version": "5.9.6"
            },
            {
                "file_name": "qt-opensource-windows-x86-pdb-files-desktop-5.9.6.7z",
                "md5": "78dddfe066e6a100cd04c52cbcd40b85",
                "platform": "unknow",
                "size": 1625318926,
                "size_string": "1.5G",
                "type": "other",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.6/qt-opensource-windows-x86-pdb-files-desktop-5.9.6.7z",
                "version": "5.9.6"
            },
            {
                "file_name": "qt-opensource-windows-x86-pdb-files-uwp-5.9.6.7z",
                "md5": "2db488f9c5260a5458eb4eb26b9fdc1d",
                "platform": "unknow",
                "size": 1306508373,
                "size_string": "1.2G",
                "type": "other",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.6/qt-opensource-windows-x86-pdb-files-uwp-5.9.6.7z",
                "version": "5.9.6"
            }
        ],
        "preview_list": [
            "source",
            "bin",
            "other"
        ],
        "version": "5.9.6"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-5.9.5.tar.xz",
                "md5": "0e6eaa2d118f9854345c2debb12e8536",
                "platform": "unknow",
                "size": 462295524,
                "size_string": "441M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.5/single/qt-everywhere-opensource-src-5.9.5.tar.xz",
                "version": "5.9.5"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.9.5.zip",
                "md5": "a3f3def98cfb0c2317e94706800311c8",
                "platform": "unknow",
                "size": 713836188,
                "size_string": "681M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.5/single/qt-everywhere-opensource-src-5.9.5.zip",
                "version": "5.9.5"
            },
            {
                "file_name": "qt-opensource-windows-x86-5.9.5.exe",
                "md5": "92a57a6137f6207819e9edaad4cc94c7",
                "platform": "window",
                "size": 0,
                "size_string": "2.4G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.5/qt-opensource-windows-x86-5.9.5.exe",
                "version": "5.9.5"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.9.5.run",
                "md5": "fe29d8ef5dd98e07dfb16156d738b38c",
                "platform": "linux",
                "size": 1116581755,
                "size_string": "1.0G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.5/qt-opensource-linux-x64-5.9.5.run",
                "version": "5.9.5"
            },
            {
                "file_name": "qt-opensource-mac-x64-5.9.5.dmg",
                "md5": "d00e76128a11d44d0fc688b39f0dc98a",
                "platform": "mac",
                "size": 0,
                "size_string": "3.6G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.5/qt-opensource-mac-x64-5.9.5.dmg",
                "version": "5.9.5"
            },
            {
                "file_name": "qt-opensource-windows-x86-pdb-files-uwp-5.9.5.zip",
                "md5": "ea2fc51f7f2294a9eeac8c178f6e521f",
                "platform": "unknow",
                "size": 1396143214,
                "size_string": "1.3G",
                "type": "other",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.5/qt-opensource-windows-x86-pdb-files-uwp-5.9.5.zip",
                "version": "5.9.5"
            },
            {
                "file_name": "qt-opensource-windows-x86-pdb-files-desktop-5.9.5.zip",
                "md5": "406c610b84c8cc0601ad490b59750d60",
                "platform": "unknow",
                "size": 1653105417,
                "size_string": "1.5G",
                "type": "other",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.5/qt-opensource-windows-x86-pdb-files-desktop-5.9.5.zip",
                "version": "5.9.5"
            }
        ],
        "preview_list": [
            "source",
            "bin",
            "other"
        ],
        "version": "5.9.5"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-5.9.4.zip",
                "md5": "e41f0db33d16a04baf9fdee14d0675a0",
                "platform": "unknow",
                "size": 657375780,
                "size_string": "627M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.4/single/qt-everywhere-opensource-src-5.9.4.zip",
                "version": "5.9.4"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.9.4.tar.xz",
                "md5": "d95f6ecb491e731c2fca622ccc7b6bbd",
                "platform": "unknow",
                "size": 407499148,
                "size_string": "389M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.4/single/qt-everywhere-opensource-src-5.9.4.tar.xz",
                "version": "5.9.4"
            },
            {
                "file_name": "qt-opensource-windows-x86-5.9.4.exe",
                "md5": "557b8a778a8c613d337765489a7b598b",
                "platform": "window",
                "size": 0,
                "size_string": "2.3G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.4/qt-opensource-windows-x86-5.9.4.exe",
                "version": "5.9.4"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.9.4.run",
                "md5": "338c2af48f450b8f0c3e4ccd8e217662",
                "platform": "linux",
                "size": 1009391362,
                "size_string": "963M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.4/qt-opensource-linux-x64-5.9.4.run",
                "version": "5.9.4"
            },
            {
                "file_name": "qt-opensource-mac-x64-5.9.4.dmg",
                "md5": "0c175b9000b0f6a8718b7c5b15250bc0",
                "platform": "mac",
                "size": 0,
                "size_string": "3.5G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.4/qt-opensource-mac-x64-5.9.4.dmg",
                "version": "5.9.4"
            },
            {
                "file_name": "qt-opensource-windows-x86-pdb-files-uwp-5.9.4.zip",
                "md5": "cd407a68c1fda4d8745df258a8620166",
                "platform": "unknow",
                "size": 1394918602,
                "size_string": "1.3G",
                "type": "other",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.4/qt-opensource-windows-x86-pdb-files-uwp-5.9.4.zip",
                "version": "5.9.4"
            },
            {
                "file_name": "qt-opensource-windows-x86-pdb-files-desktop-5.9.4.zip",
                "md5": "0d18585ece12f5c5ed2eb2c70c346829",
                "platform": "unknow",
                "size": 1651303669,
                "size_string": "1.5G",
                "type": "other",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.4/qt-opensource-windows-x86-pdb-files-desktop-5.9.4.zip",
                "version": "5.9.4"
            }
        ],
        "preview_list": [
            "source",
            "bin",
            "other"
        ],
        "version": "5.9.4"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-5.9.3.tar.xz",
                "md5": "eae2435509493a2084405714e0a9bdf9",
                "platform": "unknow",
                "size": 407364020,
                "size_string": "388M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.3/single/qt-everywhere-opensource-src-5.9.3.tar.xz",
                "version": "5.9.3"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.9.3.zip",
                "md5": "4dcbffaafee2180a7e3190042d3ab7d0",
                "platform": "unknow",
                "size": 656719618,
                "size_string": "626M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.3/single/qt-everywhere-opensource-src-5.9.3.zip",
                "version": "5.9.3"
            },
            {
                "file_name": "qt-opensource-windows-x86-5.9.3.exe",
                "md5": "a3ed2bff1335555334e389b9f422ac93",
                "platform": "window",
                "size": 0,
                "size_string": "2.2G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.3/qt-opensource-windows-x86-5.9.3.exe",
                "version": "5.9.3"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.9.3.run",
                "md5": "7c78ec8413baea3e1a277221f90f77da",
                "platform": "linux",
                "size": 1005559508,
                "size_string": "959M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.3/qt-opensource-linux-x64-5.9.3.run",
                "version": "5.9.3"
            },
            {
                "file_name": "qt-opensource-mac-x64-5.9.3.dmg",
                "md5": "73ea51f28b8aef0fda1671b3418cf52b",
                "platform": "mac",
                "size": 0,
                "size_string": "3.5G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.3/qt-opensource-mac-x64-5.9.3.dmg",
                "version": "5.9.3"
            },
            {
                "file_name": "qt-opensource-windows-x86-pdb-files-desktop-5.9.3.zip",
                "md5": "c21351897a1d1f7233296c795dca7467",
                "platform": "unknow",
                "size": 0,
                "size_string": "2.0G",
                "type": "other",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.3/qt-opensource-windows-x86-pdb-files-desktop-5.9.3.zip",
                "version": "5.9.3"
            },
            {
                "file_name": "qt-opensource-windows-x86-pdb-files-uwp-5.9.3.zip",
                "md5": "e0c8413537bc52f568f2ae0a2b218228",
                "platform": "unknow",
                "size": 1389179415,
                "size_string": "1.3G",
                "type": "other",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.3/qt-opensource-windows-x86-pdb-files-uwp-5.9.3.zip",
                "version": "5.9.3"
            }
        ],
        "preview_list": [
            "source",
            "bin",
            "other"
        ],
        "version": "5.9.3"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-5.9.2.zip",
                "md5": "d5239e19f6b80dcf44f4dd2de04c7d3d",
                "platform": "unknow",
                "size": 656260395,
                "size_string": "626M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.2/single/qt-everywhere-opensource-src-5.9.2.zip",
                "version": "5.9.2"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.9.2.tar.xz",
                "md5": "738d1b98106e1bd39f00cc228beb522a",
                "platform": "unknow",
                "size": 407328184,
                "size_string": "388M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.2/single/qt-everywhere-opensource-src-5.9.2.tar.xz",
                "version": "5.9.2"
            },
            {
                "file_name": "qt-opensource-windows-x86-5.9.2.exe",
                "md5": "862f6c276f3f466d253531778bddcfca",
                "platform": "window",
                "size": 0,
                "size_string": "2.2G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.2/qt-opensource-windows-x86-5.9.2.exe",
                "version": "5.9.2"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.9.2.run",
                "md5": "6b607f21a1953c3a272de7d5a4094a47",
                "platform": "linux",
                "size": 993542287,
                "size_string": "948M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.2/qt-opensource-linux-x64-5.9.2.run",
                "version": "5.9.2"
            },
            {
                "file_name": "qt-opensource-mac-x64-5.9.2.dmg",
                "md5": "15744c26ff3a8a28cf0ccab5499b1e8f",
                "platform": "mac",
                "size": 0,
                "size_string": "3.5G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.2/qt-opensource-mac-x64-5.9.2.dmg",
                "version": "5.9.2"
            },
            {
                "file_name": "qt-opensource-windows-x86-pdb-files-desktop-5.9.2.zip",
                "md5": "8cf69f45a4653c5a980a14736d077ce9",
                "platform": "unknow",
                "size": 0,
                "size_string": "2.0G",
                "type": "other",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.2/qt-opensource-windows-x86-pdb-files-desktop-5.9.2.zip",
                "version": "5.9.2"
            },
            {
                "file_name": "qt-opensource-windows-x86-pdb-files-uwp-5.9.2.zip",
                "md5": "ab6857d98e21bf29d00b31cc1773881b",
                "platform": "unknow",
                "size": 1386479858,
                "size_string": "1.3G",
                "type": "other",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.2/qt-opensource-windows-x86-pdb-files-uwp-5.9.2.zip",
                "version": "5.9.2"
            }
        ],
        "preview_list": [
            "source",
            "bin",
            "other"
        ],
        "version": "5.9.2"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-5.9.1.tar.xz",
                "md5": "77b4af61c49a09833d4df824c806acaf",
                "platform": "unknow",
                "size": 461395248,
                "size_string": "440M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.1/single/qt-everywhere-opensource-src-5.9.1.tar.xz",
                "version": "5.9.1"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.9.1.zip",
                "md5": "3f2e538ccc468d28bcfdefac96d1e975",
                "platform": "unknow",
                "size": 731489928,
                "size_string": "698M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.1/single/qt-everywhere-opensource-src-5.9.1.zip",
                "version": "5.9.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-5.9.1.exe",
                "md5": "81765e02958d2d32cc10b8d12803c5e4",
                "platform": "window",
                "size": 0,
                "size_string": "2.3G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.1/qt-opensource-windows-x86-5.9.1.exe",
                "version": "5.9.1"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.9.1.run",
                "md5": "b8dd904894ac6b09076b5f61d8b9d71a",
                "platform": "linux",
                "size": 1032768780,
                "size_string": "1.0G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.1/qt-opensource-linux-x64-5.9.1.run",
                "version": "5.9.1"
            },
            {
                "file_name": "qt-opensource-mac-x64-5.9.1.dmg",
                "md5": "745c73b13bc33f49f75e22a335752355",
                "platform": "mac",
                "size": 0,
                "size_string": "3.5G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.1/qt-opensource-mac-x64-5.9.1.dmg",
                "version": "5.9.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-pdb-files-uwp-5.9.1.zip",
                "md5": "4a01cddb04cb64c324d56db0eeff423b",
                "platform": "unknow",
                "size": 1561899632,
                "size_string": "1.5G",
                "type": "other",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.1/qt-opensource-windows-x86-pdb-files-uwp-5.9.1.zip",
                "version": "5.9.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-pdb-files-desktop-5.9.1.zip",
                "md5": "1b6219267203a8299be767ba43b4b532",
                "platform": "unknow",
                "size": 1624021201,
                "size_string": "1.5G",
                "type": "other",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.1/qt-opensource-windows-x86-pdb-files-desktop-5.9.1.zip",
                "version": "5.9.1"
            },
            {
                "file_name": "Qt-591-qtbase-patch-for-IOS-QTBUG-61690.zip",
                "md5": "de8f682884de08d31dc84f6aa6a4442d",
                "platform": "unknow",
                "size": 4130,
                "size_string": "4.0K",
                "type": "other",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.1/Qt-591-qtbase-patch-for-IOS-QTBUG-61690.zip",
                "version": "5.9.1"
            }
        ],
        "preview_list": [
            "source",
            "bin",
            "other"
        ],
        "version": "5.9.1"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-5.9.0.zip",
                "md5": "2e5609dc9e74525a274beb74cd94ac8e",
                "platform": "unknow",
                "size": 730698195,
                "size_string": "697M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.0/single/qt-everywhere-opensource-src-5.9.0.zip",
                "version": "5.9.0"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.9.0.tar.xz",
                "md5": "9c8bc8b828c2b56721980368266df9d9",
                "platform": "unknow",
                "size": 460540904,
                "size_string": "439M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.0/single/qt-everywhere-opensource-src-5.9.0.tar.xz",
                "version": "5.9.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-5.9.0.exe",
                "md5": "314e5e84331d67c5b06824e6736ca3d8",
                "platform": "window",
                "size": 0,
                "size_string": "2.3G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.0/qt-opensource-windows-x86-5.9.0.exe",
                "version": "5.9.0"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.9.0.run",
                "md5": "5d8150fff5e3d106cd419532ecd556db",
                "platform": "linux",
                "size": 1030326091,
                "size_string": "1.0G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.0/qt-opensource-linux-x64-5.9.0.run",
                "version": "5.9.0"
            },
            {
                "file_name": "qt-opensource-mac-x64-5.9.0.dmg",
                "md5": "3d940877ea3dd22c095463bcdf6aa966",
                "platform": "mac",
                "size": 0,
                "size_string": "3.5G",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.0/qt-opensource-mac-x64-5.9.0.dmg",
                "version": "5.9.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-pdb-files-5.9.0.zip",
                "md5": "5c3e54ca3e7e2162ea0b54afd61998c5",
                "platform": "unknow",
                "size": 2112160949,
                "size_string": "2.0G",
                "type": "other",
                "url": "https://download.qt.io/archive/qt/5.9/5.9.0/qt-opensource-windows-x86-pdb-files-5.9.0.zip",
                "version": "5.9.0"
            }
        ],
        "preview_list": [
            "source",
            "bin",
            "other"
        ],
        "version": "5.9.0"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-5.8.0.tar.gz",
                "md5": "a9f2494f75f966e2f22358ec367d8f41",
                "platform": "unknow",
                "size": 487110522,
                "size_string": "465M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.8/5.8.0/single/qt-everywhere-opensource-src-5.8.0.tar.gz",
                "version": "5.8.0"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.8.0.7z",
                "md5": "c08207d36115dd543b3f0575205096db",
                "platform": "unknow",
                "size": 353390329,
                "size_string": "337M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.8/5.8.0/single/qt-everywhere-opensource-src-5.8.0.7z",
                "version": "5.8.0"
            },
            {
                "file_name": "android-patches-5.8-2017_11_16.tar.gz",
                "md5": "37dc609aee43832621e2c52d4f4fd47b",
                "platform": "unknow",
                "size": 4907,
                "size_string": "4.8K",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.8/5.8.0/android-patches-5.8-2017_11_16.tar.gz",
                "version": "5.8.0"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.8.0.zip",
                "md5": "1e372fabc9d97a32877cb4adb377e7c8",
                "platform": "unknow",
                "size": 592588955,
                "size_string": "565M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.8/5.8.0/single/qt-everywhere-opensource-src-5.8.0.zip",
                "version": "5.8.0"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.8.0.tar.xz",
                "md5": "66660cd3d9e1a6fed36e88adcb72e9fe",
                "platform": "unknow",
                "size": 370725712,
                "size_string": "354M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.8/5.8.0/single/qt-everywhere-opensource-src-5.8.0.tar.xz",
                "version": "5.8.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-winrt-msvc2015-5.8.0.exe",
                "md5": "d0cdc1c6a08ea15b77fefb8f9ea68d46",
                "platform": "window",
                "size": 1272354592,
                "size_string": "1.2G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.8/5.8.0/qt-opensource-windows-x86-winrt-msvc2015-5.8.0.exe",
                "version": "5.8.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-winrt-msvc2013-5.8.0.exe",
                "md5": "edabaf3bd9b570b5a59ad02d2189f2c8",
                "platform": "window",
                "size": 1245423208,
                "size_string": "1.2G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.8/5.8.0/qt-opensource-windows-x86-winrt-msvc2013-5.8.0.exe",
                "version": "5.8.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2015_64-5.8.0.exe",
                "md5": "3fed85c5f3cae4d68582c715a6398303",
                "platform": "window",
                "size": 1111821288,
                "size_string": "1.0G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.8/5.8.0/qt-opensource-windows-x86-msvc2015_64-5.8.0.exe",
                "version": "5.8.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2015-5.8.0.exe",
                "md5": "87a40a04536ec9469e24caaeaa24e770",
                "platform": "window",
                "size": 1082665120,
                "size_string": "1.0G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.8/5.8.0/qt-opensource-windows-x86-msvc2015-5.8.0.exe",
                "version": "5.8.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013_64-5.8.0.exe",
                "md5": "3319fc344d9b1ae7fda2f62c3ef15169",
                "platform": "window",
                "size": 1004772360,
                "size_string": "958M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.8/5.8.0/qt-opensource-windows-x86-msvc2013_64-5.8.0.exe",
                "version": "5.8.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-android-5.8.0.exe",
                "md5": "e8ad1c72b6868fa5559986ddb25e3b87",
                "platform": "window",
                "size": 1395849376,
                "size_string": "1.3G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.8/5.8.0/qt-opensource-windows-x86-android-5.8.0.exe",
                "version": "5.8.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-mingw530-5.8.0.exe",
                "md5": "f12b5a477b4d12d239039a4e97dc3cfa",
                "platform": "window",
                "size": 1340049088,
                "size_string": "1.2G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.8/5.8.0/qt-opensource-windows-x86-mingw530-5.8.0.exe",
                "version": "5.8.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013-5.8.0.exe",
                "md5": "64aa6cbaa1f9a3f0cb76efa32b73dc0d",
                "platform": "window",
                "size": 992691664,
                "size_string": "947M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.8/5.8.0/qt-opensource-windows-x86-msvc2013-5.8.0.exe",
                "version": "5.8.0"
            },
            {
                "file_name": "qt-opensource-linux-x64-android-5.8.0.run",
                "md5": "a41d16fe2d4f94dbbfebc2309a4cf31d",
                "platform": "linux",
                "size": 856955221,
                "size_string": "817M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.8/5.8.0/qt-opensource-linux-x64-android-5.8.0.run",
                "version": "5.8.0"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.8.0.run",
                "md5": "28419b4782bb9312aa6740771f93912d",
                "platform": "linux",
                "size": 803460535,
                "size_string": "766M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.8/5.8.0/qt-opensource-linux-x64-5.8.0.run",
                "version": "5.8.0"
            },
            {
                "file_name": "qt-opensource-mac-x64-android-ios-5.8.0.dmg",
                "md5": "79193b0ecc29b3f98baa7184615813f2",
                "platform": "mac",
                "size": 0,
                "size_string": "3.4G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.8/5.8.0/qt-opensource-mac-x64-android-ios-5.8.0.dmg",
                "version": "5.8.0"
            },
            {
                "file_name": "qt-opensource-mac-x64-android-5.8.0.dmg",
                "md5": "e7cdc2eabe1fa60fade556a0b6e24141",
                "platform": "mac",
                "size": 1455946250,
                "size_string": "1.4G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.8/5.8.0/qt-opensource-mac-x64-android-5.8.0.dmg",
                "version": "5.8.0"
            },
            {
                "file_name": "qt-opensource-mac-x64-clang-5.8.0.dmg",
                "md5": "4ed602eeb94912631fe6e4b6f9cc6656",
                "platform": "mac",
                "size": 1403004701,
                "size_string": "1.3G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.8/5.8.0/qt-opensource-mac-x64-clang-5.8.0.dmg",
                "version": "5.8.0"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.8.0"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-5.7.1.tar.xz",
                "md5": "7524ffba8411119d867f673155ec91bc",
                "platform": "unknow",
                "size": 337124364,
                "size_string": "322M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.7/5.7.1/single/qt-everywhere-opensource-src-5.7.1.tar.xz",
                "version": "5.7.1"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.7.1.zip",
                "md5": "d8d84c6062c15539c5ff9f6f5d781ad8",
                "platform": "unknow",
                "size": 533938787,
                "size_string": "509M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.7/5.7.1/single/qt-everywhere-opensource-src-5.7.1.zip",
                "version": "5.7.1"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.7.1.tar.gz",
                "md5": "031fb3fd0c3cc0f1082644492683f18d",
                "platform": "unknow",
                "size": 442752752,
                "size_string": "422M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.7/5.7.1/single/qt-everywhere-opensource-src-5.7.1.tar.gz",
                "version": "5.7.1"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.7.1.7z",
                "md5": "836d14fec90119bffc9405f90911fe12",
                "platform": "unknow",
                "size": 324278465,
                "size_string": "309M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.7/5.7.1/single/qt-everywhere-opensource-src-5.7.1.7z",
                "version": "5.7.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-winrt-msvc2015-5.7.1.exe",
                "md5": "2e6e48ac1c6cbc9a38f5f1c5c2f666c3",
                "platform": "window",
                "size": 1116690736,
                "size_string": "1.0G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.7/5.7.1/qt-opensource-windows-x86-winrt-msvc2015-5.7.1.exe",
                "version": "5.7.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-winrt-msvc2013-5.7.1.exe",
                "md5": "3fd6c346b16997fa24294a8142fb8b99",
                "platform": "window",
                "size": 1098492888,
                "size_string": "1.0G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.7/5.7.1/qt-opensource-windows-x86-winrt-msvc2013-5.7.1.exe",
                "version": "5.7.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2015_64-5.7.1.exe",
                "md5": "ae832a175e87d660efa40e9e82cd5e75",
                "platform": "window",
                "size": 967602312,
                "size_string": "923M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.7/5.7.1/qt-opensource-windows-x86-msvc2015_64-5.7.1.exe",
                "version": "5.7.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2015-5.7.1.exe",
                "md5": "96826dc92248bb87f8ef920a8a330f84",
                "platform": "window",
                "size": 940989608,
                "size_string": "897M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.7/5.7.1/qt-opensource-windows-x86-msvc2015-5.7.1.exe",
                "version": "5.7.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013_64-5.7.1.exe",
                "md5": "006a48e273c0c2af752cc599a50cfe5c",
                "platform": "window",
                "size": 966594992,
                "size_string": "922M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.7/5.7.1/qt-opensource-windows-x86-msvc2013_64-5.7.1.exe",
                "version": "5.7.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013-5.7.1.exe",
                "md5": "b1a60ffc60c9170ff7815e4636b48c94",
                "platform": "window",
                "size": 945535520,
                "size_string": "902M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.7/5.7.1/qt-opensource-windows-x86-msvc2013-5.7.1.exe",
                "version": "5.7.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-mingw530-5.7.1.exe",
                "md5": "bcdc84793a09e7455904b25560d456db",
                "platform": "window",
                "size": 1201453136,
                "size_string": "1.1G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.7/5.7.1/qt-opensource-windows-x86-mingw530-5.7.1.exe",
                "version": "5.7.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-android-5.7.1.exe",
                "md5": "f24c800e89a8f39e15a4b1f19a27907a",
                "platform": "window",
                "size": 1259413528,
                "size_string": "1.2G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.7/5.7.1/qt-opensource-windows-x86-android-5.7.1.exe",
                "version": "5.7.1"
            },
            {
                "file_name": "qt-opensource-linux-x64-android-5.7.1.run",
                "md5": "b0b8e957df3a0eda62b544be52f5ce86",
                "platform": "linux",
                "size": 820501986,
                "size_string": "782M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.7/5.7.1/qt-opensource-linux-x64-android-5.7.1.run",
                "version": "5.7.1"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.7.1.run",
                "md5": "1f5b84658061c4340ebff94dc500802f",
                "platform": "linux",
                "size": 764331942,
                "size_string": "729M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.7/5.7.1/qt-opensource-linux-x64-5.7.1.run",
                "version": "5.7.1"
            },
            {
                "file_name": "qt-opensource-mac-x64-android-5.7.1.dmg",
                "md5": "c19f7816d949628066685cce9673c61d",
                "platform": "mac",
                "size": 1347191888,
                "size_string": "1.3G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.7/5.7.1/qt-opensource-mac-x64-android-5.7.1.dmg",
                "version": "5.7.1"
            },
            {
                "file_name": "qt-opensource-mac-x64-android-ios-5.7.1.dmg",
                "md5": "b9d47b8a2fffafb99cc043a05b0c704b",
                "platform": "mac",
                "size": 0,
                "size_string": "2.7G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.7/5.7.1/qt-opensource-mac-x64-android-ios-5.7.1.dmg",
                "version": "5.7.1"
            },
            {
                "file_name": "qt-opensource-mac-x64-clang-5.7.1.dmg",
                "md5": "d9085283ed3ef8eb3ceb88b2fd8526a3",
                "platform": "mac",
                "size": 1292066061,
                "size_string": "1.2G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.7/5.7.1/qt-opensource-mac-x64-clang-5.7.1.dmg",
                "version": "5.7.1"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.7.1"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-5.7.0.zip",
                "md5": "c5d4bb1d015c2cfd37d183e5d201051f",
                "platform": "unknow",
                "size": 532154185,
                "size_string": "508M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.7/5.7.0/single/qt-everywhere-opensource-src-5.7.0.zip",
                "version": "5.7.0"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.7.0.tar.xz",
                "md5": "63ec6b584757eef8cd713e4958297251",
                "platform": "unknow",
                "size": 337389384,
                "size_string": "322M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.7/5.7.0/single/qt-everywhere-opensource-src-5.7.0.tar.xz",
                "version": "5.7.0"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.7.0.tar.gz",
                "md5": "9a46cce61fc64c20c3ac0a0e0fa41b42",
                "platform": "unknow",
                "size": 441445524,
                "size_string": "421M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.7/5.7.0/single/qt-everywhere-opensource-src-5.7.0.tar.gz",
                "version": "5.7.0"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.7.0.7z",
                "md5": "a0c4f5fd6f53c229808486c270ff6865",
                "platform": "unknow",
                "size": 316156510,
                "size_string": "302M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.7/5.7.0/single/qt-everywhere-opensource-src-5.7.0.7z",
                "version": "5.7.0"
            },
            {
                "file_name": "android-patches-5.7-2017_11_16.tar.gz",
                "md5": "3844fc3d8ca25698c800ef34bd5b708b",
                "platform": "unknow",
                "size": 4877,
                "size_string": "4.8K",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.7/5.7.1/android-patches-5.7-2017_11_16.tar.gz",
                "version": "5.7.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-winrt-msvc2015-5.7.0.exe",
                "md5": "91fb339062ad0f29f00a218ba9b848f3",
                "platform": "window",
                "size": 1171042688,
                "size_string": "1.1G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.7/5.7.0/qt-opensource-windows-x86-winrt-msvc2015-5.7.0.exe",
                "version": "5.7.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-winrt-msvc2013-5.7.0.exe",
                "md5": "1b1592c0fb145e78ddddd84ad6db6576",
                "platform": "window",
                "size": 1125537504,
                "size_string": "1.0G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.7/5.7.0/qt-opensource-windows-x86-winrt-msvc2013-5.7.0.exe",
                "version": "5.7.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2015_64-5.7.0.exe",
                "md5": "909c4092b451782948815505fa1280fa",
                "platform": "window",
                "size": 962917256,
                "size_string": "918M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.7/5.7.0/qt-opensource-windows-x86-msvc2015_64-5.7.0.exe",
                "version": "5.7.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013_64-5.7.0.exe",
                "md5": "0c08da68d21aac7ab328232eaafde9e7",
                "platform": "window",
                "size": 947886512,
                "size_string": "904M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.7/5.7.0/qt-opensource-windows-x86-msvc2013_64-5.7.0.exe",
                "version": "5.7.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013-5.7.0.exe",
                "md5": "ad45635b93046b0626aa670353065886",
                "platform": "window",
                "size": 952907496,
                "size_string": "909M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.7/5.7.0/qt-opensource-windows-x86-msvc2013-5.7.0.exe",
                "version": "5.7.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-mingw530-5.7.0.exe",
                "md5": "7780c263ff69c1809015eee644bde9e4",
                "platform": "window",
                "size": 1188556280,
                "size_string": "1.1G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.7/5.7.0/qt-opensource-windows-x86-mingw530-5.7.0.exe",
                "version": "5.7.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-android-5.7.0.exe",
                "md5": "518be2a341d70df61b3749018c441c60",
                "platform": "window",
                "size": 1247483552,
                "size_string": "1.2G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.7/5.7.0/qt-opensource-windows-x86-android-5.7.0.exe",
                "version": "5.7.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2015-5.7.0.exe",
                "md5": "200f4b38ea3624856a004fd516a2ef22",
                "platform": "window",
                "size": 968695896,
                "size_string": "924M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.7/5.7.0/qt-opensource-windows-x86-msvc2015-5.7.0.exe",
                "version": "5.7.0"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.7.0.run",
                "md5": "af7cd585b6e44279ee6dbf96e377ad74",
                "platform": "linux",
                "size": 749414423,
                "size_string": "715M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.7/5.7.0/qt-opensource-linux-x64-5.7.0.run",
                "version": "5.7.0"
            },
            {
                "file_name": "qt-opensource-linux-x64-android-5.7.0.run",
                "md5": "90be985646a2c0ddb0731de4de08cb8e",
                "platform": "linux",
                "size": 806716191,
                "size_string": "769M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.7/5.7.0/qt-opensource-linux-x64-android-5.7.0.run",
                "version": "5.7.0"
            },
            {
                "file_name": "qt-opensource-mac-x64-clang-5.7.0.dmg",
                "md5": "31e1b101e0053eefee071c80e30650f8",
                "platform": "mac",
                "size": 812334337,
                "size_string": "775M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.7/5.7.0/qt-opensource-mac-x64-clang-5.7.0.dmg",
                "version": "5.7.0"
            },
            {
                "file_name": "qt-opensource-mac-x64-android-ios-5.7.0.dmg",
                "md5": "68f37202230b6230e07d824a94a9e89b",
                "platform": "mac",
                "size": 0,
                "size_string": "2.3G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.7/5.7.0/qt-opensource-mac-x64-android-ios-5.7.0.dmg",
                "version": "5.7.0"
            },
            {
                "file_name": "qt-opensource-mac-x64-android-5.7.0.dmg",
                "md5": "2da9d3baab9d8a19cef2e121368a0110",
                "platform": "mac",
                "size": 868456561,
                "size_string": "828M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.7/5.7.0/qt-opensource-mac-x64-android-5.7.0.dmg",
                "version": "5.7.0"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.7.0"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-5.6.3.zip",
                "md5": "f9e6cd1b6e336899369d26bf757d7457",
                "platform": "unknow",
                "size": 508020583,
                "size_string": "484M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.3/single/qt-everywhere-opensource-src-5.6.3.zip",
                "version": "5.6.3"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.6.3.tar.xz",
                "md5": "010342d515b62ee1c0e709254f4ef9ab",
                "platform": "unknow",
                "size": 319071028,
                "size_string": "304M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.3/single/qt-everywhere-opensource-src-5.6.3.tar.xz",
                "version": "5.6.3"
            },
            {
                "file_name": "qt-opensource-windows-x86-winrt-msvc2013-5.6.3.exe",
                "md5": "b1a0df49334312912e49ab048d158e01",
                "platform": "window",
                "size": 1032490048,
                "size_string": "1.0G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.3/qt-opensource-windows-x86-winrt-msvc2013-5.6.3.exe",
                "version": "5.6.3"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2015-5.6.3.exe",
                "md5": "2e390adc1c0b5db50a72c4bc141a3c45",
                "platform": "window",
                "size": 910787032,
                "size_string": "869M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.3/qt-opensource-windows-x86-msvc2015-5.6.3.exe",
                "version": "5.6.3"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013_64-5.6.3.exe",
                "md5": "99d991228270fa1fdafc6269ac649fb2",
                "platform": "window",
                "size": 916316608,
                "size_string": "874M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.3/qt-opensource-windows-x86-msvc2013_64-5.6.3.exe",
                "version": "5.6.3"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013-5.6.3.exe",
                "md5": "a07af76895e7193cec0cb5f30f64ebd3",
                "platform": "window",
                "size": 896083872,
                "size_string": "855M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.3/qt-opensource-windows-x86-msvc2013-5.6.3.exe",
                "version": "5.6.3"
            },
            {
                "file_name": "qt-opensource-windows-x86-mingw492-5.6.3.exe",
                "md5": "78cfa7ccc842ec769b9f5bec9397d4be",
                "platform": "window",
                "size": 1120427664,
                "size_string": "1.0G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.3/qt-opensource-windows-x86-mingw492-5.6.3.exe",
                "version": "5.6.3"
            },
            {
                "file_name": "qt-opensource-windows-x86-android-5.6.3.exe",
                "md5": "c78455e39f7bc0971e01d3834b3eb741",
                "platform": "window",
                "size": 1174520024,
                "size_string": "1.1G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.3/qt-opensource-windows-x86-android-5.6.3.exe",
                "version": "5.6.3"
            },
            {
                "file_name": "qt-opensource-windows-x86-winrt-msvc2015-5.6.3.exe",
                "md5": "65b43b001a6a3a3f47f634609d0d8db0",
                "platform": "window",
                "size": 1076238288,
                "size_string": "1.0G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.3/qt-opensource-windows-x86-winrt-msvc2015-5.6.3.exe",
                "version": "5.6.3"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2015_64-5.6.3.exe",
                "md5": "fbdcf2542a6c305f279ae29925c53bed",
                "platform": "window",
                "size": 935857240,
                "size_string": "893M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.3/qt-opensource-windows-x86-msvc2015_64-5.6.3.exe",
                "version": "5.6.3"
            },
            {
                "file_name": "qt-opensource-linux-x64-android-5.6.3.run",
                "md5": "d215b836decd0e8d4c872ba0f6770c42",
                "platform": "linux",
                "size": 780177608,
                "size_string": "744M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.3/qt-opensource-linux-x64-android-5.6.3.run",
                "version": "5.6.3"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.6.3.run",
                "md5": "594c4f25ba5e788e64874dc092135862",
                "platform": "linux",
                "size": 713716970,
                "size_string": "681M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.3/qt-opensource-linux-x64-5.6.3.run",
                "version": "5.6.3"
            },
            {
                "file_name": "qt-opensource-mac-x64-clang-5.6.3.dmg",
                "md5": "6f62e6b2bdd8a9e973cdacf3de048869",
                "platform": "mac",
                "size": 1228308383,
                "size_string": "1.1G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.3/qt-opensource-mac-x64-clang-5.6.3.dmg",
                "version": "5.6.3"
            },
            {
                "file_name": "qt-opensource-mac-x64-android-5.6.3.dmg",
                "md5": "4f37965f7e6a0125b26228bec45bbf92",
                "platform": "mac",
                "size": 1280823420,
                "size_string": "1.2G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.3/qt-opensource-mac-x64-android-5.6.3.dmg",
                "version": "5.6.3"
            },
            {
                "file_name": "qt-opensource-mac-x64-android-ios-5.6.3.dmg",
                "md5": "cc43a5b9288b051737b87c61d3d6e063",
                "platform": "mac",
                "size": 0,
                "size_string": "2.5G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.3/qt-opensource-mac-x64-android-ios-5.6.3.dmg",
                "version": "5.6.3"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.6.3"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-5.6.2.zip",
                "md5": "b684a2f37b1beebd421b3b7d1eca15dc",
                "platform": "unknow",
                "size": 486599859,
                "size_string": "464M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.2/single/qt-everywhere-opensource-src-5.6.2.zip",
                "version": "5.6.2"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.6.2.tar.xz",
                "md5": "5175fba2f221fd4c91e94771a57a5557",
                "platform": "unknow",
                "size": 297863804,
                "size_string": "284M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.2/single/qt-everywhere-opensource-src-5.6.2.tar.xz",
                "version": "5.6.2"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.6.2.tar.gz",
                "md5": "1b1b1f929d0cd83680354a0c83d8e945",
                "platform": "unknow",
                "size": 401160911,
                "size_string": "383M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.2/single/qt-everywhere-opensource-src-5.6.2.tar.gz",
                "version": "5.6.2"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.6.2.7z",
                "md5": "f66101b9b928217b420d87683263df49",
                "platform": "unknow",
                "size": 282751106,
                "size_string": "270M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.2/single/qt-everywhere-opensource-src-5.6.2.7z",
                "version": "5.6.2"
            },
            {
                "file_name": "qt-opensource-windows-x86-mingw492-5.6.2.exe",
                "md5": "1fe9b1cc341eb7b59698a0fdcdfdad20",
                "platform": "window",
                "size": 1086526944,
                "size_string": "1.0G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.2/qt-opensource-windows-x86-mingw492-5.6.2.exe",
                "version": "5.6.2"
            },
            {
                "file_name": "qt-opensource-windows-x86-android-5.6.2.exe",
                "md5": "bff2a98f24dfc7c6dcecae5635f24343",
                "platform": "window",
                "size": 1155817984,
                "size_string": "1.1G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.2/qt-opensource-windows-x86-android-5.6.2.exe",
                "version": "5.6.2"
            },
            {
                "file_name": "qt-opensource-windows-x86-winrt-msvc2015-5.6.2.exe",
                "md5": "25c25f5350fbdb0d771835905d74c8cc",
                "platform": "window",
                "size": 1006827720,
                "size_string": "960M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.2/qt-opensource-windows-x86-winrt-msvc2015-5.6.2.exe",
                "version": "5.6.2"
            },
            {
                "file_name": "qt-opensource-windows-x86-winrt-msvc2013-5.6.2.exe",
                "md5": "b4af1172e666ed932b43fe6351e3c770",
                "platform": "window",
                "size": 984820888,
                "size_string": "939M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.2/qt-opensource-windows-x86-winrt-msvc2013-5.6.2.exe",
                "version": "5.6.2"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2015_64-5.6.2.exe",
                "md5": "26a99e0482fa9149e4e1b384b4d67153",
                "platform": "window",
                "size": 879418776,
                "size_string": "839M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.2/qt-opensource-windows-x86-msvc2015_64-5.6.2.exe",
                "version": "5.6.2"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2015-5.6.2.exe",
                "md5": "463950a2fa17c322d02e59405f8aa41b",
                "platform": "window",
                "size": 855093304,
                "size_string": "815M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.2/qt-opensource-windows-x86-msvc2015-5.6.2.exe",
                "version": "5.6.2"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013_64-5.6.2.exe",
                "md5": "961438679bb36b568fe1fa8c22889217",
                "platform": "window",
                "size": 872647424,
                "size_string": "832M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.2/qt-opensource-windows-x86-msvc2013_64-5.6.2.exe",
                "version": "5.6.2"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013-5.6.2.exe",
                "md5": "f061d1a7d0ea5b29374c9ffbe6e368f1",
                "platform": "window",
                "size": 853060472,
                "size_string": "814M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.2/qt-opensource-windows-x86-msvc2013-5.6.2.exe",
                "version": "5.6.2"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.6.2.run",
                "md5": "adadb325f2593e0f9a3ef5cb13d874bd",
                "platform": "linux",
                "size": 685115038,
                "size_string": "653M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.2/qt-opensource-linux-x64-5.6.2.run",
                "version": "5.6.2"
            },
            {
                "file_name": "qt-opensource-linux-x64-android-5.6.2.run",
                "md5": "7b847bad17a92daa6913bd5b242d2a9a",
                "platform": "linux",
                "size": 750837299,
                "size_string": "716M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.2/qt-opensource-linux-x64-android-5.6.2.run",
                "version": "5.6.2"
            },
            {
                "file_name": "qt-opensource-mac-x64-clang-5.6.2.dmg",
                "md5": "c51feba344c5d29f7a859eda68b41806",
                "platform": "mac",
                "size": 1154896329,
                "size_string": "1.1G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.2/qt-opensource-mac-x64-clang-5.6.2.dmg",
                "version": "5.6.2"
            },
            {
                "file_name": "qt-opensource-mac-x64-android-ios-5.6.2.dmg",
                "md5": "7cd7693c762f5830562cc268825c79e2",
                "platform": "mac",
                "size": 0,
                "size_string": "2.4G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.2/qt-opensource-mac-x64-android-ios-5.6.2.dmg",
                "version": "5.6.2"
            },
            {
                "file_name": "qt-opensource-mac-x64-android-5.6.2.dmg",
                "md5": "1ee63ba81604c13df5430a178db337ed",
                "platform": "mac",
                "size": 1207077091,
                "size_string": "1.1G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.2/qt-opensource-mac-x64-android-5.6.2.dmg",
                "version": "5.6.2"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.6.2"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-5.6.1.zip",
                "md5": "c18a4904d9773894437c10c25f93e50f",
                "platform": "unknow",
                "size": 486472826,
                "size_string": "464M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.1/single/qt-everywhere-opensource-src-5.6.1.zip",
                "version": "5.6.1"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.6.1.tar.xz",
                "md5": "d647574345c45b5ab8b41b2d46efffb9",
                "platform": "unknow",
                "size": 297805000,
                "size_string": "284M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.1/single/qt-everywhere-opensource-src-5.6.1.tar.xz",
                "version": "5.6.1"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.6.1.tar.gz",
                "md5": "ed16ef2a30c674f91f8615678005d44c",
                "platform": "unknow",
                "size": 401089378,
                "size_string": "383M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.1/single/qt-everywhere-opensource-src-5.6.1.tar.gz",
                "version": "5.6.1"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.6.1.7z",
                "md5": "abb8290df0f1ebe8e6c384a64a75760d",
                "platform": "unknow",
                "size": 282858787,
                "size_string": "270M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.1/single/qt-everywhere-opensource-src-5.6.1.7z",
                "version": "5.6.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-mingw492-5.6.1.exe",
                "md5": "9965f44bef87bf36ae6ef1e47c0d5eaa",
                "platform": "window",
                "size": 1087585832,
                "size_string": "1.0G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.1/qt-opensource-windows-x86-mingw492-5.6.1.exe",
                "version": "5.6.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-android-5.6.1.exe",
                "md5": "5dd15d78878fa824c41733c5c0a3fbc6",
                "platform": "window",
                "size": 1157273896,
                "size_string": "1.1G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.1/qt-opensource-windows-x86-android-5.6.1.exe",
                "version": "5.6.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2015_64-5.6.1.exe",
                "md5": "07c29dcb186aae3506f02b65c942685a",
                "platform": "window",
                "size": 872640936,
                "size_string": "832M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.1/qt-opensource-windows-x86-msvc2015_64-5.6.1.exe",
                "version": "5.6.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-winrt-msvc2015-5.6.1.exe",
                "md5": "27f2091fac59fd8bd79cf600d61e2735",
                "platform": "window",
                "size": 1054688960,
                "size_string": "1.0G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.1/qt-opensource-windows-x86-winrt-msvc2015-5.6.1.exe",
                "version": "5.6.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-winrt-msvc2013-5.6.1.exe",
                "md5": "ebf16b3abe4f3f7449f3675fa77cf3ad",
                "platform": "window",
                "size": 1024612224,
                "size_string": "1.0G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.1/qt-opensource-windows-x86-winrt-msvc2013-5.6.1.exe",
                "version": "5.6.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2015-5.6.1.exe",
                "md5": "d50b466fea2a5f2f590e6880d481599d",
                "platform": "window",
                "size": 877153816,
                "size_string": "837M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.1/qt-opensource-windows-x86-msvc2015-5.6.1.exe",
                "version": "5.6.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013_64-5.6.1.exe",
                "md5": "67aee29dee76f4857e73edb5d681fe36",
                "platform": "window",
                "size": 870367624,
                "size_string": "830M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.1/qt-opensource-windows-x86-msvc2013_64-5.6.1.exe",
                "version": "5.6.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013-5.6.1.exe",
                "md5": "af5cb4dfc56490333777368226cb894c",
                "platform": "window",
                "size": 874258344,
                "size_string": "834M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.1/qt-opensource-windows-x86-msvc2013-5.6.1.exe",
                "version": "5.6.1"
            },
            {
                "file_name": "qt-opensource-linux-x64-android-5.6.1.run",
                "md5": "0b4dcda80267c1f2f1580cbaf436311a",
                "platform": "linux",
                "size": 752357429,
                "size_string": "718M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.1/qt-opensource-linux-x64-android-5.6.1.run",
                "version": "5.6.1"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.6.1.run",
                "md5": "2f2509434539b77435e858afc9ffe083",
                "platform": "linux",
                "size": 686461914,
                "size_string": "655M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.1/qt-opensource-linux-x64-5.6.1.run",
                "version": "5.6.1"
            },
            {
                "file_name": "qt-opensource-mac-x64-clang-5.6.1.dmg",
                "md5": "4da352c02eb755f888aeb2aa7df9d0d7",
                "platform": "mac",
                "size": 739364765,
                "size_string": "705M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.1/qt-opensource-mac-x64-clang-5.6.1.dmg",
                "version": "5.6.1"
            },
            {
                "file_name": "qt-opensource-mac-x64-android-ios-5.6.1.dmg",
                "md5": "5bb9c6b31ecee382c7c1c81195921ff9",
                "platform": "mac",
                "size": 0,
                "size_string": "2.1G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.1/qt-opensource-mac-x64-android-ios-5.6.1.dmg",
                "version": "5.6.1"
            },
            {
                "file_name": "qt-opensource-mac-x64-android-5.6.1.dmg",
                "md5": "83cb19ade95134eb423754957e0dd7ff",
                "platform": "mac",
                "size": 791700372,
                "size_string": "755M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.1/qt-opensource-mac-x64-android-5.6.1.dmg",
                "version": "5.6.1"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.6.1"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-5.6.0.zip",
                "md5": "03402708f260dcc917abae9bc559c1df",
                "platform": "unknow",
                "size": 486499784,
                "size_string": "464M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.0/single/qt-everywhere-opensource-src-5.6.0.zip",
                "version": "5.6.0"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.6.0.tar.xz",
                "md5": "47763c168f58b1196271b231f03c8bae",
                "platform": "unknow",
                "size": 298138624,
                "size_string": "284M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.0/single/qt-everywhere-opensource-src-5.6.0.tar.xz",
                "version": "5.6.0"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.6.0.tar.gz",
                "md5": "7a2a867bc12384f4161809136d49d4be",
                "platform": "unknow",
                "size": 401277582,
                "size_string": "383M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.0/single/qt-everywhere-opensource-src-5.6.0.tar.gz",
                "version": "5.6.0"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.6.0.7z",
                "md5": "6e109cbfd2e586213e44a996f0ab7ff3",
                "platform": "unknow",
                "size": 283121945,
                "size_string": "270M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.0/single/qt-everywhere-opensource-src-5.6.0.7z",
                "version": "5.6.0"
            },
            {
                "file_name": "android-patches-5.6-2017_11_16.tar.gz",
                "md5": "51d01fc19702bf954d40141fd2db44a7",
                "platform": "unknow",
                "size": 3475,
                "size_string": "3.4K",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.3/android-patches-5.6-2017_11_16.tar.gz",
                "version": "5.6.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-winrt-msvc2015-5.6.0.exe",
                "md5": "d00fc4b1a90f34e907d7d343ff321050",
                "platform": "window",
                "size": 1102179280,
                "size_string": "1.0G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.0/qt-opensource-windows-x86-winrt-msvc2015-5.6.0.exe",
                "version": "5.6.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-winrt-msvc2013-5.6.0.exe",
                "md5": "14c59bb4922ca2afbdc6876be5248c7c",
                "platform": "window",
                "size": 1025744088,
                "size_string": "1.0G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.0/qt-opensource-windows-x86-winrt-msvc2013-5.6.0.exe",
                "version": "5.6.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2015_64-5.6.0.exe",
                "md5": "7f7e3efb7422f83ca50aa5fbe3c54672",
                "platform": "window",
                "size": 876893632,
                "size_string": "836M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.0/qt-opensource-windows-x86-msvc2015_64-5.6.0.exe",
                "version": "5.6.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2015-5.6.0.exe",
                "md5": "d93034f6f10d5e4d62cd34b998a302bb",
                "platform": "window",
                "size": 881079552,
                "size_string": "840M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.0/qt-opensource-windows-x86-msvc2015-5.6.0.exe",
                "version": "5.6.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013_64-5.6.0.exe",
                "md5": "3a4094615237f503454faabcd4cfd850",
                "platform": "window",
                "size": 876525680,
                "size_string": "836M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.0/qt-opensource-windows-x86-msvc2013_64-5.6.0.exe",
                "version": "5.6.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-android-5.6.0.exe",
                "md5": "83d854189fc453ef05e280c295911d42",
                "platform": "window",
                "size": 1166947648,
                "size_string": "1.1G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.0/qt-opensource-windows-x86-android-5.6.0.exe",
                "version": "5.6.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-mingw492-5.6.0.exe",
                "md5": "6e1ebb7fd152c63396efca0fac1f3506",
                "platform": "window",
                "size": 1097519096,
                "size_string": "1.0G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.0/qt-opensource-windows-x86-mingw492-5.6.0.exe",
                "version": "5.6.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013-5.6.0.exe",
                "md5": "12f540d1cd154610e794a5c1e5bfd22a",
                "platform": "window",
                "size": 880357240,
                "size_string": "840M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.0/qt-opensource-windows-x86-msvc2013-5.6.0.exe",
                "version": "5.6.0"
            },
            {
                "file_name": "qt-opensource-linux-x64-android-5.6.0.run",
                "md5": "ffb9feee7d30c2327adf97db83efc603",
                "platform": "linux",
                "size": 752138715,
                "size_string": "717M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.0/qt-opensource-linux-x64-android-5.6.0.run",
                "version": "5.6.0"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.6.0.run",
                "md5": "8ef8e13a8142b51987b4656e97e58c3a",
                "platform": "linux",
                "size": 693097139,
                "size_string": "661M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.0/qt-opensource-linux-x64-5.6.0.run",
                "version": "5.6.0"
            },
            {
                "file_name": "qt-opensource-mac-x64-android-5.6.0.dmg",
                "md5": "4abdf6b07f3ac2c674a81e6fce0d0aa0",
                "platform": "mac",
                "size": 798094545,
                "size_string": "761M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.0/qt-opensource-mac-x64-android-5.6.0.dmg",
                "version": "5.6.0"
            },
            {
                "file_name": "qt-opensource-mac-x64-clang-5.6.0.dmg",
                "md5": "fbac33b0af93bb958b6d613758a638c9",
                "platform": "mac",
                "size": 744538261,
                "size_string": "710M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.0/qt-opensource-mac-x64-clang-5.6.0.dmg",
                "version": "5.6.0"
            },
            {
                "file_name": "qt-opensource-mac-x64-android-ios-5.6.0.dmg",
                "md5": "ceb7fac5e51e62ceead8a212c38eb61f",
                "platform": "mac",
                "size": 0,
                "size_string": "2.1G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.6/5.6.0/qt-opensource-mac-x64-android-ios-5.6.0.dmg",
                "version": "5.6.0"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.6.0"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-5.5.1.zip",
                "md5": "d071cdaa1b8f34fb49f6deac94039c2c",
                "platform": "unknow",
                "size": 568461846,
                "size_string": "542M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.5/5.5.1/single/qt-everywhere-opensource-src-5.5.1.zip",
                "version": "5.5.1"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.5.1.tar.xz",
                "md5": "c2a249e8795d45200d997d96860d0353",
                "platform": "unknow",
                "size": 320459924,
                "size_string": "306M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.5/5.5.1/single/qt-everywhere-opensource-src-5.5.1.tar.xz",
                "version": "5.5.1"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.5.1.tar.gz",
                "md5": "59f0216819152b77536cf660b015d784",
                "platform": "unknow",
                "size": 458345433,
                "size_string": "437M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.5/5.5.1/single/qt-everywhere-opensource-src-5.5.1.tar.gz",
                "version": "5.5.1"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.5.1.7z",
                "md5": "545d6600e5de5e5fd64214e75036dfef",
                "platform": "unknow",
                "size": 312170178,
                "size_string": "298M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.5/5.5.1/single/qt-everywhere-opensource-src-5.5.1.7z",
                "version": "5.5.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013_64-5.5.1.exe",
                "md5": "7a57d04fa617061a94bcf6615b7eeb93",
                "platform": "window",
                "size": 862863576,
                "size_string": "823M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.5/5.5.1/qt-opensource-windows-x86-msvc2013_64-5.5.1.exe",
                "version": "5.5.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013-5.5.1.exe",
                "md5": "0fc059a021e06eabce45ff86b225ff3e",
                "platform": "window",
                "size": 843361704,
                "size_string": "804M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.5/5.5.1/qt-opensource-windows-x86-msvc2013-5.5.1.exe",
                "version": "5.5.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2010-5.5.1.exe",
                "md5": "fa86ea95939bf96afe3d550d66abd699",
                "platform": "window",
                "size": 759697872,
                "size_string": "725M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.5/5.5.1/qt-opensource-windows-x86-msvc2010-5.5.1.exe",
                "version": "5.5.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2012-5.5.1.exe",
                "md5": "336441c6ab65180cd32e86ca1259d9f1",
                "platform": "window",
                "size": 783317272,
                "size_string": "747M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.5/5.5.1/qt-opensource-windows-x86-msvc2012-5.5.1.exe",
                "version": "5.5.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-android-5.5.1.exe",
                "md5": "e17aa656fdaab73a0bd4808aebd2fc8d",
                "platform": "window",
                "size": 1141191464,
                "size_string": "1.1G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.5/5.5.1/qt-opensource-windows-x86-android-5.5.1.exe",
                "version": "5.5.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-mingw492-5.5.1.exe",
                "md5": "ed8d97790607724937ac8a274b7ca292",
                "platform": "window",
                "size": 1045991056,
                "size_string": "1.0G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.5/5.5.1/qt-opensource-windows-x86-mingw492-5.5.1.exe",
                "version": "5.5.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-winrt-5.5.1.exe",
                "md5": "c286b71f41701decbb79475380d0b329",
                "platform": "window",
                "size": 958871472,
                "size_string": "914M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.5/5.5.1/qt-opensource-windows-x86-winrt-5.5.1.exe",
                "version": "5.5.1"
            },
            {
                "file_name": "qt-opensource-linux-x86-5.5.1.run",
                "md5": "4170a95a73e1e9c465fd6ce2066efcb3",
                "platform": "linux",
                "size": 664790208,
                "size_string": "634M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.5/5.5.1/qt-opensource-linux-x86-5.5.1.run",
                "version": "5.5.1"
            },
            {
                "file_name": "qt-opensource-linux-x86-android-5.5.1.run",
                "md5": "25f6d4bba045d4336f3a9e7f3df14625",
                "platform": "linux",
                "size": 745444485,
                "size_string": "711M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.5/5.5.1/qt-opensource-linux-x86-android-5.5.1.run",
                "version": "5.5.1"
            },
            {
                "file_name": "qt-opensource-linux-x64-android-5.5.1.run",
                "md5": "0c978132a470a1d4163f13d72e40f240",
                "platform": "linux",
                "size": 732044353,
                "size_string": "698M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.5/5.5.1/qt-opensource-linux-x64-android-5.5.1.run",
                "version": "5.5.1"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.5.1.run",
                "md5": "cbac60a44ae91a35aa6c5ddc872d0408",
                "platform": "linux",
                "size": 652064113,
                "size_string": "622M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.5/5.5.1/qt-opensource-linux-x64-5.5.1.run",
                "version": "5.5.1"
            },
            {
                "file_name": "qt-opensource-mac-x64-clang-5.5.1.dmg",
                "md5": "ad0932bd67fe1b6fde8982f034089e04",
                "platform": "mac",
                "size": 711712261,
                "size_string": "679M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.5/5.5.1/qt-opensource-mac-x64-clang-5.5.1.dmg",
                "version": "5.5.1"
            },
            {
                "file_name": "qt-opensource-mac-x64-android-ios-5.5.1.dmg",
                "md5": "c604c756564318d44eafa1c459d7a83d",
                "platform": "mac",
                "size": 2113568501,
                "size_string": "2.0G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.5/5.5.1/qt-opensource-mac-x64-android-ios-5.5.1.dmg",
                "version": "5.5.1"
            },
            {
                "file_name": "qt-opensource-mac-x64-android-5.5.1.dmg",
                "md5": "c90802b8987f41a7c9091b88b594b3dd",
                "platform": "mac",
                "size": 783011909,
                "size_string": "747M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.5/5.5.1/qt-opensource-mac-x64-android-5.5.1.dmg",
                "version": "5.5.1"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.5.1"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-5.5.0.zip",
                "md5": "299abca0a091b6b06cbbc6387f4fff9f",
                "platform": "unknow",
                "size": 566204740,
                "size_string": "540M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.5/5.5.0/single/qt-everywhere-opensource-src-5.5.0.zip",
                "version": "5.5.0"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.5.0.tar.xz",
                "md5": "65d5282f3dee0336da9ed1f77148952f",
                "platform": "unknow",
                "size": 319474656,
                "size_string": "305M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.5/5.5.0/single/qt-everywhere-opensource-src-5.5.0.tar.xz",
                "version": "5.5.0"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.5.0.tar.gz",
                "md5": "828594c91ba736ce2cd3e1e8a6146452",
                "platform": "unknow",
                "size": 456925175,
                "size_string": "436M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.5/5.5.0/single/qt-everywhere-opensource-src-5.5.0.tar.gz",
                "version": "5.5.0"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.5.0.7z",
                "md5": "3b6f8ebf887b203a74913ef019b2d7b9",
                "platform": "unknow",
                "size": 311279286,
                "size_string": "297M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.5/5.5.0/single/qt-everywhere-opensource-src-5.5.0.7z",
                "version": "5.5.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-winrt-5.5.0.exe",
                "md5": "9fd4f7fcfafafc795cce886a05e06c47",
                "platform": "window",
                "size": 650945264,
                "size_string": "621M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.5/5.5.0/qt-opensource-windows-x86-winrt-5.5.0.exe",
                "version": "5.5.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2012-5.5.0.exe",
                "md5": "a266ab2edda346276543b9983753c180",
                "platform": "window",
                "size": 615209824,
                "size_string": "587M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.5/5.5.0/qt-opensource-windows-x86-msvc2012-5.5.0.exe",
                "version": "5.5.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013_64-5.5.0.exe",
                "md5": "80b7b1b1142159458adff00b6b0cc530",
                "platform": "window",
                "size": 681889736,
                "size_string": "650M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.5/5.5.0/qt-opensource-windows-x86-msvc2013_64-5.5.0.exe",
                "version": "5.5.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013-5.5.0.exe",
                "md5": "ae46f8d92b8f627cb83ac0af5b4b44af",
                "platform": "window",
                "size": 664203024,
                "size_string": "633M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.5/5.5.0/qt-opensource-windows-x86-msvc2013-5.5.0.exe",
                "version": "5.5.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2010-5.5.0.exe",
                "md5": "e0dc46e09cdef616fe0332b83bbfc110",
                "platform": "window",
                "size": 613489832,
                "size_string": "585M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.5/5.5.0/qt-opensource-windows-x86-msvc2010-5.5.0.exe",
                "version": "5.5.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-mingw492-5.5.0.exe",
                "md5": "bd22638482ccd16fe8e542d350f869e4",
                "platform": "window",
                "size": 1005456984,
                "size_string": "959M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.5/5.5.0/qt-opensource-windows-x86-mingw492-5.5.0.exe",
                "version": "5.5.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-android-5.5.0.exe",
                "md5": "508e7e6846c630c3551b7ccb52b3f65e",
                "platform": "window",
                "size": 1100294920,
                "size_string": "1.0G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.5/5.5.0/qt-opensource-windows-x86-android-5.5.0.exe",
                "version": "5.5.0"
            },
            {
                "file_name": "qt-opensource-linux-x86-android-5.5.0.run",
                "md5": "bc493de11dcb6b4f78c9bf0a75c688d4",
                "platform": "linux",
                "size": 718803436,
                "size_string": "686M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.5/5.5.0/qt-opensource-linux-x86-android-5.5.0.run",
                "version": "5.5.0"
            },
            {
                "file_name": "qt-opensource-linux-x86-5.5.0.run",
                "md5": "e00c69eae1634aae5b71641a7ac78076",
                "platform": "linux",
                "size": 638584067,
                "size_string": "609M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.5/5.5.0/qt-opensource-linux-x86-5.5.0.run",
                "version": "5.5.0"
            },
            {
                "file_name": "qt-opensource-linux-x64-android-5.5.0-2.run",
                "md5": "4f282f11e731324235a9c7cc88936cac",
                "platform": "linux",
                "size": 707422951,
                "size_string": "675M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.5/5.5.0/qt-opensource-linux-x64-android-5.5.0-2.run",
                "version": "5.5.0"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.5.0-2.run",
                "md5": "7e1a2f4141abf634bf80523b73108434",
                "platform": "linux",
                "size": 627892389,
                "size_string": "599M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.5/5.5.0/qt-opensource-linux-x64-5.5.0-2.run",
                "version": "5.5.0"
            },
            {
                "file_name": "qt-opensource-mac-x64-clang-5.5.0.dmg",
                "md5": "6eb16f1f77fffe0861412bc4b53b6019",
                "platform": "mac",
                "size": 685894010,
                "size_string": "654M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.5/5.5.0/qt-opensource-mac-x64-clang-5.5.0.dmg",
                "version": "5.5.0"
            },
            {
                "file_name": "qt-opensource-mac-x64-android-5.5.0.dmg",
                "md5": "785683630c11ecbd118814173ff20091",
                "platform": "mac",
                "size": 756766368,
                "size_string": "722M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.5/5.5.0/qt-opensource-mac-x64-android-5.5.0.dmg",
                "version": "5.5.0"
            },
            {
                "file_name": "qt-opensource-mac-x64-android-ios-5.5.0.dmg",
                "md5": "7d1884ff348daaa1d7ec674610528d36",
                "platform": "mac",
                "size": 2072144711,
                "size_string": "1.9G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.5/5.5.0/qt-opensource-mac-x64-android-ios-5.5.0.dmg",
                "version": "5.5.0"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.5.0"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-5.4.2.zip",
                "md5": "b6a099fff154fcb0a0127619623d412f",
                "platform": "unknow",
                "size": 524017548,
                "size_string": "500M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.2/single/qt-everywhere-opensource-src-5.4.2.zip",
                "version": "5.4.2"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.4.2.tar.xz",
                "md5": "c23bd0f14d66e7901d24906a1edce9b0",
                "platform": "unknow",
                "size": 281319000,
                "size_string": "268M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.2/single/qt-everywhere-opensource-src-5.4.2.tar.xz",
                "version": "5.4.2"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.4.2.tar.gz",
                "md5": "fa1c4d819b401b267eb246a543a63ea5",
                "platform": "unknow",
                "size": 416644849,
                "size_string": "397M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.2/single/qt-everywhere-opensource-src-5.4.2.tar.gz",
                "version": "5.4.2"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.4.2.7z",
                "md5": "de951bcf4d4da9af2fadd903a91b2cc4",
                "platform": "unknow",
                "size": 283196315,
                "size_string": "270M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.2/single/qt-everywhere-opensource-src-5.4.2.7z",
                "version": "5.4.2"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2012_opengl-5.4.2.exe",
                "md5": "7aebf974970569088bff0567cb171e0e",
                "platform": "window",
                "size": 661710064,
                "size_string": "631M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.2/qt-opensource-windows-x86-msvc2012_opengl-5.4.2.exe",
                "version": "5.4.2"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2010_opengl-5.4.2.exe",
                "md5": "b874ef98f3b7f777e99eb736d81a75f0",
                "platform": "window",
                "size": 643073744,
                "size_string": "613M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.2/qt-opensource-windows-x86-msvc2010_opengl-5.4.2.exe",
                "version": "5.4.2"
            },
            {
                "file_name": "qt-opensource-windows-x86-android-5.4.2.exe",
                "md5": "ba3ae80b885028f4de1842f5366a48b6",
                "platform": "window",
                "size": 969159592,
                "size_string": "924M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.2/qt-opensource-windows-x86-android-5.4.2.exe",
                "version": "5.4.2"
            },
            {
                "file_name": "qt-opensource-windows-x86-winrt-5.4.2.exe",
                "md5": "7a8c2a3d7d37b18622706792d5bacc02",
                "platform": "window",
                "size": 789548672,
                "size_string": "753M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.2/qt-opensource-windows-x86-winrt-5.4.2.exe",
                "version": "5.4.2"
            },
            {
                "file_name": "qt-opensource-windows-x86-mingw491_opengl-5.4.2.exe",
                "md5": "2b5b7fc56e9ba505f375a27d02067395",
                "platform": "window",
                "size": 882953880,
                "size_string": "842M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.2/qt-opensource-windows-x86-mingw491_opengl-5.4.2.exe",
                "version": "5.4.2"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013_opengl-5.4.2.exe",
                "md5": "62323e962ecccc35d3eb170b59077144",
                "platform": "window",
                "size": 715059424,
                "size_string": "682M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.2/qt-opensource-windows-x86-msvc2013_opengl-5.4.2.exe",
                "version": "5.4.2"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013_64_opengl-5.4.2.exe",
                "md5": "c8b92526f8b64a0fc70c129b10842e7c",
                "platform": "window",
                "size": 731273800,
                "size_string": "697M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.2/qt-opensource-windows-x86-msvc2013_64_opengl-5.4.2.exe",
                "version": "5.4.2"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013_64-5.4.2.exe",
                "md5": "0e2f5d270be01a860bf5d97a162b2113",
                "platform": "window",
                "size": 742945024,
                "size_string": "709M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.2/qt-opensource-windows-x86-msvc2013_64-5.4.2.exe",
                "version": "5.4.2"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013-5.4.2.exe",
                "md5": "c60949c3d9cdde742ca5119a2e623d61",
                "platform": "window",
                "size": 725314720,
                "size_string": "692M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.2/qt-opensource-windows-x86-msvc2013-5.4.2.exe",
                "version": "5.4.2"
            },
            {
                "file_name": "qt-opensource-linux-x86-android-5.4.2.run",
                "md5": "871fcbc1c55f861b05cc5749a809101c",
                "platform": "linux",
                "size": 637843754,
                "size_string": "608M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.2/qt-opensource-linux-x86-android-5.4.2.run",
                "version": "5.4.2"
            },
            {
                "file_name": "qt-opensource-linux-x64-android-5.4.2.run",
                "md5": "e22be41827b36b0dbccd6d76eb7ccb51",
                "platform": "linux",
                "size": 634256520,
                "size_string": "605M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.2/qt-opensource-linux-x64-android-5.4.2.run",
                "version": "5.4.2"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.4.2.run",
                "md5": "a9aede5e928bfc4e9c3f3b670a01aec7",
                "platform": "linux",
                "size": 557503040,
                "size_string": "532M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.2/qt-opensource-linux-x64-5.4.2.run",
                "version": "5.4.2"
            },
            {
                "file_name": "qt-opensource-linux-x86-5.4.2.run",
                "md5": "a82eee2379f02cae1c3cf985b13289c0",
                "platform": "linux",
                "size": 560918516,
                "size_string": "535M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.2/qt-opensource-linux-x86-5.4.2.run",
                "version": "5.4.2"
            },
            {
                "file_name": "qt-opensource-mac-x64-ios-5.4.2.dmg",
                "md5": "3a237cf1c84646d0be1a63dc379f0a27",
                "platform": "mac",
                "size": 1706962864,
                "size_string": "1.6G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.2/qt-opensource-mac-x64-ios-5.4.2.dmg",
                "version": "5.4.2"
            },
            {
                "file_name": "qt-opensource-mac-x64-clang-5.4.2.dmg",
                "md5": "0022711014e1bda7907687bcbc8d857b",
                "platform": "mac",
                "size": 616135300,
                "size_string": "588M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.2/qt-opensource-mac-x64-clang-5.4.2.dmg",
                "version": "5.4.2"
            },
            {
                "file_name": "qt-opensource-mac-x64-android-5.4.2.dmg",
                "md5": "572da03adf1b9625b33bde6a9608079a",
                "platform": "mac",
                "size": 683855830,
                "size_string": "652M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.2/qt-opensource-mac-x64-android-5.4.2.dmg",
                "version": "5.4.2"
            },
            {
                "file_name": "qt-opensource-mac-x64-android-ios-5.4.2.dmg",
                "md5": "639881614c1eab661c98eb59fdbcf54f",
                "platform": "mac",
                "size": 1774642574,
                "size_string": "1.7G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.2/qt-opensource-mac-x64-android-ios-5.4.2.dmg",
                "version": "5.4.2"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.4.2"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-5.4.1.zip",
                "md5": "57b25c68982237abb9e18b347034e005",
                "platform": "unknow",
                "size": 535137244,
                "size_string": "510M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.1/single/qt-everywhere-opensource-src-5.4.1.zip",
                "version": "5.4.1"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.4.1.tar.xz",
                "md5": "7afb5f9235d8d42b5b6e832442a32a5d",
                "platform": "unknow",
                "size": 291733972,
                "size_string": "278M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.1/single/qt-everywhere-opensource-src-5.4.1.tar.xz",
                "version": "5.4.1"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.4.1.tar.gz",
                "md5": "90f3fbce38ed16e5dc2cd0909ae86ca4",
                "platform": "unknow",
                "size": 427580076,
                "size_string": "408M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.1/single/qt-everywhere-opensource-src-5.4.1.tar.gz",
                "version": "5.4.1"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.4.1.7z",
                "md5": "c9f1dde5c28f1bd6215dffa4f01fb170",
                "platform": "unknow",
                "size": 293136934,
                "size_string": "280M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.1/single/qt-everywhere-opensource-src-5.4.1.7z",
                "version": "5.4.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2012_opengl-5.4.1.exe",
                "md5": "cecea735ef7f23df21ef87765db2890a",
                "platform": "window",
                "size": 675807000,
                "size_string": "644M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.1/qt-opensource-windows-x86-msvc2012_opengl-5.4.1.exe",
                "version": "5.4.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2010_opengl-5.4.1.exe",
                "md5": "b8834ddb40e9c45537824b6b9d4ce9a9",
                "platform": "window",
                "size": 657342216,
                "size_string": "627M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.1/qt-opensource-windows-x86-msvc2010_opengl-5.4.1.exe",
                "version": "5.4.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-mingw491_opengl-5.4.1.exe",
                "md5": "9e4cb9fc5f8bc07301f4f7bf5fa62b20",
                "platform": "window",
                "size": 897106696,
                "size_string": "856M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.1/qt-opensource-windows-x86-mingw491_opengl-5.4.1.exe",
                "version": "5.4.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-android-5.4.1.exe",
                "md5": "584ee63e7222da5461e981623faa1673",
                "platform": "window",
                "size": 984699024,
                "size_string": "939M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.1/qt-opensource-windows-x86-android-5.4.1.exe",
                "version": "5.4.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-winrt-5.4.1.exe",
                "md5": "681040871fbbc713a32537818450959f",
                "platform": "window",
                "size": 803264568,
                "size_string": "766M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.1/qt-opensource-windows-x86-winrt-5.4.1.exe",
                "version": "5.4.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013_opengl-5.4.1.exe",
                "md5": "0b979c5c47225da99accc326de7a502b",
                "platform": "window",
                "size": 729061736,
                "size_string": "695M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.1/qt-opensource-windows-x86-msvc2013_opengl-5.4.1.exe",
                "version": "5.4.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013_64_opengl-5.4.1.exe",
                "md5": "db5f2dad9db5c7148a6e15b12d5aee22",
                "platform": "window",
                "size": 745191248,
                "size_string": "711M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.1/qt-opensource-windows-x86-msvc2013_64_opengl-5.4.1.exe",
                "version": "5.4.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013-5.4.1.exe",
                "md5": "f7fba92e9befd067dfef98aecb2f58ae",
                "platform": "window",
                "size": 739450200,
                "size_string": "705M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.1/qt-opensource-windows-x86-msvc2013-5.4.1.exe",
                "version": "5.4.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013_64-5.4.1.exe",
                "md5": "b2b3392184f6526762b33dbecf91b611",
                "platform": "window",
                "size": 757329232,
                "size_string": "722M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.1/qt-opensource-windows-x86-msvc2013_64-5.4.1.exe",
                "version": "5.4.1"
            },
            {
                "file_name": "qt-opensource-linux-x86-android-5.4.1.run",
                "md5": "62056ac8959e94fbbd2ae39fcb32d47a",
                "platform": "linux",
                "size": 648987877,
                "size_string": "619M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.1/qt-opensource-linux-x86-android-5.4.1.run",
                "version": "5.4.1"
            },
            {
                "file_name": "qt-opensource-linux-x86-5.4.1.run",
                "md5": "b3e289246a4083ed7e122b26cadc60e2",
                "platform": "linux",
                "size": 572293599,
                "size_string": "546M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.1/qt-opensource-linux-x86-5.4.1.run",
                "version": "5.4.1"
            },
            {
                "file_name": "qt-opensource-linux-x64-android-5.4.1.run",
                "md5": "30f9dcd6538f9df91bf94787777e5a6e",
                "platform": "linux",
                "size": 645450181,
                "size_string": "616M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.1/qt-opensource-linux-x64-android-5.4.1.run",
                "version": "5.4.1"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.4.1.run",
                "md5": "e4c6cde752effac1668a99bdfd2c7f6c",
                "platform": "linux",
                "size": 568905085,
                "size_string": "543M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.1/qt-opensource-linux-x64-5.4.1.run",
                "version": "5.4.1"
            },
            {
                "file_name": "qt-opensource-mac-x64-clang-5.4.1.dmg",
                "md5": "fa349b9b0bc2eec94620553970648c7d",
                "platform": "mac",
                "size": 626848614,
                "size_string": "598M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.1/qt-opensource-mac-x64-clang-5.4.1.dmg",
                "version": "5.4.1"
            },
            {
                "file_name": "qt-opensource-mac-x64-ios-5.4.1.dmg",
                "md5": "918cf2ebe4eb99005073160070193191",
                "platform": "mac",
                "size": 1716807845,
                "size_string": "1.6G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.1/qt-opensource-mac-x64-ios-5.4.1.dmg",
                "version": "5.4.1"
            },
            {
                "file_name": "qt-opensource-mac-x64-android-ios-5.4.1.dmg",
                "md5": "4815bdbd0ae6ee2d77201b8476149ce5",
                "platform": "mac",
                "size": 1784303816,
                "size_string": "1.7G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.1/qt-opensource-mac-x64-android-ios-5.4.1.dmg",
                "version": "5.4.1"
            },
            {
                "file_name": "qt-opensource-mac-x64-android-5.4.1.dmg",
                "md5": "82d8424783da35cc2f7da9fb4ebfedac",
                "platform": "mac",
                "size": 694356417,
                "size_string": "662M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.1/qt-opensource-mac-x64-android-5.4.1.dmg",
                "version": "5.4.1"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.4.1"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-5.4.0.zip",
                "md5": "d68424497836dadb194b9fb038850d15",
                "platform": "unknow",
                "size": 535049703,
                "size_string": "510M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.0/single/qt-everywhere-opensource-src-5.4.0.zip",
                "version": "5.4.0"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.4.0.tar.xz",
                "md5": "21843b00ecde8956217faab391bc007b",
                "platform": "unknow",
                "size": 291681576,
                "size_string": "278M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.0/single/qt-everywhere-opensource-src-5.4.0.tar.xz",
                "version": "5.4.0"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.4.0.tar.gz",
                "md5": "e8654e4b37dd98039ba20da7a53877e6",
                "platform": "unknow",
                "size": 427519895,
                "size_string": "408M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.0/single/qt-everywhere-opensource-src-5.4.0.tar.gz",
                "version": "5.4.0"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.4.0.7z",
                "md5": "b6cc2ae28f1512706258745e2f3a4f41",
                "platform": "unknow",
                "size": 293085066,
                "size_string": "280M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.0/single/qt-everywhere-opensource-src-5.4.0.7z",
                "version": "5.4.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013_opengl-5.4.0.exe",
                "md5": "eaa816a57f9faf6f29bb79aebcc6669a",
                "platform": "window",
                "size": 727678864,
                "size_string": "694M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.0/qt-opensource-windows-x86-msvc2013_opengl-5.4.0.exe",
                "version": "5.4.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013_64_opengl-5.4.0.exe",
                "md5": "23ebae5623eb2a197d5718722bc997a6",
                "platform": "window",
                "size": 743678512,
                "size_string": "709M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.0/qt-opensource-windows-x86-msvc2013_64_opengl-5.4.0.exe",
                "version": "5.4.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013_64-5.4.0.exe",
                "md5": "fffa6d36c079ef4352165ea327be254d",
                "platform": "window",
                "size": 755454360,
                "size_string": "720M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.0/qt-opensource-windows-x86-msvc2013_64-5.4.0.exe",
                "version": "5.4.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013-5.4.0.exe",
                "md5": "f987f93d86390fe41a5d52f4f25f0169",
                "platform": "window",
                "size": 737999848,
                "size_string": "704M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.0/qt-opensource-windows-x86-msvc2013-5.4.0.exe",
                "version": "5.4.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2012_opengl-5.4.0.exe",
                "md5": "2273901457f859199f807c9055b7be66",
                "platform": "window",
                "size": 674214592,
                "size_string": "643M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.0/qt-opensource-windows-x86-msvc2012_opengl-5.4.0.exe",
                "version": "5.4.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2010_opengl-5.4.0.exe",
                "md5": "2f5d396a7167ba15cbacd06dcef326f3",
                "platform": "window",
                "size": 655630608,
                "size_string": "625M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.0/qt-opensource-windows-x86-msvc2010_opengl-5.4.0.exe",
                "version": "5.4.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-mingw491_opengl-5.4.0.exe",
                "md5": "4c0b23fa36e8b7c06a137f2310321924",
                "platform": "window",
                "size": 893769560,
                "size_string": "852M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.0/qt-opensource-windows-x86-mingw491_opengl-5.4.0.exe",
                "version": "5.4.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-android-5.4.0.exe",
                "md5": "0dcd42df09505be418393dc28286e850",
                "platform": "window",
                "size": 979896472,
                "size_string": "935M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.0/qt-opensource-windows-x86-android-5.4.0.exe",
                "version": "5.4.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-winrt-5.4.0.exe",
                "md5": "98c52193321534f9289a788d84e3e5ff",
                "platform": "window",
                "size": 801435136,
                "size_string": "764M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.0/qt-opensource-windows-x86-winrt-5.4.0.exe",
                "version": "5.4.0"
            },
            {
                "file_name": "qt-opensource-linux-x86-android-5.4.0.run",
                "md5": "270885de7412a3c47dde31d07901f166",
                "platform": "linux",
                "size": 647330989,
                "size_string": "617M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.0/qt-opensource-linux-x86-android-5.4.0.run",
                "version": "5.4.0"
            },
            {
                "file_name": "qt-opensource-linux-x86-5.4.0.run",
                "md5": "8144b6c89e1216f7e7f863a91e29bf33",
                "platform": "linux",
                "size": 572023276,
                "size_string": "546M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.0/qt-opensource-linux-x86-5.4.0.run",
                "version": "5.4.0"
            },
            {
                "file_name": "qt-opensource-linux-x64-android-5.4.0.run",
                "md5": "b9424d8eb247a43947bd2c520cef3c53",
                "platform": "linux",
                "size": 643789251,
                "size_string": "614M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.0/qt-opensource-linux-x64-android-5.4.0.run",
                "version": "5.4.0"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.4.0.run",
                "md5": "7f07677944af5f7eff561991a4108b49",
                "platform": "linux",
                "size": 568622897,
                "size_string": "542M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.0/qt-opensource-linux-x64-5.4.0.run",
                "version": "5.4.0"
            },
            {
                "file_name": "qt-opensource-mac-x64-ios-5.4.0.dmg",
                "md5": "39d86c3216113dffa80488b55d400d38",
                "platform": "mac",
                "size": 1707226836,
                "size_string": "1.6G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.0/qt-opensource-mac-x64-ios-5.4.0.dmg",
                "version": "5.4.0"
            },
            {
                "file_name": "qt-opensource-mac-x64-clang-5.4.0.dmg",
                "md5": "6282e8ac0002d7525b2d1e3a7e74bebe",
                "platform": "mac",
                "size": 627677035,
                "size_string": "599M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.0/qt-opensource-mac-x64-clang-5.4.0.dmg",
                "version": "5.4.0"
            },
            {
                "file_name": "qt-opensource-mac-x64-android-ios-5.4.0.dmg",
                "md5": "98c00be06a8a30e8de5f48057ef1d5b9",
                "platform": "mac",
                "size": 1773325251,
                "size_string": "1.7G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.0/qt-opensource-mac-x64-android-ios-5.4.0.dmg",
                "version": "5.4.0"
            },
            {
                "file_name": "qt-opensource-mac-x64-android-5.4.0.dmg",
                "md5": "7e33d213d780434a9dc44f0d82c19e70",
                "platform": "mac",
                "size": 693776664,
                "size_string": "662M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.4/5.4.0/qt-opensource-mac-x64-android-5.4.0.dmg",
                "version": "5.4.0"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.4.0"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-5.3.2.zip",
                "md5": "ce4319e4f32aeeb52837c2df41c51f32",
                "platform": "unknow",
                "size": 296265371,
                "size_string": "283M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.2/single/qt-everywhere-opensource-src-5.3.2.zip",
                "version": "5.3.2"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.3.2.tar.xz",
                "md5": "c4e893678e3d8388ab04d059523d1d78",
                "platform": "unknow",
                "size": 177866784,
                "size_string": "170M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.2/single/qt-everywhere-opensource-src-5.3.2.tar.xz",
                "version": "5.3.2"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.3.2.tar.gz",
                "md5": "febb001129927a70174467ecb508a682",
                "platform": "unknow",
                "size": 238906177,
                "size_string": "228M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.2/single/qt-everywhere-opensource-src-5.3.2.tar.gz",
                "version": "5.3.2"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.3.2.7z",
                "md5": "c77ce3f12612a77dbac3a4dc36369cfd",
                "platform": "unknow",
                "size": 174222674,
                "size_string": "166M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.2/single/qt-everywhere-opensource-src-5.3.2.7z",
                "version": "5.3.2"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2010_opengl-5.3.2.exe",
                "md5": "b5a0faa0fdaf5b23482254431e036235",
                "platform": "window",
                "size": 565458464,
                "size_string": "539M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.2/qt-opensource-windows-x86-msvc2010_opengl-5.3.2.exe",
                "version": "5.3.2"
            },
            {
                "file_name": "qt-opensource-windows-x86-mingw482_opengl-5.3.2.exe",
                "md5": "1ee1aa4eb5db4a18917eaed965a0bc67",
                "platform": "window",
                "size": 773217192,
                "size_string": "737M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.2/qt-opensource-windows-x86-mingw482_opengl-5.3.2.exe",
                "version": "5.3.2"
            },
            {
                "file_name": "qt-opensource-windows-x86-android-5.3.2.exe",
                "md5": "1c94268b57a365fd175674543a1c9225",
                "platform": "window",
                "size": 856336296,
                "size_string": "817M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.2/qt-opensource-windows-x86-android-5.3.2.exe",
                "version": "5.3.2"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013_64_opengl-5.3.2.exe",
                "md5": "55797a6900042e86b00f5a619fc1f9c8",
                "platform": "window",
                "size": 600788576,
                "size_string": "573M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.2/qt-opensource-windows-x86-msvc2013_64_opengl-5.3.2.exe",
                "version": "5.3.2"
            },
            {
                "file_name": "qt-opensource-windows-x86-winrt-5.3.2.exe",
                "md5": "7aa3fb3e31f5ed25254b30b7b1dc8561",
                "platform": "window",
                "size": 680446000,
                "size_string": "649M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.2/qt-opensource-windows-x86-winrt-5.3.2.exe",
                "version": "5.3.2"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013_opengl-5.3.2.exe",
                "md5": "e41f52e77fe4f95fcfd3d2693f9e2cb1",
                "platform": "window",
                "size": 586358176,
                "size_string": "559M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.2/qt-opensource-windows-x86-msvc2013_opengl-5.3.2.exe",
                "version": "5.3.2"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013_64-5.3.2.exe",
                "md5": "6cd05d428e1dea3fb6fee20f87143d17",
                "platform": "window",
                "size": 603152344,
                "size_string": "575M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.2/qt-opensource-windows-x86-msvc2013_64-5.3.2.exe",
                "version": "5.3.2"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013-5.3.2.exe",
                "md5": "9abc9cbe36fdaefb909ecda3598ee2b5",
                "platform": "window",
                "size": 588596376,
                "size_string": "561M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.2/qt-opensource-windows-x86-msvc2013-5.3.2.exe",
                "version": "5.3.2"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2012_opengl-5.3.2.exe",
                "md5": "2cb20fc1212fac907cee9357a5cd1524",
                "platform": "window",
                "size": 582079856,
                "size_string": "555M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.2/qt-opensource-windows-x86-msvc2012_opengl-5.3.2.exe",
                "version": "5.3.2"
            },
            {
                "file_name": "qt-opensource-linux-x86-android-5.3.2.run",
                "md5": "b1efe6c69cf1458927dcc722fe28e033",
                "platform": "linux",
                "size": 543474117,
                "size_string": "518M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.2/qt-opensource-linux-x86-android-5.3.2.run",
                "version": "5.3.2"
            },
            {
                "file_name": "qt-opensource-linux-x86-5.3.2.run",
                "md5": "cf5846b8ba99a4db3c0e19bdbc525350",
                "platform": "linux",
                "size": 471115221,
                "size_string": "449M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.2/qt-opensource-linux-x86-5.3.2.run",
                "version": "5.3.2"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.3.2.run",
                "md5": "496d3fe4d2ab3d8bf15681ddce62f95d",
                "platform": "linux",
                "size": 468521191,
                "size_string": "447M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.2/qt-opensource-linux-x64-5.3.2.run",
                "version": "5.3.2"
            },
            {
                "file_name": "qt-opensource-linux-x64-android-5.3.2.run",
                "md5": "2a21e21535760d7c45de720efb139938",
                "platform": "linux",
                "size": 540953988,
                "size_string": "516M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.2/qt-opensource-linux-x64-android-5.3.2.run",
                "version": "5.3.2"
            },
            {
                "file_name": "qt-opensource-mac-x64-ios-5.3.2.dmg",
                "md5": "343a7e7a8cab070d4a958c6cf3d7bddc",
                "platform": "mac",
                "size": 957687055,
                "size_string": "913M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.2/qt-opensource-mac-x64-ios-5.3.2.dmg",
                "version": "5.3.2"
            },
            {
                "file_name": "qt-opensource-mac-x64-clang-5.3.2.dmg",
                "md5": "9899bff2c8ff61e3ff25277153499fe3",
                "platform": "mac",
                "size": 478130593,
                "size_string": "456M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.2/qt-opensource-mac-x64-clang-5.3.2.dmg",
                "version": "5.3.2"
            },
            {
                "file_name": "qt-opensource-mac-x64-android-ios-5.3.2.dmg",
                "md5": "7918ee40ae75e8ebd41f981cc3a5b958",
                "platform": "mac",
                "size": 1021207301,
                "size_string": "1.0G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.2/qt-opensource-mac-x64-android-ios-5.3.2.dmg",
                "version": "5.3.2"
            },
            {
                "file_name": "qt-opensource-mac-x64-android-5.3.2.dmg",
                "md5": "5eab93296fe95af9e208cfb2d1f4fb67",
                "platform": "mac",
                "size": 541656242,
                "size_string": "517M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.2/qt-opensource-mac-x64-android-5.3.2.dmg",
                "version": "5.3.2"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.3.2"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-5.3.1.7z",
                "md5": "9d048faed8a912f0c08be70cd6444913",
                "platform": "unknow",
                "size": 173575207,
                "size_string": "166M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.1/single/qt-everywhere-opensource-src-5.3.1.7z",
                "version": "5.3.1"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.3.1.tar.gz",
                "md5": "a355749e4f200a6392ecad151d1d0cdc",
                "platform": "unknow",
                "size": 238157422,
                "size_string": "227M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.1/single/qt-everywhere-opensource-src-5.3.1.tar.gz",
                "version": "5.3.1"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.3.1.zip",
                "md5": "3d70c491d9c16f86d342b5f9b6315f27",
                "platform": "unknow",
                "size": 295451424,
                "size_string": "282M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.1/single/qt-everywhere-opensource-src-5.3.1.zip",
                "version": "5.3.1"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.3.1.tar.xz",
                "md5": "f9a24a0d5645efa0715b6ff0fa13d60f",
                "platform": "unknow",
                "size": 177181444,
                "size_string": "169M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.1/single/qt-everywhere-opensource-src-5.3.1.tar.xz",
                "version": "5.3.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013_opengl-5.3.1.exe",
                "md5": "53fb6568bc91c26cd1a1ca2fed73c638",
                "platform": "window",
                "size": 584107480,
                "size_string": "557M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.1/qt-opensource-windows-x86-msvc2013_opengl-5.3.1.exe",
                "version": "5.3.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013_64_opengl-5.3.1.exe",
                "md5": "e674fe6a40a01fc552b22e065daf384e",
                "platform": "window",
                "size": 598552016,
                "size_string": "571M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.1/qt-opensource-windows-x86-msvc2013_64_opengl-5.3.1.exe",
                "version": "5.3.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013_64-5.3.1.exe",
                "md5": "de3e3ef466688646297512da8c07686e",
                "platform": "window",
                "size": 601013072,
                "size_string": "573M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.1/qt-opensource-windows-x86-msvc2013_64-5.3.1.exe",
                "version": "5.3.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013-5.3.1.exe",
                "md5": "3d6ebe8669a8a84a2162ddd2a7b3ad20",
                "platform": "window",
                "size": 586448144,
                "size_string": "559M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.1/qt-opensource-windows-x86-msvc2013-5.3.1.exe",
                "version": "5.3.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2012_opengl-5.3.1.exe",
                "md5": "30a52b2f86b6efb017c261cdb4028eac",
                "platform": "window",
                "size": 579649168,
                "size_string": "553M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.1/qt-opensource-windows-x86-msvc2012_opengl-5.3.1.exe",
                "version": "5.3.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2010_opengl-5.3.1.exe",
                "md5": "d5034abbca2d2d05af2c8af5b3f3aa60",
                "platform": "window",
                "size": 563242968,
                "size_string": "537M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.1/qt-opensource-windows-x86-msvc2010_opengl-5.3.1.exe",
                "version": "5.3.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-mingw482_opengl-5.3.1.exe",
                "md5": "26d91b0266fd4fc7efa78c9bb3743d2d",
                "platform": "window",
                "size": 770711536,
                "size_string": "735M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.1/qt-opensource-windows-x86-mingw482_opengl-5.3.1.exe",
                "version": "5.3.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-android-5.3.1.exe",
                "md5": "aec4a1ce0c179054b3dda24d2f2512d3",
                "platform": "window",
                "size": 853780160,
                "size_string": "814M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.1/qt-opensource-windows-x86-android-5.3.1.exe",
                "version": "5.3.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-winrt-5.3.1.exe",
                "md5": "bd3ccbea1c6948bb43bc25f0d2a7af43",
                "platform": "window",
                "size": 678076304,
                "size_string": "647M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.1/qt-opensource-windows-x86-winrt-5.3.1.exe",
                "version": "5.3.1"
            },
            {
                "file_name": "qt-opensource-linux-x86-android-5.3.1.run",
                "md5": "1b9ddf641ef946ac38cd5c484a4bb5b2",
                "platform": "linux",
                "size": 540596577,
                "size_string": "516M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.1/qt-opensource-linux-x86-android-5.3.1.run",
                "version": "5.3.1"
            },
            {
                "file_name": "qt-opensource-linux-x86-5.3.1.run",
                "md5": "b41ee8924f37f95f029a4359668f894c",
                "platform": "linux",
                "size": 468314384,
                "size_string": "447M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.1/qt-opensource-linux-x86-5.3.1.run",
                "version": "5.3.1"
            },
            {
                "file_name": "qt-opensource-linux-x64-android-5.3.1.run",
                "md5": "23ea2b548eaa22ed7d49ad35b2728d00",
                "platform": "linux",
                "size": 538076550,
                "size_string": "513M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.1/qt-opensource-linux-x64-android-5.3.1.run",
                "version": "5.3.1"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.3.1.run",
                "md5": "8afdd8e7ffd0b55846784a24827e3619",
                "platform": "linux",
                "size": 465712293,
                "size_string": "444M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.1/qt-opensource-linux-x64-5.3.1.run",
                "version": "5.3.1"
            },
            {
                "file_name": "qt-opensource-mac-x64-ios-5.3.1.dmg",
                "md5": "df7aebc8cb9231207ca6f6bf0c143fad",
                "platform": "mac",
                "size": 953811581,
                "size_string": "910M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.1/qt-opensource-mac-x64-ios-5.3.1.dmg",
                "version": "5.3.1"
            },
            {
                "file_name": "qt-opensource-mac-x64-clang-5.3.1.dmg",
                "md5": "ef2825a3819aee506f87264ecf9b4d42",
                "platform": "mac",
                "size": 474553049,
                "size_string": "453M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.1/qt-opensource-mac-x64-clang-5.3.1.dmg",
                "version": "5.3.1"
            },
            {
                "file_name": "qt-opensource-mac-x64-android-ios-5.3.1.dmg",
                "md5": "0a3ac41bd9abefc51095dcea3f844634",
                "platform": "mac",
                "size": 1017293895,
                "size_string": "970M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.1/qt-opensource-mac-x64-android-ios-5.3.1.dmg",
                "version": "5.3.1"
            },
            {
                "file_name": "qt-opensource-mac-x64-android-5.3.1.dmg",
                "md5": "c3a7dd43705c49836a51526d92e06acd",
                "platform": "mac",
                "size": 538027553,
                "size_string": "513M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.1/qt-opensource-mac-x64-android-5.3.1.dmg",
                "version": "5.3.1"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.3.1"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-5.3.0.zip",
                "md5": "6b8daa9e05d1818c18bb73429dbfac1b",
                "platform": "unknow",
                "size": 295154451,
                "size_string": "281M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.0/single/qt-everywhere-opensource-src-5.3.0.zip",
                "version": "5.3.0"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.3.0.tar.xz",
                "md5": "f00ac7930e3b1b2b364dedcd15acc142",
                "platform": "unknow",
                "size": 177007088,
                "size_string": "169M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.0/single/qt-everywhere-opensource-src-5.3.0.tar.xz",
                "version": "5.3.0"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.3.0.7z",
                "md5": "1835fe1875a9743eb746f17c92c234af",
                "platform": "unknow",
                "size": 173420832,
                "size_string": "165M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.0/single/qt-everywhere-opensource-src-5.3.0.7z",
                "version": "5.3.0"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.3.0.tar.gz",
                "md5": "5e6fc156e9542a2ad2abcb54a8b48069",
                "platform": "unknow",
                "size": 237916707,
                "size_string": "227M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.0/single/qt-everywhere-opensource-src-5.3.0.tar.gz",
                "version": "5.3.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013_opengl-5.3.0.exe",
                "md5": "c78a029392944169bee039dd04e64c8d",
                "platform": "window",
                "size": 652113920,
                "size_string": "622M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.0/qt-opensource-windows-x86-msvc2013_opengl-5.3.0.exe",
                "version": "5.3.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013_64_opengl-5.3.0.exe",
                "md5": "6f21146c3609381d80a7b54480c6fccc",
                "platform": "window",
                "size": 666608032,
                "size_string": "636M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.0/qt-opensource-windows-x86-msvc2013_64_opengl-5.3.0.exe",
                "version": "5.3.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013_64-5.3.0.exe",
                "md5": "3812e806a623b1ff233c23eb4d363771",
                "platform": "window",
                "size": 673671568,
                "size_string": "642M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.0/qt-opensource-windows-x86-msvc2013_64-5.3.0.exe",
                "version": "5.3.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2013-5.3.0.exe",
                "md5": "cf9893ea6347c075437dd246289c486d",
                "platform": "window",
                "size": 656280472,
                "size_string": "626M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.0/qt-opensource-windows-x86-msvc2013-5.3.0.exe",
                "version": "5.3.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2012_opengl-5.3.0.exe",
                "md5": "d6c070d9319238bcd04d6db5ade95523",
                "platform": "window",
                "size": 646128776,
                "size_string": "616M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.0/qt-opensource-windows-x86-msvc2012_opengl-5.3.0.exe",
                "version": "5.3.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2010_opengl-5.3.0.exe",
                "md5": "5182ab3ace5b37dc41f1d37137ea9067",
                "platform": "window",
                "size": 622042400,
                "size_string": "593M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.0/qt-opensource-windows-x86-msvc2010_opengl-5.3.0.exe",
                "version": "5.3.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-mingw482_opengl-5.3.0.exe",
                "md5": "05e038f73ff4709279fa10c6c58865a8",
                "platform": "window",
                "size": 769864576,
                "size_string": "734M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.0/qt-opensource-windows-x86-mingw482_opengl-5.3.0.exe",
                "version": "5.3.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-android-5.3.0.exe",
                "md5": "6b13ccfe6e66bcdf93e79bd0412b1fd9",
                "platform": "window",
                "size": 852755392,
                "size_string": "813M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.0/qt-opensource-windows-x86-android-5.3.0.exe",
                "version": "5.3.0"
            },
            {
                "file_name": "qt-opensource-windows-x86-winrt-5.3.0.exe",
                "md5": "cc07f70c5e123de8a9608288c5f9bdb8",
                "platform": "window",
                "size": 766573488,
                "size_string": "731M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.0/qt-opensource-windows-x86-winrt-5.3.0.exe",
                "version": "5.3.0"
            },
            {
                "file_name": "qt-opensource-linux-x86-android-5.3.0.run",
                "md5": "7e9052cec41d693393ab5214e7fa2df1",
                "platform": "linux",
                "size": 540272853,
                "size_string": "515M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.0/qt-opensource-linux-x86-android-5.3.0.run",
                "version": "5.3.0"
            },
            {
                "file_name": "qt-opensource-linux-x86-5.3.0.run",
                "md5": "13b1511ec6e1ce01117fcbb85a8bfc6f",
                "platform": "linux",
                "size": 466292970,
                "size_string": "445M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.0/qt-opensource-linux-x86-5.3.0.run",
                "version": "5.3.0"
            },
            {
                "file_name": "qt-opensource-linux-x64-android-5.3.0.run",
                "md5": "cbf557c18137420c21008d9c04e4bc95",
                "platform": "linux",
                "size": 537495209,
                "size_string": "513M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.0/qt-opensource-linux-x64-android-5.3.0.run",
                "version": "5.3.0"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.3.0.run",
                "md5": "cada928bc3e8e73e0d543d3256103131",
                "platform": "linux",
                "size": 463769893,
                "size_string": "442M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.0/qt-opensource-linux-x64-5.3.0.run",
                "version": "5.3.0"
            },
            {
                "file_name": "qt-opensource-mac-x64-ios-5.3.0.dmg",
                "md5": "3ef8d513fb48e86e802d057ac66e26c3",
                "platform": "mac",
                "size": 953928097,
                "size_string": "910M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.0/qt-opensource-mac-x64-ios-5.3.0.dmg",
                "version": "5.3.0"
            },
            {
                "file_name": "qt-opensource-mac-x64-clang-5.3.0.dmg",
                "md5": "4a0327e78dd8ee46dd6ee62859b8892a",
                "platform": "mac",
                "size": 474472489,
                "size_string": "452M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.0/qt-opensource-mac-x64-clang-5.3.0.dmg",
                "version": "5.3.0"
            },
            {
                "file_name": "qt-opensource-mac-x64-android-ios-5.3.0.dmg",
                "md5": "f53422284c330e7004f597c47f2e6b38",
                "platform": "mac",
                "size": 1019337896,
                "size_string": "972M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.0/qt-opensource-mac-x64-android-ios-5.3.0.dmg",
                "version": "5.3.0"
            },
            {
                "file_name": "qt-opensource-mac-x64-android-5.3.0.dmg",
                "md5": "d7b5c93941464e9e4ec8389b8649edbd",
                "platform": "mac",
                "size": 539883662,
                "size_string": "515M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.3/5.3.0/qt-opensource-mac-x64-android-5.3.0.dmg",
                "version": "5.3.0"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.3.0"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-5.2.1.zip",
                "md5": "7fea4e018220739251f9206d690076d0",
                "platform": "unknow",
                "size": 289979298,
                "size_string": "277M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.1/single/qt-everywhere-opensource-src-5.2.1.zip",
                "version": "5.2.1"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.2.1.tar.xz",
                "md5": "0c8d2aa45f38be9c3f7c9325eb059d9d",
                "platform": "unknow",
                "size": 174004404,
                "size_string": "166M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.1/single/qt-everywhere-opensource-src-5.2.1.tar.xz",
                "version": "5.2.1"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.2.1.tar.gz",
                "md5": "a78408c887c04c34ce615da690e0b4c8",
                "platform": "unknow",
                "size": 234053045,
                "size_string": "223M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.1/single/qt-everywhere-opensource-src-5.2.1.tar.gz",
                "version": "5.2.1"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.2.1.7z",
                "md5": "db39626947b081f47737a9a147585e1a",
                "platform": "unknow",
                "size": 169916093,
                "size_string": "162M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.1/single/qt-everywhere-opensource-src-5.2.1.7z",
                "version": "5.2.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-mingw48_opengl-5.2.1.exe",
                "md5": "1107faa3039ec24b07a4aae77d67084e",
                "platform": "window",
                "size": 664560840,
                "size_string": "634M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.1/qt-opensource-windows-x86-mingw48_opengl-5.2.1.exe",
                "version": "5.2.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-android-5.2.1.exe",
                "md5": "b7470228b2c4cd3b007c3c30551ba897",
                "platform": "window",
                "size": 1170864856,
                "size_string": "1.1G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.1/qt-opensource-windows-x86-android-5.2.1.exe",
                "version": "5.2.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2012_64-5.2.1.exe",
                "md5": "fed36411ecdc2897fdff78b8b70a8b81",
                "platform": "window",
                "size": 582946576,
                "size_string": "556M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.1/qt-opensource-windows-x86-msvc2012_64-5.2.1.exe",
                "version": "5.2.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2012_64_opengl-5.2.1.exe",
                "md5": "f6fff964717f7ccdc37ef2ca2ddf43db",
                "platform": "window",
                "size": 579789088,
                "size_string": "553M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.1/qt-opensource-windows-x86-msvc2012_64_opengl-5.2.1.exe",
                "version": "5.2.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2012-5.2.1.exe",
                "md5": "eb49e1dcda617725b2cf6ab3c949b7a3",
                "platform": "window",
                "size": 566938256,
                "size_string": "541M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.1/qt-opensource-windows-x86-msvc2012-5.2.1.exe",
                "version": "5.2.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2010_opengl-5.2.1.exe",
                "md5": "c172f504399158b0dde061382355025b",
                "platform": "window",
                "size": 542201104,
                "size_string": "517M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.1/qt-opensource-windows-x86-msvc2010_opengl-5.2.1.exe",
                "version": "5.2.1"
            },
            {
                "file_name": "qt-opensource-windows-x86-msvc2010-5.2.1.exe",
                "md5": "24f7373e88d2cb0fe410cf64a9dc5c0c",
                "platform": "window",
                "size": 543127568,
                "size_string": "518M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.1/qt-opensource-windows-x86-msvc2010-5.2.1.exe",
                "version": "5.2.1"
            },
            {
                "file_name": "qt-opensource-linux-x86-5.2.1.run",
                "md5": "19f6767c744d31f4489791cf27f5b655",
                "platform": "linux",
                "size": 388513068,
                "size_string": "371M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.1/qt-opensource-linux-x86-5.2.1.run",
                "version": "5.2.1"
            },
            {
                "file_name": "qt-opensource-linux-x64-android-5.2.1.run",
                "md5": "66599e6ffebcc57ee59bd277b0aba346",
                "platform": "linux",
                "size": 887211082,
                "size_string": "846M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.1/qt-opensource-linux-x64-android-5.2.1.run",
                "version": "5.2.1"
            },
            {
                "file_name": "qt-opensource-linux-x64-5.2.1.run",
                "md5": "323522e9d1e2bfc6759fede46c9a14ef",
                "platform": "linux",
                "size": 386288615,
                "size_string": "368M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.1/qt-opensource-linux-x64-5.2.1.run",
                "version": "5.2.1"
            },
            {
                "file_name": "qt-opensource-linux-x86-android-5.2.1.run",
                "md5": "9d66f8b0b01bd3f33c4251d8ce7286fa",
                "platform": "linux",
                "size": 889513913,
                "size_string": "848M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.1/qt-opensource-linux-x86-android-5.2.1.run",
                "version": "5.2.1"
            },
            {
                "file_name": "qt-opensource-mac-x64-clang-5.2.1.dmg",
                "md5": "be0e1bebe722a567a72e6eeda6c03325",
                "platform": "mac",
                "size": 396531151,
                "size_string": "378M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.1/qt-opensource-mac-x64-clang-5.2.1.dmg",
                "version": "5.2.1"
            },
            {
                "file_name": "qt-opensource-mac-x64-ios-5.2.1.dmg",
                "md5": "ce6e037b14d9f25dd0f21fa92e73eaa3",
                "platform": "mac",
                "size": 952650788,
                "size_string": "909M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.1/qt-opensource-mac-x64-ios-5.2.1.dmg",
                "version": "5.2.1"
            },
            {
                "file_name": "qt-opensource-mac-x64-android-ios-5.2.1.dmg",
                "md5": "e61c1aa77994df1983beaf5abc8d96a9",
                "platform": "mac",
                "size": 1448453658,
                "size_string": "1.3G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.1/qt-opensource-mac-x64-android-ios-5.2.1.dmg",
                "version": "5.2.1"
            },
            {
                "file_name": "qt-opensource-mac-x64-android-5.2.1.dmg",
                "md5": "e61afff0711b6b13e326513037179d3d",
                "platform": "mac",
                "size": 892330685,
                "size_string": "851M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.1/qt-opensource-mac-x64-android-5.2.1.dmg",
                "version": "5.2.1"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.2.1"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-5.2.0.zip",
                "md5": "7f0b746bdd45ee90110581d2a4417070",
                "platform": "unknow",
                "size": 289863083,
                "size_string": "276M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.0/single/qt-everywhere-opensource-src-5.2.0.zip",
                "version": "5.2.0"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.2.0.tar.xz",
                "md5": "8f60b47ca9461831d940f579ee90517e",
                "platform": "unknow",
                "size": 173962448,
                "size_string": "166M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.0/single/qt-everywhere-opensource-src-5.2.0.tar.xz",
                "version": "5.2.0"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.2.0.tar.gz",
                "md5": "228b6384dfd7272de00fd8b2c144fecd",
                "platform": "unknow",
                "size": 233965960,
                "size_string": "223M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.0/single/qt-everywhere-opensource-src-5.2.0.tar.gz",
                "version": "5.2.0"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.2.0.7z",
                "md5": "8d043ac97dbbb7d72bddb5c257cfe685",
                "platform": "unknow",
                "size": 169845503,
                "size_string": "162M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.0/single/qt-everywhere-opensource-src-5.2.0.7z",
                "version": "5.2.0"
            },
            {
                "file_name": "qt-windows-opensource-5.2.0-mingw48_opengl-x86-offline.exe",
                "md5": "d9b514754f8856a2efcc0426e3e66e52",
                "platform": "window",
                "size": 722276200,
                "size_string": "689M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.0/qt-windows-opensource-5.2.0-mingw48_opengl-x86-offline.exe",
                "version": "5.2.0"
            },
            {
                "file_name": "qt-windows-opensource-5.2.0-android-x86-win32-offline.exe",
                "md5": "d571b060d74077522c113aca1449a001",
                "platform": "window",
                "size": 798280864,
                "size_string": "761M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.0/qt-windows-opensource-5.2.0-android-x86-win32-offline.exe",
                "version": "5.2.0"
            },
            {
                "file_name": "qt-windows-opensource-5.2.0-msvc2012_opengl-x86_64-offline.exe",
                "md5": "c647728ad90995d035cc767f4aa48b07",
                "platform": "window",
                "size": 617827160,
                "size_string": "589M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.0/qt-windows-opensource-5.2.0-msvc2012_opengl-x86_64-offline.exe",
                "version": "5.2.0"
            },
            {
                "file_name": "qt-windows-opensource-5.2.0-msvc2012-x86_64-offline.exe",
                "md5": "47384de5bde73f39746f7eda125156ac",
                "platform": "window",
                "size": 618933048,
                "size_string": "590M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.0/qt-windows-opensource-5.2.0-msvc2012-x86_64-offline.exe",
                "version": "5.2.0"
            },
            {
                "file_name": "qt-windows-opensource-5.2.0-msvc2012-x86-offline.exe",
                "md5": "dceb2b4decb39b82d42ded507d407bc0",
                "platform": "window",
                "size": 606647728,
                "size_string": "579M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.0/qt-windows-opensource-5.2.0-msvc2012-x86-offline.exe",
                "version": "5.2.0"
            },
            {
                "file_name": "qt-windows-opensource-5.2.0-msvc2010_opengl-x86-offline.exe",
                "md5": "db6ab651c7e60b812c431e123b6f321b",
                "platform": "window",
                "size": 597111040,
                "size_string": "569M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.0/qt-windows-opensource-5.2.0-msvc2010_opengl-x86-offline.exe",
                "version": "5.2.0"
            },
            {
                "file_name": "qt-windows-opensource-5.2.0-msvc2010-x86-offline.exe",
                "md5": "90ce7c73ce4681c25b36e940d034b85f",
                "platform": "window",
                "size": 598020736,
                "size_string": "570M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.0/qt-windows-opensource-5.2.0-msvc2010-x86-offline.exe",
                "version": "5.2.0"
            },
            {
                "file_name": "qt-linux-opensource-5.2.0-x86_64-offline.run",
                "md5": "2035f177659257176e7d71e005f22bef",
                "platform": "linux",
                "size": 443912890,
                "size_string": "423M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.0/qt-linux-opensource-5.2.0-x86_64-offline.run",
                "version": "5.2.0"
            },
            {
                "file_name": "qt-linux-opensource-5.2.0-x86-offline.run",
                "md5": "ce529c68a5984070bc9b49545ef6bad8",
                "platform": "linux",
                "size": 446126011,
                "size_string": "425M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.0/qt-linux-opensource-5.2.0-x86-offline.run",
                "version": "5.2.0"
            },
            {
                "file_name": "qt-linux-opensource-5.2.0-android-x86_64-offline.run",
                "md5": "dfdc00f25f483b4d422767a8fa49e571",
                "platform": "linux",
                "size": 511238385,
                "size_string": "488M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.0/qt-linux-opensource-5.2.0-android-x86_64-offline.run",
                "version": "5.2.0"
            },
            {
                "file_name": "qt-linux-opensource-5.2.0-android-x86-offline.run",
                "md5": "67f8178563e4656078067628bbe71366",
                "platform": "linux",
                "size": 513485687,
                "size_string": "490M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.0/qt-linux-opensource-5.2.0-android-x86-offline.run",
                "version": "5.2.0"
            },
            {
                "file_name": "qt-mac-opensource-5.2.0-ios-x86_64-offline.dmg",
                "md5": "4898ff7e62ffe354acb43f7b19093327",
                "platform": "mac",
                "size": 996466347,
                "size_string": "950M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.0/qt-mac-opensource-5.2.0-ios-x86_64-offline.dmg",
                "version": "5.2.0"
            },
            {
                "file_name": "qt-mac-opensource-5.2.0-clang-offline.dmg",
                "md5": "322870e340052ea173b1ff6fef788c0d",
                "platform": "mac",
                "size": 455551667,
                "size_string": "434M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.0/qt-mac-opensource-5.2.0-clang-offline.dmg",
                "version": "5.2.0"
            },
            {
                "file_name": "qt-mac-opensource-5.2.0-android-x86_64-offline.dmg",
                "md5": "7b5191908276705cca064a0da5dadb29",
                "platform": "mac",
                "size": 515002756,
                "size_string": "491M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.0/qt-mac-opensource-5.2.0-android-x86_64-offline.dmg",
                "version": "5.2.0"
            },
            {
                "file_name": "qt-mac-opensource-5.2.0-android-ios-x86_64-offline.dmg",
                "md5": "39b7f54aff7b5c5ef187dfaac428ff31",
                "platform": "mac",
                "size": 1055918724,
                "size_string": "1.0G",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.2/5.2.0/qt-mac-opensource-5.2.0-android-ios-x86_64-offline.dmg",
                "version": "5.2.0"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.2.0"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-5.1.0.tar.xz",
                "md5": "44a507beebef73eb364b5a2ec7bbe090",
                "platform": "unknow",
                "size": 177164144,
                "size_string": "169M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.1/5.1.0/single/qt-everywhere-opensource-src-5.1.0.tar.xz",
                "version": "5.1.0"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.1.0.zip",
                "md5": "bc946668f76159ffc33c24badbc087e4",
                "platform": "unknow",
                "size": 292764480,
                "size_string": "279M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.1/5.1.0/single/qt-everywhere-opensource-src-5.1.0.zip",
                "version": "5.1.0"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.1.0.tar.gz",
                "md5": "787ce18c7f47fc14538b4362a0aa9edd",
                "platform": "unknow",
                "size": 236433580,
                "size_string": "225M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.1/5.1.0/single/qt-everywhere-opensource-src-5.1.0.tar.gz",
                "version": "5.1.0"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.1.0.7z",
                "md5": "a949e025c721f01ac9cbe00cfa489c0c",
                "platform": "unknow",
                "size": 171541360,
                "size_string": "164M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.1/5.1.0/single/qt-everywhere-opensource-src-5.1.0.7z",
                "version": "5.1.0"
            },
            {
                "file_name": "qt-windows-opensource-5.1.0-mingw48_opengl-x86-offline.exe",
                "md5": "944233c55382c6acfeace2ab9e2125ba",
                "platform": "window",
                "size": 698465600,
                "size_string": "666M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.1/5.1.0/qt-windows-opensource-5.1.0-mingw48_opengl-x86-offline.exe",
                "version": "5.1.0"
            },
            {
                "file_name": "qt-windows-opensource-5.1.0-android-x86-win32-offline.exe",
                "md5": "a88a3029f23f36b5cc07ce3dcce6edd3",
                "platform": "window",
                "size": 751146312,
                "size_string": "716M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.1/5.1.0/qt-windows-opensource-5.1.0-android-x86-win32-offline.exe",
                "version": "5.1.0"
            },
            {
                "file_name": "qt-windows-opensource-5.1.0-msvc2012-x86-offline.exe",
                "md5": "9a4a7061b62cb684c5bb16bebcae3af7",
                "platform": "window",
                "size": 535359048,
                "size_string": "511M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.1/5.1.0/qt-windows-opensource-5.1.0-msvc2012-x86-offline.exe",
                "version": "5.1.0"
            },
            {
                "file_name": "qt-windows-opensource-5.1.0-msvc2012_opengl-x86_64-offline.exe",
                "md5": "fca8f6ff98ee4d01a6c8fcfef8e339f6",
                "platform": "window",
                "size": 547601640,
                "size_string": "522M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.1/5.1.0/qt-windows-opensource-5.1.0-msvc2012_opengl-x86_64-offline.exe",
                "version": "5.1.0"
            },
            {
                "file_name": "qt-windows-opensource-5.1.0-msvc2012-x86_64-offline.exe",
                "md5": "eee7b15a7188721b7a18e0c9ed1625fb",
                "platform": "window",
                "size": 550075904,
                "size_string": "525M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.1/5.1.0/qt-windows-opensource-5.1.0-msvc2012-x86_64-offline.exe",
                "version": "5.1.0"
            },
            {
                "file_name": "qt-windows-opensource-5.1.0-msvc2010_opengl-x86-offline.exe",
                "md5": "8b4ea30efab56c7db27127c96e5b81a7",
                "platform": "window",
                "size": 528151120,
                "size_string": "504M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.1/5.1.0/qt-windows-opensource-5.1.0-msvc2010_opengl-x86-offline.exe",
                "version": "5.1.0"
            },
            {
                "file_name": "qt-windows-opensource-5.1.0-msvc2010-x86-offline.exe",
                "md5": "c9c22991389ec501729696b7f65e1223",
                "platform": "window",
                "size": 529294360,
                "size_string": "505M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.1/5.1.0/qt-windows-opensource-5.1.0-msvc2010-x86-offline.exe",
                "version": "5.1.0"
            },
            {
                "file_name": "qt-linux-opensource-5.1.0-x86_64-offline.run",
                "md5": "a5e643ad3154799248cb0e27837e229f",
                "platform": "linux",
                "size": 435934702,
                "size_string": "416M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.1/5.1.0/qt-linux-opensource-5.1.0-x86_64-offline.run",
                "version": "5.1.0"
            },
            {
                "file_name": "qt-linux-opensource-5.1.0-x86-offline.run",
                "md5": "9e337f9ec621b44ecbf5013483da9575",
                "platform": "linux",
                "size": 437574596,
                "size_string": "417M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.1/5.1.0/qt-linux-opensource-5.1.0-x86-offline.run",
                "version": "5.1.0"
            },
            {
                "file_name": "qt-linux-opensource-5.1.0-android-x86_64-offline.run",
                "md5": "0d7183d780e90bebfde218a14ff1b180",
                "platform": "linux",
                "size": 483488045,
                "size_string": "461M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.1/5.1.0/qt-linux-opensource-5.1.0-android-x86_64-offline.run",
                "version": "5.1.0"
            },
            {
                "file_name": "qt-linux-opensource-5.1.0-android-x86-offline.run",
                "md5": "8433cb4fedc51b634ed72553ac18d263",
                "platform": "linux",
                "size": 485397401,
                "size_string": "463M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.1/5.1.0/qt-linux-opensource-5.1.0-android-x86-offline.run",
                "version": "5.1.0"
            },
            {
                "file_name": "qt-mac-opensource-5.1.0-clang-offline.dmg",
                "md5": "d3487f1d16f30cefe378dc592b7cbaa7",
                "platform": "mac",
                "size": 445557836,
                "size_string": "425M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.1/5.1.0/qt-mac-opensource-5.1.0-clang-offline.dmg",
                "version": "5.1.0"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.1.0"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-5.0.2.zip",
                "md5": "c52dcf0facc0a086caae45a8179e54a6",
                "platform": "unknow",
                "size": 284015558,
                "size_string": "271M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.0/5.0.2/single/qt-everywhere-opensource-src-5.0.2.zip",
                "version": "5.0.2"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.0.2.tar.xz",
                "md5": "2cab3518d86fe8f0638c7faea8b46397",
                "platform": "unknow",
                "size": 172800940,
                "size_string": "165M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.0/5.0.2/single/qt-everywhere-opensource-src-5.0.2.tar.xz",
                "version": "5.0.2"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.0.2.7z",
                "md5": "5042ecd659b980e88b5137a602a1d398",
                "platform": "unknow",
                "size": 167131191,
                "size_string": "159M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.0/5.0.2/single/qt-everywhere-opensource-src-5.0.2.7z",
                "version": "5.0.2"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.0.2.tar.gz",
                "md5": "87cae8ae2f82f41ba027c2db0ab639b7",
                "platform": "unknow",
                "size": 231010088,
                "size_string": "220M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.0/5.0.2/single/qt-everywhere-opensource-src-5.0.2.tar.gz",
                "version": "5.0.2"
            },
            {
                "file_name": "qt-windows-opensource-5.0.2-msvc2010_32_opengl-x86-offline.exe",
                "md5": "f3b612227a0adb6be02323294a163af3",
                "platform": "window",
                "size": 498938504,
                "size_string": "476M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.0/5.0.2/qt-windows-opensource-5.0.2-msvc2010_32_opengl-x86-offline.exe",
                "version": "5.0.2"
            },
            {
                "file_name": "qt-windows-opensource-5.0.2-msvc2010_32-x86-offline.exe",
                "md5": "e0b50722171faef166ee662f1e08c1c8",
                "platform": "window",
                "size": 508852984,
                "size_string": "485M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.0/5.0.2/qt-windows-opensource-5.0.2-msvc2010_32-x86-offline.exe",
                "version": "5.0.2"
            },
            {
                "file_name": "qt-windows-opensource-5.0.2-msvc2012_64-x64-offline.exe",
                "md5": "53617845f4709135ee9a320cc8211b15",
                "platform": "window",
                "size": 524751544,
                "size_string": "500M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.0/5.0.2/qt-windows-opensource-5.0.2-msvc2012_64-x64-offline.exe",
                "version": "5.0.2"
            },
            {
                "file_name": "qt-windows-opensource-5.0.2-mingw47_32-x86-offline.exe",
                "md5": "34b3e5c1cabe35651c64936342218a2b",
                "platform": "window",
                "size": 681181264,
                "size_string": "650M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.0/5.0.2/qt-windows-opensource-5.0.2-mingw47_32-x86-offline.exe",
                "version": "5.0.2"
            },
            {
                "file_name": "qt-linux-opensource-5.0.2-x86_64-offline.run",
                "md5": "6db26390c8102308a655684ec957adc9",
                "platform": "linux",
                "size": 412907365,
                "size_string": "394M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.0/5.0.2/qt-linux-opensource-5.0.2-x86_64-offline.run",
                "version": "5.0.2"
            },
            {
                "file_name": "qt-linux-opensource-5.0.2-x86-offline.run",
                "md5": "f4ff26e65e7229e514c72e2eaf6743a0",
                "platform": "linux",
                "size": 414265884,
                "size_string": "395M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.0/5.0.2/qt-linux-opensource-5.0.2-x86-offline.run",
                "version": "5.0.2"
            },
            {
                "file_name": "qt-mac-opensource-5.0.2-clang-offline.dmg",
                "md5": "92a3bc8b27956a07f0f675345bb7d984",
                "platform": "mac",
                "size": 424054974,
                "size_string": "404M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.0/5.0.2/qt-mac-opensource-5.0.2-clang-offline.dmg",
                "version": "5.0.2"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.0.2"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-5.0.1.zip",
                "md5": "874da1d3d9cf214c20bfa5b8ee366b0b",
                "platform": "unknow",
                "size": 282838616,
                "size_string": "270M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.0/5.0.1/single/qt-everywhere-opensource-src-5.0.1.zip",
                "version": "5.0.1"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.0.1.tar.xz",
                "md5": "00a577bd88e682d1b4d01d41d1d699cf",
                "platform": "unknow",
                "size": 172770544,
                "size_string": "165M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.0/5.0.1/single/qt-everywhere-opensource-src-5.0.1.tar.xz",
                "version": "5.0.1"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.0.1.tar.gz",
                "md5": "8e8137c23c8ff078a560d3c62b4383e7",
                "platform": "unknow",
                "size": 229909931,
                "size_string": "219M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.0/5.0.1/single/qt-everywhere-opensource-src-5.0.1.tar.gz",
                "version": "5.0.1"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.0.1.7z",
                "md5": "e9d1d506db0d5fa0f002afa3c3fe1b33",
                "platform": "unknow",
                "size": 166962122,
                "size_string": "159M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.0/5.0.1/single/qt-everywhere-opensource-src-5.0.1.7z",
                "version": "5.0.1"
            },
            {
                "file_name": "qt-windows-opensource-5.0.1-msvc2010_32-x86-offline.exe",
                "md5": "912bee742ee306c64a93cd08d59e1fc7",
                "platform": "window",
                "size": 504133016,
                "size_string": "481M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.0/5.0.1/qt-windows-opensource-5.0.1-msvc2010_32-x86-offline.exe",
                "version": "5.0.1"
            },
            {
                "file_name": "qt-windows-opensource-5.0.1-mingw47_32-x86-offline.exe",
                "md5": "198ac515edc9f5727bf4c6197d0748f3",
                "platform": "window",
                "size": 862703256,
                "size_string": "823M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.0/5.0.1/qt-windows-opensource-5.0.1-mingw47_32-x86-offline.exe",
                "version": "5.0.1"
            },
            {
                "file_name": "qt-linux-opensource-5.0.1-x86_64-offline.run",
                "md5": "49f02984960f54beb908ef423762cb48",
                "platform": "linux",
                "size": 406838407,
                "size_string": "388M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.0/5.0.1/qt-linux-opensource-5.0.1-x86_64-offline.run",
                "version": "5.0.1"
            },
            {
                "file_name": "qt-linux-opensource-5.0.1-x86-offline.run",
                "md5": "4ce372b2a798b1aeb3a30b7394e5da87",
                "platform": "linux",
                "size": 407106090,
                "size_string": "388M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.0/5.0.1/qt-linux-opensource-5.0.1-x86-offline.run",
                "version": "5.0.1"
            },
            {
                "file_name": "qt-mac-opensource-5.0.1-clang-offline.dmg",
                "md5": "17c55c0c23bca1cf69b390c8cd3f022f",
                "platform": "mac",
                "size": 417325043,
                "size_string": "398M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.0/5.0.1/qt-mac-opensource-5.0.1-clang-offline.dmg",
                "version": "5.0.1"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.0.1"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-5.0.0.zip",
                "md5": "82439ec4327eb44d42071ae88cf430f8",
                "platform": "unknow",
                "size": 282254720,
                "size_string": "269M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.0/5.0.0/single/qt-everywhere-opensource-src-5.0.0.zip",
                "version": "5.0.0"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.0.0.tar.xz",
                "md5": "6b4f2f3f1e6252fbce3a57ac5165baff",
                "platform": "unknow",
                "size": 172545344,
                "size_string": "165M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.0/5.0.0/single/qt-everywhere-opensource-src-5.0.0.tar.xz",
                "version": "5.0.0"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.0.0.tar.gz",
                "md5": "1a1b73f913a965f6b0cf02bb820c8a03",
                "platform": "unknow",
                "size": 229374296,
                "size_string": "219M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.0/5.0.0/single/qt-everywhere-opensource-src-5.0.0.tar.gz",
                "version": "5.0.0"
            },
            {
                "file_name": "qt-everywhere-opensource-src-5.0.0.7z",
                "md5": "03511516b8e586b14e3609a8f66e5b70",
                "platform": "unknow",
                "size": 166731374,
                "size_string": "159M",
                "type": "source",
                "url": "https://download.qt.io/new_archive/qt/5.0/5.0.0/single/qt-everywhere-opensource-src-5.0.0.7z",
                "version": "5.0.0"
            },
            {
                "file_name": "qt-windows-opensource-5.0.0-msvc2010_32-x86-offline.exe",
                "md5": "0105b08711e4a0f024131ef12c4c8970",
                "platform": "window",
                "size": 425318832,
                "size_string": "406M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.0/5.0.0/qt-windows-opensource-5.0.0-msvc2010_32-x86-offline.exe",
                "version": "5.0.0"
            },
            {
                "file_name": "qt-linux-opensource-5.0.0-x86-offline.run",
                "md5": "4c21394c57f5cdc16b6fc4dbed07f226",
                "platform": "linux",
                "size": 430605254,
                "size_string": "411M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.0/5.0.0/qt-linux-opensource-5.0.0-x86-offline.run",
                "version": "5.0.0"
            },
            {
                "file_name": "qt-linux-opensource-5.0.0-x86_64-offline.run",
                "md5": "24b3464b13e8ff3fb0baa7bf9a0dae38",
                "platform": "linux",
                "size": 429794304,
                "size_string": "410M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.0/5.0.0/qt-linux-opensource-5.0.0-x86_64-offline.run",
                "version": "5.0.0"
            },
            {
                "file_name": "qt-mac-opensource-5.0.0-clang-offline.dmg",
                "md5": "64fc78b2e52054441094990bea642526",
                "platform": "mac",
                "size": 417324489,
                "size_string": "398M",
                "type": "bin",
                "url": "https://download.qt.io/new_archive/qt/5.0/5.0.0/qt-mac-opensource-5.0.0-clang-offline.dmg",
                "version": "5.0.0"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "5.0.0"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-4.8.7.zip",
                "md5": "0d3427d71aa0e8aec87288d7329e26cb",
                "platform": "unknow",
                "size": 281457960,
                "size_string": "268M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.7/qt-everywhere-opensource-src-4.8.7.zip",
                "version": "4.8.7"
            },
            {
                "file_name": "qt-everywhere-opensource-src-4.8.7.tar.gz",
                "md5": "d990ee66bf7ab0c785589776f35ba6ad",
                "platform": "unknow",
                "size": 241075567,
                "size_string": "230M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.7/qt-everywhere-opensource-src-4.8.7.tar.gz",
                "version": "4.8.7"
            },
            {
                "file_name": "qt-opensource-windows-x86-vs2008-4.8.7.exe",
                "md5": "9595d66c73ba987abe5c36c8bcc3328f",
                "platform": "window",
                "size": 246173264,
                "size_string": "235M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.7/qt-opensource-windows-x86-vs2008-4.8.7.exe",
                "version": "4.8.7"
            },
            {
                "file_name": "qt-opensource-windows-x86-mingw482-4.8.7.exe",
                "md5": "e44929c709f9a70df1bdc1f551c01710",
                "platform": "window",
                "size": 344458064,
                "size_string": "329M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.7/qt-opensource-windows-x86-mingw482-4.8.7.exe",
                "version": "4.8.7"
            },
            {
                "file_name": "qt-opensource-windows-x86-vs2010-4.8.7.exe",
                "md5": "0f1e3abc71c9e867aa84fe8503250fdc",
                "platform": "window",
                "size": 247159680,
                "size_string": "236M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.7/qt-opensource-windows-x86-vs2010-4.8.7.exe",
                "version": "4.8.7"
            },
            {
                "file_name": "qt-opensource-mac-4.8.7.dmg",
                "md5": "a235da5c245798171175c4d227ba68cd",
                "platform": "mac",
                "size": 193854082,
                "size_string": "185M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.7/qt-opensource-mac-4.8.7.dmg",
                "version": "4.8.7"
            },
            {
                "file_name": "qt-opensource-mac-4.8.7-debug-libs.dmg",
                "md5": "034f62623861cefc303b1f2f8a1645c9",
                "platform": "mac",
                "size": 499352366,
                "size_string": "476M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.7/qt-opensource-mac-4.8.7-debug-libs.dmg",
                "version": "4.8.7"
            },
            {
                "file_name": "md5sums-4.8.7",
                "md5": "10a825fdaa809108eac1a1257e7fd0c8",
                "platform": "unknow",
                "size": -1,
                "size_string": "Invalid",
                "type": "other",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.7/md5sums-4.8.7",
                "version": "4.8.7"
            },
            {
                "file_name": "changes-4.8.7",
                "md5": "e2f134bd0385f104e94c7189c9a652c1",
                "platform": "unknow",
                "size": 11931,
                "size_string": "12K",
                "type": "other",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.7/changes-4.8.7",
                "version": "4.8.7"
            }
        ],
        "preview_list": [
            "source",
            "bin",
            "other"
        ],
        "version": "4.8.7"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-4.8.6.tar.gz",
                "md5": "2edbe4d6c2eff33ef91732602f3518eb",
                "platform": "unknow",
                "size": 241623667,
                "size_string": "230M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.6/qt-everywhere-opensource-src-4.8.6.tar.gz",
                "version": "4.8.6"
            },
            {
                "file_name": "qt-everywhere-opensource-src-4.8.6.zip",
                "md5": "61f7d0ebe900ed3fb64036cfdca55975",
                "platform": "unknow",
                "size": 281915668,
                "size_string": "269M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.6/qt-everywhere-opensource-src-4.8.6.zip",
                "version": "4.8.6"
            },
            {
                "file_name": "qt-opensource-windows-x86-vs2010-4.8.6.exe",
                "md5": "4c6e66fab7cb4d0728248c9c1ee630c5",
                "platform": "window",
                "size": 247166808,
                "size_string": "236M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.6/qt-opensource-windows-x86-vs2010-4.8.6.exe",
                "version": "4.8.6"
            },
            {
                "file_name": "qt-opensource-windows-x86-vs2008-4.8.6.exe",
                "md5": "8d6f489c8550792314f617ae80daa7e4",
                "platform": "window",
                "size": 246551936,
                "size_string": "235M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.6/qt-opensource-windows-x86-vs2008-4.8.6.exe",
                "version": "4.8.6"
            },
            {
                "file_name": "qt-opensource-windows-x86-mingw482-4.8.6-1.exe",
                "md5": "b0f70eb2607b89653c7aafe524352ce3",
                "platform": "window",
                "size": 344332608,
                "size_string": "328M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.6/qt-opensource-windows-x86-mingw482-4.8.6-1.exe",
                "version": "4.8.6"
            },
            {
                "file_name": "qt-opensource-mac-4.8.6-1.dmg",
                "md5": "37e6efc7b71b2c6d80e1726a6e51deca",
                "platform": "mac",
                "size": 193803718,
                "size_string": "185M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.6/qt-opensource-mac-4.8.6-1.dmg",
                "version": "4.8.6"
            },
            {
                "file_name": "qt-opensource-mac-4.8.6-1-debug-libs.dmg",
                "md5": "2063adb22f62a67b76032727a1ae0412",
                "platform": "mac",
                "size": 499207744,
                "size_string": "476M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.6/qt-opensource-mac-4.8.6-1-debug-libs.dmg",
                "version": "4.8.6"
            },
            {
                "file_name": "md5sums-4.8.6",
                "md5": "9306fe9d684d70789dd2afd601f38846",
                "platform": "unknow",
                "size": -1,
                "size_string": "Invalid",
                "type": "other",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.6/md5sums-4.8.6",
                "version": "4.8.6"
            },
            {
                "file_name": "changes-4.8.6",
                "md5": "87de0bfb113d8b400d72abc704a6ccbb",
                "platform": "unknow",
                "size": 18225,
                "size_string": "18K",
                "type": "other",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.6/changes-4.8.6",
                "version": "4.8.6"
            }
        ],
        "preview_list": [
            "source",
            "bin",
            "other"
        ],
        "version": "4.8.6"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-4.8.5.tar.gz",
                "md5": "1864987bdbb2f58f8ae8b350dfdbe133",
                "platform": "unknow",
                "size": 241491467,
                "size_string": "230M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.5/qt-everywhere-opensource-src-4.8.5.tar.gz",
                "version": "4.8.5"
            },
            {
                "file_name": "qt-everywhere-opensource-src-4.8.5.zip",
                "md5": "d0c366ddf9796256e168ccce6b087175",
                "platform": "unknow",
                "size": 281761588,
                "size_string": "269M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.5/qt-everywhere-opensource-src-4.8.5.zip",
                "version": "4.8.5"
            },
            {
                "file_name": "qt-win-opensource-4.8.5-vs2010.exe",
                "md5": "d6ad48003c7c1ba5810b7ee203f38ed2",
                "platform": "window",
                "size": 246923552,
                "size_string": "235M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.5/qt-win-opensource-4.8.5-vs2010.exe",
                "version": "4.8.5"
            },
            {
                "file_name": "qt-win-opensource-4.8.5-vs2008.exe",
                "md5": "067670e6bb271dc63038b981bd660632",
                "platform": "window",
                "size": 246311472,
                "size_string": "235M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.5/qt-win-opensource-4.8.5-vs2008.exe",
                "version": "4.8.5"
            },
            {
                "file_name": "qt-win-opensource-4.8.5-mingw.exe",
                "md5": "0f4f4db0f8b89fff8160a7e9d8e796e1",
                "platform": "window",
                "size": 332432680,
                "size_string": "317M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.5/qt-win-opensource-4.8.5-mingw.exe",
                "version": "4.8.5"
            },
            {
                "file_name": "qt-mac-opensource-4.8.5.dmg",
                "md5": "0c75d5ebb731939f001729a5cb267322",
                "platform": "mac",
                "size": 193788895,
                "size_string": "185M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.5/qt-mac-opensource-4.8.5.dmg",
                "version": "4.8.5"
            },
            {
                "file_name": "qt-mac-opensource-4.8.5-debug-libs.dmg",
                "md5": "5d239b5d4c89031da6ed24b2dd3d7cf7",
                "platform": "mac",
                "size": 503584030,
                "size_string": "480M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.5/qt-mac-opensource-4.8.5-debug-libs.dmg",
                "version": "4.8.5"
            },
            {
                "file_name": "md5sums-4.8.5",
                "md5": "76d13f96ede07a7540dab4c508e938b5",
                "platform": "unknow",
                "size": -1,
                "size_string": "Invalid",
                "type": "other",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.5/md5sums-4.8.5",
                "version": "4.8.5"
            },
            {
                "file_name": "changes-4.8.5",
                "md5": "6626781d214c5fb7d74b1c2305ae595f",
                "platform": "unknow",
                "size": 22434,
                "size_string": "22K",
                "type": "other",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.5/changes-4.8.5",
                "version": "4.8.5"
            }
        ],
        "preview_list": [
            "source",
            "bin",
            "other"
        ],
        "version": "4.8.5"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-4.8.4.zip",
                "md5": "73b5bc046491acd2c62b35af52287387",
                "platform": "unknow",
                "size": 282284785,
                "size_string": "269M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.4/qt-everywhere-opensource-src-4.8.4.zip",
                "version": "4.8.4"
            },
            {
                "file_name": "qt-everywhere-opensource-src-4.8.4.tar.gz",
                "md5": "89c5ecba180cae74c66260ac732dc5cb",
                "platform": "unknow",
                "size": 236593028,
                "size_string": "226M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.4/qt-everywhere-opensource-src-4.8.4.tar.gz",
                "version": "4.8.4"
            },
            {
                "file_name": "qt-win-opensource-4.8.4-mingw.exe",
                "md5": "1f4c7cc1d6d097fc17ff443ceae29706",
                "platform": "window",
                "size": 332706008,
                "size_string": "317M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.4/qt-win-opensource-4.8.4-mingw.exe",
                "version": "4.8.4"
            },
            {
                "file_name": "qt-win-opensource-4.8.4-vs2008.exe",
                "md5": "45184f186cd705bed4e409ac241d6014",
                "platform": "window",
                "size": 244861376,
                "size_string": "234M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.4/qt-win-opensource-4.8.4-vs2008.exe",
                "version": "4.8.4"
            },
            {
                "file_name": "qt-win-opensource-4.8.4-vs2010.exe",
                "md5": "c0bdc6b95c80b69342d9e67c6570d89f",
                "platform": "window",
                "size": 245765280,
                "size_string": "234M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.4/qt-win-opensource-4.8.4-vs2010.exe",
                "version": "4.8.4"
            },
            {
                "file_name": "qt-mac-opensource-4.8.4-debug-libs.dmg",
                "md5": "d2ccbd315a2e36dce68153728f6cd4ad",
                "platform": "mac",
                "size": 503455345,
                "size_string": "480M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.4/qt-mac-opensource-4.8.4-debug-libs.dmg",
                "version": "4.8.4"
            },
            {
                "file_name": "qt-mac-opensource-4.8.4.dmg",
                "md5": "d9f382a701c32f5c97d87ccccd4e72d3",
                "platform": "mac",
                "size": 194148939,
                "size_string": "185M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.4/qt-mac-opensource-4.8.4.dmg",
                "version": "4.8.4"
            },
            {
                "file_name": "changes-4.8.4",
                "md5": "8d4ce7ea0266fc8013a23e29365e34e0",
                "platform": "unknow",
                "size": 12032,
                "size_string": "12K",
                "type": "other",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.4/changes-4.8.4",
                "version": "4.8.4"
            },
            {
                "file_name": "md5sums-4.8.4.txt",
                "md5": "123dbfdcfd704f878f80c421577f382c",
                "platform": "unknow",
                "size": -1,
                "size_string": "Invalid",
                "type": "other",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.4/md5sums-4.8.4.txt",
                "version": "4.8.4"
            }
        ],
        "preview_list": [
            "source",
            "bin",
            "other"
        ],
        "version": "4.8.4"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-4.8.3.zip",
                "md5": "c61373f088b78b6220f7bb7b8ca6bfb8",
                "platform": "unknow",
                "size": 272773412,
                "size_string": "260M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.3/qt-everywhere-opensource-src-4.8.3.zip",
                "version": "4.8.3"
            },
            {
                "file_name": "qt-everywhere-opensource-src-4.8.3.tar.gz",
                "md5": "a663b6c875f8d7caa8ac9c30e4a4ec3b",
                "platform": "unknow",
                "size": 233635127,
                "size_string": "223M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.3/qt-everywhere-opensource-src-4.8.3.tar.gz",
                "version": "4.8.3"
            },
            {
                "file_name": "qt-win-opensource-4.8.3-vs2010.exe",
                "md5": "5da2f60e469ddf2462e988864427f9e5",
                "platform": "window",
                "size": 245546472,
                "size_string": "234M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.3/qt-win-opensource-4.8.3-vs2010.exe",
                "version": "4.8.3"
            },
            {
                "file_name": "qt-win-opensource-4.8.3-vs2008.exe",
                "md5": "552ec325eab4d82df7fa41ee2a2c1dc3",
                "platform": "window",
                "size": 244976744,
                "size_string": "234M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.3/qt-win-opensource-4.8.3-vs2008.exe",
                "version": "4.8.3"
            },
            {
                "file_name": "qt-win-opensource-4.8.3-mingw.exe",
                "md5": "f058b337ba4d305b2ad08762326358eb",
                "platform": "window",
                "size": 332097752,
                "size_string": "317M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.3/qt-win-opensource-4.8.3-mingw.exe",
                "version": "4.8.3"
            },
            {
                "file_name": "qt-mac-opensource-4.8.3.dmg",
                "md5": "02a569cb0dcc325d8adcfa7d86ebcd72",
                "platform": "mac",
                "size": 191356132,
                "size_string": "182M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.3/qt-mac-opensource-4.8.3.dmg",
                "version": "4.8.3"
            },
            {
                "file_name": "qt-mac-opensource-4.8.3-debug-libs.dmg",
                "md5": "5352cc8396ed108fcd779cc577f4348a",
                "platform": "mac",
                "size": 502340734,
                "size_string": "479M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.3/qt-mac-opensource-4.8.3-debug-libs.dmg",
                "version": "4.8.3"
            },
            {
                "file_name": "changes-4.8.3",
                "md5": "6eae5bdf25319c19a5ec8477124ed7f7",
                "platform": "unknow",
                "size": 17411,
                "size_string": "17K",
                "type": "other",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.3/changes-4.8.3",
                "version": "4.8.3"
            },
            {
                "file_name": "md5sums-4.8.3.txt",
                "md5": "512a28c5832d7c7436f64db726c69d56",
                "platform": "unknow",
                "size": -1,
                "size_string": "Invalid",
                "type": "other",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.3/md5sums-4.8.3.txt",
                "version": "4.8.3"
            }
        ],
        "preview_list": [
            "source",
            "bin",
            "other"
        ],
        "version": "4.8.3"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-4.8.2.zip",
                "md5": "2aa01755afeb4d7d53c1f78ca8741706",
                "platform": "unknow",
                "size": 272537159,
                "size_string": "260M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.2/qt-everywhere-opensource-src-4.8.2.zip",
                "version": "4.8.2"
            },
            {
                "file_name": "qt-everywhere-opensource-src-4.8.2.tar.gz",
                "md5": "3c1146ddf56247e16782f96910a8423b",
                "platform": "unknow",
                "size": 239108331,
                "size_string": "228M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.2/qt-everywhere-opensource-src-4.8.2.tar.gz",
                "version": "4.8.2"
            },
            {
                "file_name": "qt-win-opensource-4.8.2-vs2008.exe",
                "md5": "d27d8b494648eab8509461d68362870e",
                "platform": "window",
                "size": 245076664,
                "size_string": "234M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.2/qt-win-opensource-4.8.2-vs2008.exe",
                "version": "4.8.2"
            },
            {
                "file_name": "qt-win-opensource-4.8.2-mingw.exe",
                "md5": "3c77ec21f9128f3055ba8a48a6b89cc2",
                "platform": "window",
                "size": 333441072,
                "size_string": "318M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.2/qt-win-opensource-4.8.2-mingw.exe",
                "version": "4.8.2"
            },
            {
                "file_name": "qt-win-opensource-4.8.2-vs2010.exe",
                "md5": "0533c1108118dfe48a16b1fa62d3991d",
                "platform": "window",
                "size": 245537344,
                "size_string": "234M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.2/qt-win-opensource-4.8.2-vs2010.exe",
                "version": "4.8.2"
            },
            {
                "file_name": "qt-mac-opensource-4.8.2.dmg",
                "md5": "5d3835fd518b1819700ec438bf0016db",
                "platform": "mac",
                "size": 190852217,
                "size_string": "182M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.2/qt-mac-opensource-4.8.2.dmg",
                "version": "4.8.2"
            },
            {
                "file_name": "qt-mac-opensource-4.8.2-debug-libs.dmg",
                "md5": "6b139319cd62f2c6b863fe5537cf3f26",
                "platform": "mac",
                "size": 501070427,
                "size_string": "478M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.2/qt-mac-opensource-4.8.2-debug-libs.dmg",
                "version": "4.8.2"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.8.2"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-4.8.1.tar.gz",
                "md5": "7960ba8e18ca31f0c6e4895a312f92ff",
                "platform": "unknow",
                "size": 239606696,
                "size_string": "229M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.1/qt-everywhere-opensource-src-4.8.1.tar.gz",
                "version": "4.8.1"
            },
            {
                "file_name": "qt-everywhere-opensource-src-4.8.1.zip",
                "md5": "c0a72c81726bc62263b8826106d2c507",
                "platform": "unknow",
                "size": 273335684,
                "size_string": "261M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.1/qt-everywhere-opensource-src-4.8.1.zip",
                "version": "4.8.1"
            },
            {
                "file_name": "qt-win-opensource-4.8.1-vs2010.exe",
                "md5": "b433e9f0d8ee98adf1440042ccf53e16",
                "platform": "window",
                "size": 246111968,
                "size_string": "235M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.1/qt-win-opensource-4.8.1-vs2010.exe",
                "version": "4.8.1"
            },
            {
                "file_name": "qt-win-opensource-4.8.1-vs2008.exe",
                "md5": "a29dc2dd9e69e042e904685d1fd85ba1",
                "platform": "window",
                "size": 245203808,
                "size_string": "234M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.1/qt-win-opensource-4.8.1-vs2008.exe",
                "version": "4.8.1"
            },
            {
                "file_name": "qt-win-opensource-4.8.1-mingw.exe",
                "md5": "0721daa6ccb67ae60d8d81d53a641792",
                "platform": "window",
                "size": 333911112,
                "size_string": "318M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.1/qt-win-opensource-4.8.1-mingw.exe",
                "version": "4.8.1"
            },
            {
                "file_name": "qt-mac-opensource-4.8.1.dmg",
                "md5": "9c3f90d1a49eec4976059ff688355c7d",
                "platform": "mac",
                "size": 191025808,
                "size_string": "182M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.1/qt-mac-opensource-4.8.1.dmg",
                "version": "4.8.1"
            },
            {
                "file_name": "qt-mac-opensource-4.8.1-debug-libs.dmg",
                "md5": "4cb8e64e9b380dc80d1f4b66f73162a3",
                "platform": "mac",
                "size": 501183466,
                "size_string": "478M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.1/qt-mac-opensource-4.8.1-debug-libs.dmg",
                "version": "4.8.1"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.8.1"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-4.8.0.zip",
                "md5": "e68d46e469c440aa4c9c7241a1ddda90",
                "platform": "unknow",
                "size": 273106373,
                "size_string": "260M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.0/qt-everywhere-opensource-src-4.8.0.zip",
                "version": "4.8.0"
            },
            {
                "file_name": "qt-everywhere-opensource-src-4.8.0.tar.gz",
                "md5": "e8a5fdbeba2927c948d9f477a6abe904",
                "platform": "unknow",
                "size": 238580694,
                "size_string": "228M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.0/qt-everywhere-opensource-src-4.8.0.tar.gz",
                "version": "4.8.0"
            },
            {
                "file_name": "qt-win-opensource-4.8.0-vs2008.exe",
                "md5": "a75e7534928d2f577bcf263a9c2ed367",
                "platform": "window",
                "size": 285485008,
                "size_string": "272M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.0/qt-win-opensource-4.8.0-vs2008.exe",
                "version": "4.8.0"
            },
            {
                "file_name": "qt-win-opensource-4.8.0-mingw.exe",
                "md5": "48780bd3a7fd8220c24481ae0bad25f7",
                "platform": "window",
                "size": 370401469,
                "size_string": "353M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.0/qt-win-opensource-4.8.0-mingw.exe",
                "version": "4.8.0"
            },
            {
                "file_name": "qt-win-opensource-4.8.0-vs2010.exe",
                "md5": "d2531b6bdd2bd7a91be9f51523986dee",
                "platform": "window",
                "size": 287563192,
                "size_string": "274M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.0/qt-win-opensource-4.8.0-vs2010.exe",
                "version": "4.8.0"
            },
            {
                "file_name": "qt-mac-opensource-4.8.0.dmg",
                "md5": "d9aa1f9739666ba4e719c08d7e8f15ea",
                "platform": "mac",
                "size": 186034084,
                "size_string": "177M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.0/qt-mac-opensource-4.8.0.dmg",
                "version": "4.8.0"
            },
            {
                "file_name": "qt-mac-opensource-4.8.0-debug-libs.dmg",
                "md5": "8975d538073b65ea7b48747f826ff8ae",
                "platform": "mac",
                "size": 663480530,
                "size_string": "633M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.8/4.8.0/qt-mac-opensource-4.8.0-debug-libs.dmg",
                "version": "4.8.0"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.8.0"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-4.7.4.tar.gz",
                "md5": "9831cf1dfa8d0689a06c2c54c5c65aaf",
                "platform": "unknow",
                "size": 220388303,
                "size_string": "210M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.7/qt-everywhere-opensource-src-4.7.4.tar.gz",
                "version": "4.7.4"
            },
            {
                "file_name": "qt-everywhere-opensource-src-4.7.4.zip",
                "md5": "6e7e53c521fee614cefeea83835ed4aa",
                "platform": "unknow",
                "size": 249163314,
                "size_string": "238M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.7/qt-everywhere-opensource-src-4.7.4.zip",
                "version": "4.7.4"
            },
            {
                "file_name": "qt-win-opensource-4.7.4-vs2008.exe",
                "md5": "f0b7391c151edea16738e7ca929f4366",
                "platform": "window",
                "size": 245991000,
                "size_string": "235M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.7/qt-win-opensource-4.7.4-vs2008.exe",
                "version": "4.7.4"
            },
            {
                "file_name": "qt-win-opensource-4.7.4-mingw.exe",
                "md5": "bafe7f9ae6ddcfaf96fb014d85f1859d",
                "platform": "window",
                "size": 344406160,
                "size_string": "328M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.7/qt-win-opensource-4.7.4-mingw.exe",
                "version": "4.7.4"
            },
            {
                "file_name": "qt-mac-opensource-4.7.4.dmg",
                "md5": "738350d170cd6d2bdc7a9ef0498739cd",
                "platform": "mac",
                "size": 221492557,
                "size_string": "211M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.7/qt-mac-opensource-4.7.4.dmg",
                "version": "4.7.4"
            },
            {
                "file_name": "qt-mac-opensource-4.7.4-debug-libs.dmg",
                "md5": "7e74be0cc6312f568cec91cfb3d6e39b",
                "platform": "mac",
                "size": 756520224,
                "size_string": "721M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.7/qt-mac-opensource-4.7.4-debug-libs.dmg",
                "version": "4.7.4"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.7.4"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-4.7.3.zip",
                "md5": "fc732cf9f383a1b7611c71e66f19e780",
                "platform": "unknow",
                "size": 248645754,
                "size_string": "237M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.7/qt-everywhere-opensource-src-4.7.3.zip",
                "version": "4.7.3"
            },
            {
                "file_name": "qt-everywhere-opensource-src-4.7.3.tar.gz",
                "md5": "49b96eefb1224cc529af6fe5608654fe",
                "platform": "unknow",
                "size": 213659173,
                "size_string": "204M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.7/qt-everywhere-opensource-src-4.7.3.tar.gz",
                "version": "4.7.3"
            },
            {
                "file_name": "qt-win-opensource-4.7.3-mingw.exe",
                "md5": "91c8ec81cea9fd1dd57fb092b5a34b84",
                "platform": "window",
                "size": 336598488,
                "size_string": "321M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.7/qt-win-opensource-4.7.3-mingw.exe",
                "version": "4.7.3"
            },
            {
                "file_name": "qt-win-opensource-4.7.3-vs2008.exe",
                "md5": "79eb6c048aa084d861a5293e73ffab22",
                "platform": "window",
                "size": 240871448,
                "size_string": "230M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.7/qt-win-opensource-4.7.3-vs2008.exe",
                "version": "4.7.3"
            },
            {
                "file_name": "qt-mac-carbon-opensource-4.7.3-debug-libs.dmg",
                "md5": "af4c63d6e337e55dc58d19f0877b27c4",
                "platform": "mac",
                "size": 394458099,
                "size_string": "376M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.7/qt-mac-carbon-opensource-4.7.3-debug-libs.dmg",
                "version": "4.7.3"
            },
            {
                "file_name": "qt-mac-carbon-opensource-4.7.3.dmg",
                "md5": "9853374afc12ba345a80df19de13fad4",
                "platform": "mac",
                "size": 218775064,
                "size_string": "209M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.7/qt-mac-carbon-opensource-4.7.3.dmg",
                "version": "4.7.3"
            },
            {
                "file_name": "qt-mac-opensource-4.7.3.dmg",
                "md5": "f34ba301f79783b6eac410728f02a10e",
                "platform": "mac",
                "size": 219292712,
                "size_string": "209M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.7/qt-mac-opensource-4.7.3.dmg",
                "version": "4.7.3"
            },
            {
                "file_name": "qt-mac-opensource-4.7.3-debug-libs.dmg",
                "md5": "c2b1218bc067de0950e548b095957212",
                "platform": "mac",
                "size": 754492194,
                "size_string": "720M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.7/qt-mac-opensource-4.7.3-debug-libs.dmg",
                "version": "4.7.3"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.7.3"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-4.7.2.zip",
                "md5": "f62c422bf0d3440acfb458ac7bbf9afa",
                "platform": "unknow",
                "size": 248645312,
                "size_string": "237M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.7/qt-everywhere-opensource-src-4.7.2.zip",
                "version": "4.7.2"
            },
            {
                "file_name": "qt-everywhere-opensource-src-4.7.2.tar.gz",
                "md5": "66b992f5c21145df08c99d21847f4fdb",
                "platform": "unknow",
                "size": 213663032,
                "size_string": "204M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.7/qt-everywhere-opensource-src-4.7.2.tar.gz",
                "version": "4.7.2"
            },
            {
                "file_name": "qt-win-opensource-4.7.2-mingw.exe",
                "md5": "31568d8bed7009899c4e983cc7da7a81",
                "platform": "window",
                "size": 336600168,
                "size_string": "321M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.7/qt-win-opensource-4.7.2-mingw.exe",
                "version": "4.7.2"
            },
            {
                "file_name": "qt-win-opensource-4.7.2-vs2008.exe",
                "md5": "b40706cbb4979d5723cb6aa0310eb620",
                "platform": "window",
                "size": 228487288,
                "size_string": "218M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.7/qt-win-opensource-4.7.2-vs2008.exe",
                "version": "4.7.2"
            },
            {
                "file_name": "qt-mac-carbon-opensource-4.7.2.dmg",
                "md5": "1035ebe5bed72adb371e96897a0c6519",
                "platform": "mac",
                "size": 218761842,
                "size_string": "209M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.7/qt-mac-carbon-opensource-4.7.2.dmg",
                "version": "4.7.2"
            },
            {
                "file_name": "qt-mac-opensource-4.7.2-debug-libs.dmg",
                "md5": "5c8bdd3ff9bb8634431c10c1f24e176f",
                "platform": "mac",
                "size": 754494789,
                "size_string": "720M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.7/qt-mac-opensource-4.7.2-debug-libs.dmg",
                "version": "4.7.2"
            },
            {
                "file_name": "qt-mac-opensource-4.7.2.dmg",
                "md5": "db1d59945443e1fb033a4b87f5618e73",
                "platform": "mac",
                "size": 219285497,
                "size_string": "209M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.7/qt-mac-opensource-4.7.2.dmg",
                "version": "4.7.2"
            },
            {
                "file_name": "qt-mac-carbon-opensource-4.7.2-debug-libs.dmg",
                "md5": "2880e9ff7548384e307161ca449e5c66",
                "platform": "mac",
                "size": 394463887,
                "size_string": "376M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.7/qt-mac-carbon-opensource-4.7.2-debug-libs.dmg",
                "version": "4.7.2"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.7.2"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-4.7.1.zip",
                "md5": "fcf3bd35ec3cb07f42046b04f3b32f39",
                "platform": "unknow",
                "size": 247108360,
                "size_string": "236M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.7/qt-everywhere-opensource-src-4.7.1.zip",
                "version": "4.7.1"
            },
            {
                "file_name": "qt-everywhere-opensource-src-4.7.1.tar.gz",
                "md5": "6f88d96507c84e9fea5bf3a71ebeb6d7",
                "platform": "unknow",
                "size": 211768512,
                "size_string": "202M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.7/qt-everywhere-opensource-src-4.7.1.tar.gz",
                "version": "4.7.1"
            },
            {
                "file_name": "qt-win-opensource-4.7.1-vs2008.exe",
                "md5": "dd1c10f5497dbe04c903f00bae2e869a",
                "platform": "window",
                "size": 238983408,
                "size_string": "228M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.7/qt-win-opensource-4.7.1-vs2008.exe",
                "version": "4.7.1"
            },
            {
                "file_name": "qt-win-opensource-4.7.1-mingw.exe",
                "md5": "2fdcb64bbfe78370c3b0a9e4fc3de026",
                "platform": "window",
                "size": 333551352,
                "size_string": "318M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.7/qt-win-opensource-4.7.1-mingw.exe",
                "version": "4.7.1"
            },
            {
                "file_name": "qt-mac-carbon-opensource-4.7.1-debug-libs.dmg",
                "md5": "133c9f575dae98df04ece39ab58bb986",
                "platform": "mac",
                "size": 442241359,
                "size_string": "422M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.7/qt-mac-carbon-opensource-4.7.1-debug-libs.dmg",
                "version": "4.7.1"
            },
            {
                "file_name": "qt-mac-opensource-4.7.1.dmg",
                "md5": "6786877a5aa4fb28f851f332cd7f1001",
                "platform": "mac",
                "size": 216262018,
                "size_string": "206M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.7/qt-mac-opensource-4.7.1.dmg",
                "version": "4.7.1"
            },
            {
                "file_name": "qt-mac-opensource-4.7.1-debug-libs.dmg",
                "md5": "5e5da656c92629b1dafd8975886cabe7",
                "platform": "mac",
                "size": 734300722,
                "size_string": "700M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.7/qt-mac-opensource-4.7.1-debug-libs.dmg",
                "version": "4.7.1"
            },
            {
                "file_name": "qt-mac-carbon-opensource-4.7.1.dmg",
                "md5": "1d76f961af568429b29b7ef6a1577250",
                "platform": "mac",
                "size": 215784539,
                "size_string": "206M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.7/qt-mac-carbon-opensource-4.7.1.dmg",
                "version": "4.7.1"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.7.1"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-4.7.0.tar.gz",
                "md5": "3a2f25b9b115037277f4fb759194a7a5",
                "platform": "unknow",
                "size": 208320257,
                "size_string": "199M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.7/qt-everywhere-opensource-src-4.7.0.tar.gz",
                "version": "4.7.0"
            },
            {
                "file_name": "qt-everywhere-opensource-src-4.7.0.zip",
                "md5": "ac7ed0d00d68177ed054afebdd77507d",
                "platform": "unknow",
                "size": 242783835,
                "size_string": "232M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.7/qt-everywhere-opensource-src-4.7.0.zip",
                "version": "4.7.0"
            },
            {
                "file_name": "qt-win-opensource-4.7.0-vs2008.exe",
                "md5": "81039cf01bedf8a8ecd0cacb8c3ef12a",
                "platform": "window",
                "size": 236465928,
                "size_string": "226M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.7/qt-win-opensource-4.7.0-vs2008.exe",
                "version": "4.7.0"
            },
            {
                "file_name": "qt-win-opensource-4.7.0-mingw.exe",
                "md5": "cd12ec1f9050d3a5183768ea7f733f1a",
                "platform": "window",
                "size": 330738528,
                "size_string": "315M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.7/qt-win-opensource-4.7.0-mingw.exe",
                "version": "4.7.0"
            },
            {
                "file_name": "qt-mac-opensource-4.7.0.dmg",
                "md5": "655de57835cae1f990d26191e737cfd5",
                "platform": "mac",
                "size": 213659149,
                "size_string": "204M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.7/qt-mac-opensource-4.7.0.dmg",
                "version": "4.7.0"
            },
            {
                "file_name": "qt-mac-carbon-opensource-4.7.0-debug-libs.dmg",
                "md5": "bde0a4b5295c9a3fa6693395dfaaade6",
                "platform": "mac",
                "size": 71186319,
                "size_string": "68M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.7/qt-mac-carbon-opensource-4.7.0-debug-libs.dmg",
                "version": "4.7.0"
            },
            {
                "file_name": "qt-mac-opensource-4.7.0-debug-libs.dmg",
                "md5": "74f2bbe2a3bcb8d3b98f82586e3ea2c7",
                "platform": "mac",
                "size": 731716901,
                "size_string": "698M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.7/qt-mac-opensource-4.7.0-debug-libs.dmg",
                "version": "4.7.0"
            },
            {
                "file_name": "qt-mac-carbon-opensource-4.7.0.dmg",
                "md5": "009d282fb8ac8bf3844726bc017796de",
                "platform": "mac",
                "size": 213170261,
                "size_string": "203M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.7/qt-mac-carbon-opensource-4.7.0.dmg",
                "version": "4.7.0"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.7.0"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-4.6.4.zip",
                "md5": "c5c12c3156464bac99460c2d57d3e971",
                "platform": "unknow",
                "size": 184655292,
                "size_string": "176M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.6/qt-everywhere-opensource-src-4.6.4.zip",
                "version": "4.6.4"
            },
            {
                "file_name": "qt-everywhere-opensource-src-4.6.4.tar.gz",
                "md5": "8ac880cc07a130c39607b65efd5e1421",
                "platform": "unknow",
                "size": 160837056,
                "size_string": "153M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.6/qt-everywhere-opensource-src-4.6.4.tar.gz",
                "version": "4.6.4"
            },
            {
                "file_name": "qt-win-opensource-4.6.4-mingw.exe",
                "md5": "88d1a0afe6eb88f5a711bac0e21d597d",
                "platform": "window",
                "size": 285966407,
                "size_string": "273M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.6/qt-win-opensource-4.6.4-mingw.exe",
                "version": "4.6.4"
            },
            {
                "file_name": "qt-win-opensource-4.6.4-vs2008.exe",
                "md5": "6b92e5a57e6851c47b26f9f12c09833b",
                "platform": "window",
                "size": 194245607,
                "size_string": "185M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.6/qt-win-opensource-4.6.4-vs2008.exe",
                "version": "4.6.4"
            },
            {
                "file_name": "qt-mac-opensource-4.6.4-debug-libs.dmg",
                "md5": "b0dff4ecfadc0b2efa53f9a9115c0bec",
                "platform": "mac",
                "size": 349470664,
                "size_string": "333M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.6/qt-mac-opensource-4.6.4-debug-libs.dmg",
                "version": "4.6.4"
            },
            {
                "file_name": "qt-mac-cocoa-opensource-4.6.4-debug-libs.dmg",
                "md5": "127f25c6133ccd8f28cfabd23fe42b6a",
                "platform": "mac",
                "size": 646682428,
                "size_string": "617M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.6/qt-mac-cocoa-opensource-4.6.4-debug-libs.dmg",
                "version": "4.6.4"
            },
            {
                "file_name": "qt-mac-cocoa-opensource-4.6.4.dmg",
                "md5": "e45b5e5efbf68f574086c797ccab7abe",
                "platform": "mac",
                "size": 173695498,
                "size_string": "166M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.6/qt-mac-cocoa-opensource-4.6.4.dmg",
                "version": "4.6.4"
            },
            {
                "file_name": "qt-mac-opensource-4.6.4.dmg",
                "md5": "29f124098ea610ad2640ec573a9b38b5",
                "platform": "mac",
                "size": 171123452,
                "size_string": "163M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.6/qt-mac-opensource-4.6.4.dmg",
                "version": "4.6.4"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.6.4"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-4.6.3.zip",
                "md5": "b5bf391b4d264bebab7ea6b9ae655d77",
                "platform": "unknow",
                "size": 184186289,
                "size_string": "176M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.6/qt-everywhere-opensource-src-4.6.3.zip",
                "version": "4.6.3"
            },
            {
                "file_name": "qt-everywhere-opensource-src-4.6.3.tar.gz",
                "md5": "5c69f16d452b0bb3d44bc3c10556c072",
                "platform": "unknow",
                "size": 160993454,
                "size_string": "154M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.6/qt-everywhere-opensource-src-4.6.3.tar.gz",
                "version": "4.6.3"
            },
            {
                "file_name": "qt-assistant-qassistantclient-library-compat-src-4.6.3.tar.gz",
                "md5": "a20148e0488d5c12ab35ccc107dcc64d",
                "platform": "unknow",
                "size": 329896,
                "size_string": "322K",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.6/qt-assistant-qassistantclient-library-compat-src-4.6.3.tar.gz",
                "version": "4.6.3"
            },
            {
                "file_name": "qt-win-opensource-4.6.3-mingw.exe",
                "md5": "5290e564b9e17fd021a9cae03f4db574",
                "platform": "window",
                "size": 279991752,
                "size_string": "267M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.6/qt-win-opensource-4.6.3-mingw.exe",
                "version": "4.6.3"
            },
            {
                "file_name": "qt-win-opensource-4.6.3-vs2008.exe",
                "md5": "a25aa898347ba4d2de68b4da7481dcf4",
                "platform": "window",
                "size": 194074952,
                "size_string": "185M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.6/qt-win-opensource-4.6.3-vs2008.exe",
                "version": "4.6.3"
            },
            {
                "file_name": "qt-mac-opensource-4.6.3.dmg",
                "md5": "b24e986a96e238bf4e589c4be970646d",
                "platform": "mac",
                "size": 170711854,
                "size_string": "163M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.6/qt-mac-opensource-4.6.3.dmg",
                "version": "4.6.3"
            },
            {
                "file_name": "qt-mac-opensource-4.6.3-debug-libs.dmg",
                "md5": "38c7a920e800de4507565d84d6c5e62e",
                "platform": "mac",
                "size": 340547016,
                "size_string": "325M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.6/qt-mac-opensource-4.6.3-debug-libs.dmg",
                "version": "4.6.3"
            },
            {
                "file_name": "qt-mac-cocoa-opensource-4.6.3.dmg",
                "md5": "eee07b20eca7f099b2041f11a8e1cb67",
                "platform": "mac",
                "size": 172679948,
                "size_string": "165M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.6/qt-mac-cocoa-opensource-4.6.3.dmg",
                "version": "4.6.3"
            },
            {
                "file_name": "qt-mac-cocoa-opensource-4.6.3-debug-libs.dmg",
                "md5": "ef8e7f33ad8c01baa744eedbe4bd8a2a",
                "platform": "mac",
                "size": 544933866,
                "size_string": "520M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.6/qt-mac-cocoa-opensource-4.6.3-debug-libs.dmg",
                "version": "4.6.3"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.6.3"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-4.6.2.zip",
                "md5": "e12b6d9bb1fac3c0c03349cddf886369",
                "platform": "unknow",
                "size": 183675054,
                "size_string": "175M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.6/qt-everywhere-opensource-src-4.6.2.zip",
                "version": "4.6.2"
            },
            {
                "file_name": "qt-everywhere-opensource-src-4.6.2.tar.gz",
                "md5": "eb651ee4b157c01696aa56777fc6e0e5",
                "platform": "unknow",
                "size": 160601949,
                "size_string": "153M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.6/qt-everywhere-opensource-src-4.6.2.tar.gz",
                "version": "4.6.2"
            },
            {
                "file_name": "qt-win-opensource-4.6.2-mingw.exe",
                "md5": "509002417a6d27438a15c6805a76f8ae",
                "platform": "window",
                "size": 278855712,
                "size_string": "266M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.6/qt-win-opensource-4.6.2-mingw.exe",
                "version": "4.6.2"
            },
            {
                "file_name": "qt-win-opensource-4.6.2-vs2008.exe",
                "md5": "61bb84258f1d88ed3b35e0629ffb28a0",
                "platform": "window",
                "size": 193704208,
                "size_string": "185M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.6/qt-win-opensource-4.6.2-vs2008.exe",
                "version": "4.6.2"
            },
            {
                "file_name": "qt-mac-opensource-4.6.2.dmg",
                "md5": "37d5959dc9cb6ed5e99c750e28903ef8",
                "platform": "mac",
                "size": 170203056,
                "size_string": "162M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.6/qt-mac-opensource-4.6.2.dmg",
                "version": "4.6.2"
            },
            {
                "file_name": "qt-mac-cocoa-opensource-4.6.2-debug-libs.dmg",
                "md5": "e0f54dcd99bc0a7e8f9eef86adbd9f41",
                "platform": "mac",
                "size": 544345334,
                "size_string": "519M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.6/qt-mac-cocoa-opensource-4.6.2-debug-libs.dmg",
                "version": "4.6.2"
            },
            {
                "file_name": "qt-mac-opensource-4.6.2-debug-libs.dmg",
                "md5": "6134300adf934c2966e4815669781bbc",
                "platform": "mac",
                "size": 340270405,
                "size_string": "325M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.6/qt-mac-opensource-4.6.2-debug-libs.dmg",
                "version": "4.6.2"
            },
            {
                "file_name": "qt-mac-cocoa-opensource-4.6.2.dmg",
                "md5": "b7a4bd30c7d8802768c91c694f3a8160",
                "platform": "mac",
                "size": 172101634,
                "size_string": "164M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.6/qt-mac-cocoa-opensource-4.6.2.dmg",
                "version": "4.6.2"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.6.2"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-4.6.1.zip",
                "md5": "ce2137165a1d778c041d915ecbb13056",
                "platform": "unknow",
                "size": 183176848,
                "size_string": "175M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.6/qt-everywhere-opensource-src-4.6.1.zip",
                "version": "4.6.1"
            },
            {
                "file_name": "qt-everywhere-opensource-src-4.6.1.tar.gz",
                "md5": "0542a4be6425451ab5f668c6899cac36",
                "platform": "unknow",
                "size": 160197277,
                "size_string": "153M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.6/qt-everywhere-opensource-src-4.6.1.tar.gz",
                "version": "4.6.1"
            },
            {
                "file_name": "qt-win-opensource-4.6.1-vs2008.exe",
                "md5": "22a5fc7cc2ca1107c4ad155281f38ded",
                "platform": "window",
                "size": 193543280,
                "size_string": "185M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.6/qt-win-opensource-4.6.1-vs2008.exe",
                "version": "4.6.1"
            },
            {
                "file_name": "qt-win-opensource-4.6.1-mingw.exe",
                "md5": "bcb06a3d5f0d26a937a558d805d9cc6c",
                "platform": "window",
                "size": 277533064,
                "size_string": "265M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.6/qt-win-opensource-4.6.1-mingw.exe",
                "version": "4.6.1"
            },
            {
                "file_name": "qt-mac-cocoa-opensource-4.6.1-debug-libs.dmg",
                "md5": "806d0647d5f878f3b01cf92378599287",
                "platform": "mac",
                "size": 540027225,
                "size_string": "515M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.6/qt-mac-cocoa-opensource-4.6.1-debug-libs.dmg",
                "version": "4.6.1"
            },
            {
                "file_name": "qt-mac-opensource-4.6.1.dmg",
                "md5": "b0f61882a9f26765f3dc37fd385a43ad",
                "platform": "mac",
                "size": 168995057,
                "size_string": "161M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.6/qt-mac-opensource-4.6.1.dmg",
                "version": "4.6.1"
            },
            {
                "file_name": "qt-mac-cocoa-opensource-4.6.1.dmg",
                "md5": "00f0a3aa61edea54eab4c6df0a8b7bcd",
                "platform": "mac",
                "size": 170887246,
                "size_string": "163M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.6/qt-mac-cocoa-opensource-4.6.1.dmg",
                "version": "4.6.1"
            },
            {
                "file_name": "qt-mac-opensource-4.6.1-debug-libs.dmg",
                "md5": "a6be17d22c72c251773db4e8fe895c9c",
                "platform": "mac",
                "size": 337604593,
                "size_string": "322M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.6/qt-mac-opensource-4.6.1-debug-libs.dmg",
                "version": "4.6.1"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.6.1"
    },
    {
        "data": [
            {
                "file_name": "qt-everywhere-opensource-src-4.6.0.tar.gz",
                "md5": "2a7b5126f2450d8525af355fc4c12ad6",
                "platform": "unknow",
                "size": 155679111,
                "size_string": "148M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.6/qt-everywhere-opensource-src-4.6.0.tar.gz",
                "version": "4.6.0"
            },
            {
                "file_name": "qt-everywhere-opensource-src-4.6.0.zip",
                "md5": "b288d51277e7840bba7d69af62e74e6f",
                "platform": "unknow",
                "size": 178185702,
                "size_string": "170M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.6/qt-everywhere-opensource-src-4.6.0.zip",
                "version": "4.6.0"
            },
            {
                "file_name": "qt-win-opensource-4.6.0-vs2008.exe",
                "md5": "446b6b84ad82c2a677279573c0670a7a",
                "platform": "window",
                "size": 189843200,
                "size_string": "181M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.6/qt-win-opensource-4.6.0-vs2008.exe",
                "version": "4.6.0"
            },
            {
                "file_name": "qt-win-opensource-4.6.0-mingw.exe",
                "md5": "6210a4e65127710559777d6bfb9166a4",
                "platform": "window",
                "size": 273100488,
                "size_string": "260M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.6/qt-win-opensource-4.6.0-mingw.exe",
                "version": "4.6.0"
            },
            {
                "file_name": "qt-mac-cocoa-opensource-4.6.0-debug-libs.dmg",
                "md5": "71e9dd8781c1df7a960bc9f630758b70",
                "platform": "mac",
                "size": 542046302,
                "size_string": "517M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.6/qt-mac-cocoa-opensource-4.6.0-debug-libs.dmg",
                "version": "4.6.0"
            },
            {
                "file_name": "qt-mac-opensource-4.6.0.dmg",
                "md5": "1007bb0536e830bb50ddc892f673cde1",
                "platform": "mac",
                "size": 163939677,
                "size_string": "156M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.6/qt-mac-opensource-4.6.0.dmg",
                "version": "4.6.0"
            },
            {
                "file_name": "qt-mac-opensource-4.6.0-debug-libs.dmg",
                "md5": "bd6e314f8d7f55d1bb6a3a0f5c8b1b4d",
                "platform": "mac",
                "size": 338588728,
                "size_string": "323M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.6/qt-mac-opensource-4.6.0-debug-libs.dmg",
                "version": "4.6.0"
            },
            {
                "file_name": "qt-mac-cocoa-opensource-4.6.0.dmg",
                "md5": "3abb434060c6c4b62040b0ed04c8557a",
                "platform": "mac",
                "size": 165664528,
                "size_string": "158M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.6/qt-mac-cocoa-opensource-4.6.0.dmg",
                "version": "4.6.0"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.6.0"
    },
    {
        "data": [
            {
                "file_name": "qt-mac-opensource-src-4.5.3.tar.gz",
                "md5": "484e3739fdc51540218ed92f4b732881",
                "platform": "unknow",
                "size": 123311631,
                "size_string": "118M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-mac-opensource-src-4.5.3.tar.gz",
                "version": "4.5.3"
            },
            {
                "file_name": "qt-embedded-wince-opensource-src-4.5.3.zip",
                "md5": "a8b6a7bff66a11f2a4b4d9f49eae26b0",
                "platform": "unknow",
                "size": 145276308,
                "size_string": "139M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-embedded-wince-opensource-src-4.5.3.zip",
                "version": "4.5.3"
            },
            {
                "file_name": "qt-embedded-linux-opensource-src-4.5.3.tar.gz",
                "md5": "47d467cb63b01e7b469b5bd43722ff0f",
                "platform": "unknow",
                "size": 129332484,
                "size_string": "123M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-embedded-linux-opensource-src-4.5.3.tar.gz",
                "version": "4.5.3"
            },
            {
                "file_name": "qt-all-opensource-src-4.5.3.zip",
                "md5": "b110167a18a0133955859ac30dd37082",
                "platform": "unknow",
                "size": 148529273,
                "size_string": "142M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-all-opensource-src-4.5.3.zip",
                "version": "4.5.3"
            },
            {
                "file_name": "qt-all-opensource-src-4.5.3.tar.gz",
                "md5": "94feadac537c93eca7a382f0aab8d782",
                "platform": "unknow",
                "size": 127476103,
                "size_string": "122M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-all-opensource-src-4.5.3.tar.gz",
                "version": "4.5.3"
            },
            {
                "file_name": "qt-x11-opensource-src-4.5.3.tar.gz",
                "md5": "3988cf9af68be2df8a8000ede231de9b",
                "platform": "unknow",
                "size": 125384668,
                "size_string": "120M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-x11-opensource-src-4.5.3.tar.gz",
                "version": "4.5.3"
            },
            {
                "file_name": "qt-win-opensource-src-4.5.3.zip",
                "md5": "81ca235e0da2728f948ca36236f9ee45",
                "platform": "unknow",
                "size": 144163715,
                "size_string": "137M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-win-opensource-src-4.5.3.zip",
                "version": "4.5.3"
            },
            {
                "file_name": "qt-win-opensource-4.5.3-mingw.exe",
                "md5": "6097a8bab923de5959a87e3457779a62",
                "platform": "window",
                "size": 173493199,
                "size_string": "165M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.5/qt-win-opensource-4.5.3-mingw.exe",
                "version": "4.5.3"
            },
            {
                "file_name": "qt-mac-opensource-4.5.3.dmg",
                "md5": "b84a4317a7b50a1f206bd57039af4788",
                "platform": "mac",
                "size": 138170903,
                "size_string": "132M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.5/qt-mac-opensource-4.5.3.dmg",
                "version": "4.5.3"
            },
            {
                "file_name": "qt-mac-opensource-4.5.3-debug-libs.dmg",
                "md5": "65718faa6c781e353314e5d610a9bf96",
                "platform": "mac",
                "size": 283019380,
                "size_string": "270M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.5/qt-mac-opensource-4.5.3-debug-libs.dmg",
                "version": "4.5.3"
            },
            {
                "file_name": "qt-mac-cocoa-opensource-4.5.3.dmg",
                "md5": "1e4553c2da59c95882ff32de8a61b397",
                "platform": "mac",
                "size": 193540165,
                "size_string": "185M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.5/qt-mac-cocoa-opensource-4.5.3.dmg",
                "version": "4.5.3"
            },
            {
                "file_name": "qt-mac-cocoa-opensource-4.5.3-debug-libs.dmg",
                "md5": "e66e58b3ad7b428167a0932f4794a57d",
                "platform": "mac",
                "size": 520408062,
                "size_string": "496M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.5/qt-mac-cocoa-opensource-4.5.3-debug-libs.dmg",
                "version": "4.5.3"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.5.3"
    },
    {
        "data": [
            {
                "file_name": "qt-embedded-linux-opensource-src-4.5.2.tar.bz2",
                "md5": "62186345c609a72b89f16d83bc7a130f",
                "platform": "unknow",
                "size": 119183201,
                "size_string": "114M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-embedded-linux-opensource-src-4.5.2.tar.bz2",
                "version": "4.5.2"
            },
            {
                "file_name": "qt-all-opensource-src-4.5.2.zip",
                "md5": "3bbc0541d4f908afd9979b0ee16cd65e",
                "platform": "unknow",
                "size": 148643359,
                "size_string": "142M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-all-opensource-src-4.5.2.zip",
                "version": "4.5.2"
            },
            {
                "file_name": "qt-all-opensource-src-4.5.2.tar.gz",
                "md5": "4529793618866613e99d15ee94463045",
                "platform": "unknow",
                "size": 127895546,
                "size_string": "122M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-all-opensource-src-4.5.2.tar.gz",
                "version": "4.5.2"
            },
            {
                "file_name": "qt-x11-opensource-src-4.5.2.tar.gz",
                "md5": "d8bcc070a58db25c228b7729ffad4550",
                "platform": "unknow",
                "size": 125803421,
                "size_string": "120M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-x11-opensource-src-4.5.2.tar.gz",
                "version": "4.5.2"
            },
            {
                "file_name": "qt-mac-opensource-src-4.5.2.tar.gz",
                "md5": "c549d6c0c2e0723377cb955c78a1b680",
                "platform": "unknow",
                "size": 123719193,
                "size_string": "118M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-mac-opensource-src-4.5.2.tar.gz",
                "version": "4.5.2"
            },
            {
                "file_name": "qt-mac-opensource-src-4.5.2.tar.bz2",
                "md5": "68eb274a91ef537c92a954f535704f1d",
                "platform": "unknow",
                "size": 113874121,
                "size_string": "109M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-mac-opensource-src-4.5.2.tar.bz2",
                "version": "4.5.2"
            },
            {
                "file_name": "qt-embedded-wince-opensource-src-4.5.2.zip",
                "md5": "7c97e5e6dd9723dce57ced58bfdf5ef1",
                "platform": "unknow",
                "size": 145430702,
                "size_string": "139M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-embedded-wince-opensource-src-4.5.2.zip",
                "version": "4.5.2"
            },
            {
                "file_name": "qt-win-opensource-src-4.5.2.zip",
                "md5": "05d3d3448218acc72bd7a5bbfae9312a",
                "platform": "unknow",
                "size": 144313325,
                "size_string": "138M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-win-opensource-src-4.5.2.zip",
                "version": "4.5.2"
            },
            {
                "file_name": "qt-x11-opensource-src-4.5.2.tar.bz2",
                "md5": "28a7e8ac9805a6f614d2a27ee1a6ac9d",
                "platform": "unknow",
                "size": 116015284,
                "size_string": "111M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-x11-opensource-src-4.5.2.tar.bz2",
                "version": "4.5.2"
            },
            {
                "file_name": "qt-embedded-linux-opensource-src-4.5.2.tar.gz",
                "md5": "083903f34a0c345a05931b43fe4aa7f0",
                "platform": "unknow",
                "size": 129744190,
                "size_string": "124M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-embedded-linux-opensource-src-4.5.2.tar.gz",
                "version": "4.5.2"
            },
            {
                "file_name": "qt-all-opensource-src-4.5.2.tar.bz2",
                "md5": "49937eea133afefe9f6a7ef4371fe413",
                "platform": "unknow",
                "size": 117882167,
                "size_string": "112M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-all-opensource-src-4.5.2.tar.bz2",
                "version": "4.5.2"
            },
            {
                "file_name": "qt-win-opensource-4.5.2-mingw.exe",
                "md5": "d0758cb05f7b2eee340cb2659fd71896",
                "platform": "window",
                "size": 173584490,
                "size_string": "166M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.5/qt-win-opensource-4.5.2-mingw.exe",
                "version": "4.5.2"
            },
            {
                "file_name": "qt-mac-opensource-4.5.2.dmg",
                "md5": "caa534d3646ad1a2c436a8bf73fd0ed2",
                "platform": "mac",
                "size": 138743093,
                "size_string": "132M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.5/qt-mac-opensource-4.5.2.dmg",
                "version": "4.5.2"
            },
            {
                "file_name": "qt-mac-opensource-4.5.2-debug-libs.dmg",
                "md5": "06adbdd2afb8c02fb3b94e278c37da49",
                "platform": "mac",
                "size": 276407551,
                "size_string": "264M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.5/qt-mac-opensource-4.5.2-debug-libs.dmg",
                "version": "4.5.2"
            },
            {
                "file_name": "qt-mac-cocoa-opensource-4.5.2.dmg",
                "md5": "29ec5619f1815eebdda2217dfaafd803",
                "platform": "mac",
                "size": 194146898,
                "size_string": "185M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.5/qt-mac-cocoa-opensource-4.5.2.dmg",
                "version": "4.5.2"
            },
            {
                "file_name": "qt-mac-cocoa-opensource-4.5.2-debug-libs.dmg",
                "md5": "f2dd8a784c80e7c2348e5c2dec23a361",
                "platform": "mac",
                "size": 508022356,
                "size_string": "484M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.5/qt-mac-cocoa-opensource-4.5.2-debug-libs.dmg",
                "version": "4.5.2"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.5.2"
    },
    {
        "data": [
            {
                "file_name": "qt-embedded-wince-opensource-src-4.5.1.zip",
                "md5": "6a05b1458e7abb61d3cca456a7e1f891",
                "platform": "unknow",
                "size": 144085058,
                "size_string": "137M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-embedded-wince-opensource-src-4.5.1.zip",
                "version": "4.5.1"
            },
            {
                "file_name": "qt-embedded-linux-opensource-src-4.5.1.tar.gz",
                "md5": "2ae7916a5903b714131f471d14b2da33",
                "platform": "unknow",
                "size": 128448600,
                "size_string": "122M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-embedded-linux-opensource-src-4.5.1.tar.gz",
                "version": "4.5.1"
            },
            {
                "file_name": "qt-embedded-linux-opensource-src-4.5.1.tar.bz2",
                "md5": "43e7384c59625af0830032348f672a1d",
                "platform": "unknow",
                "size": 117692187,
                "size_string": "112M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-embedded-linux-opensource-src-4.5.1.tar.bz2",
                "version": "4.5.1"
            },
            {
                "file_name": "qt-win-opensource-src-4.5.1.zip",
                "md5": "ef2934257c893b7694437cc8d1e37bd9",
                "platform": "unknow",
                "size": 142969637,
                "size_string": "136M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-win-opensource-src-4.5.1.zip",
                "version": "4.5.1"
            },
            {
                "file_name": "qt-all-opensource-src-4.5.1.zip",
                "md5": "bfe6ab4788ab40d4421ac06ded1187a0",
                "platform": "unknow",
                "size": 147301732,
                "size_string": "140M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-all-opensource-src-4.5.1.zip",
                "version": "4.5.1"
            },
            {
                "file_name": "qt-all-opensource-src-4.5.1.tar.gz",
                "md5": "61ea0ead6ca4fde84605531b095c04c9",
                "platform": "unknow",
                "size": 126594253,
                "size_string": "121M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-all-opensource-src-4.5.1.tar.gz",
                "version": "4.5.1"
            },
            {
                "file_name": "qt-all-opensource-src-4.5.1.tar.bz2",
                "md5": "eeb16b899c261762f16cedcaa38ad878",
                "platform": "unknow",
                "size": 116518775,
                "size_string": "111M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-all-opensource-src-4.5.1.tar.bz2",
                "version": "4.5.1"
            },
            {
                "file_name": "qt-mac-opensource-src-4.5.1.tar.gz",
                "md5": "9fc0e96197df6db48a0628ac4d63e0dd",
                "platform": "unknow",
                "size": 122425979,
                "size_string": "117M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-mac-opensource-src-4.5.1.tar.gz",
                "version": "4.5.1"
            },
            {
                "file_name": "qt-mac-opensource-src-4.5.1.tar.bz2",
                "md5": "e372299a36975f6faa1931273268e1ea",
                "platform": "unknow",
                "size": 112558395,
                "size_string": "107M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-mac-opensource-src-4.5.1.tar.bz2",
                "version": "4.5.1"
            },
            {
                "file_name": "qt-x11-opensource-src-4.5.1.tar.gz",
                "md5": "f81a94e2ab7713b2d375d4e5cfc8e051",
                "platform": "unknow",
                "size": 124513736,
                "size_string": "119M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-x11-opensource-src-4.5.1.tar.gz",
                "version": "4.5.1"
            },
            {
                "file_name": "qt-x11-opensource-src-4.5.1.tar.bz2",
                "md5": "afc43e566341cf3e5ed0bcb974f0c3b2",
                "platform": "unknow",
                "size": 114667436,
                "size_string": "109M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-x11-opensource-src-4.5.1.tar.bz2",
                "version": "4.5.1"
            },
            {
                "file_name": "qt-win-opensource-4.5.1-mingw.exe",
                "md5": "06167df97787ea04a40151b9c34e5083",
                "platform": "window",
                "size": 167822380,
                "size_string": "160M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.5/qt-win-opensource-4.5.1-mingw.exe",
                "version": "4.5.1"
            },
            {
                "file_name": "qt-mac-cocoa-opensource-4.5.1.dmg",
                "md5": "132fa50c7a2879ee25b1704a2e4c9cb9",
                "platform": "mac",
                "size": 194861119,
                "size_string": "186M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.5/qt-mac-cocoa-opensource-4.5.1.dmg",
                "version": "4.5.1"
            },
            {
                "file_name": "qt-mac-cocoa-opensource-4.5.1-debug-libs.dmg",
                "md5": "16cc176366d51e06ebc7d98bcb8351fe",
                "platform": "mac",
                "size": 505086502,
                "size_string": "482M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.5/qt-mac-cocoa-opensource-4.5.1-debug-libs.dmg",
                "version": "4.5.1"
            },
            {
                "file_name": "qt-mac-opensource-4.5.1.dmg",
                "md5": "6c58f1fe3cd7fc951faf1df03d859f46",
                "platform": "mac",
                "size": 137795497,
                "size_string": "131M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.5/qt-mac-opensource-4.5.1.dmg",
                "version": "4.5.1"
            },
            {
                "file_name": "qt-mac-opensource-4.5.1-debug-libs.dmg",
                "md5": "ed6ae67f9f9fb39ba78e47642150f8e2",
                "platform": "mac",
                "size": 272301242,
                "size_string": "260M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.5/qt-mac-opensource-4.5.1-debug-libs.dmg",
                "version": "4.5.1"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.5.1"
    },
    {
        "data": [
            {
                "file_name": "qt-x11-opensource-src-4.5.0.tar.gz",
                "md5": "d6d1555b7a074e0a746f6247af402e11",
                "platform": "unknow",
                "size": 123179308,
                "size_string": "117M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-x11-opensource-src-4.5.0.tar.gz",
                "version": "4.5.0"
            },
            {
                "file_name": "qt-x11-opensource-src-4.5.0.tar.bz2",
                "md5": "55e8c74a89e1bc128a5a6f1ff6b8b5ba",
                "platform": "unknow",
                "size": 113335010,
                "size_string": "108M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-x11-opensource-src-4.5.0.tar.bz2",
                "version": "4.5.0"
            },
            {
                "file_name": "qt-win-opensource-src-4.5.0.zip",
                "md5": "172c0157a4a53c362f780066ead9f88a",
                "platform": "unknow",
                "size": 141787627,
                "size_string": "135M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-win-opensource-src-4.5.0.zip",
                "version": "4.5.0"
            },
            {
                "file_name": "qt-embedded-linux-opensource-src-4.5.0.tar.bz2",
                "md5": "3bd081c940c8580ecaabc1bd898e48f0",
                "platform": "unknow",
                "size": 116331666,
                "size_string": "111M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-embedded-linux-opensource-src-4.5.0.tar.bz2",
                "version": "4.5.0"
            },
            {
                "file_name": "qt-mac-opensource-src-4.5.0.tar.bz2",
                "md5": "841694db7b700835f4644f9bab367414",
                "platform": "unknow",
                "size": 111184478,
                "size_string": "106M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-mac-opensource-src-4.5.0.tar.bz2",
                "version": "4.5.0"
            },
            {
                "file_name": "qt-mac-opensource-src-4.5.0.tar.gz",
                "md5": "c66a3f3211529b456086284f87f17393",
                "platform": "unknow",
                "size": 121098057,
                "size_string": "115M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-mac-opensource-src-4.5.0.tar.gz",
                "version": "4.5.0"
            },
            {
                "file_name": "qt-embedded-wince-opensource-src-4.5.0.zip",
                "md5": "1041238fafd435a9f0f9d7aed79d5abe",
                "platform": "unknow",
                "size": 142905317,
                "size_string": "136M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-embedded-wince-opensource-src-4.5.0.zip",
                "version": "4.5.0"
            },
            {
                "file_name": "qt-embedded-linux-opensource-src-4.5.0.tar.gz",
                "md5": "53a41132e4276275583876a352d29ec7",
                "platform": "unknow",
                "size": 127112386,
                "size_string": "121M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-embedded-linux-opensource-src-4.5.0.tar.gz",
                "version": "4.5.0"
            },
            {
                "file_name": "qt-all-opensource-src-4.5.0.zip",
                "md5": "3a0dc8a25ee836a2781cbc1de50039c1",
                "platform": "unknow",
                "size": 146108270,
                "size_string": "139M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-all-opensource-src-4.5.0.zip",
                "version": "4.5.0"
            },
            {
                "file_name": "qt-all-opensource-src-4.5.0.tar.gz",
                "md5": "ffbb1aaea2d538df7ec7694cd68750df",
                "platform": "unknow",
                "size": 125349021,
                "size_string": "120M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-all-opensource-src-4.5.0.tar.gz",
                "version": "4.5.0"
            },
            {
                "file_name": "qt-all-opensource-src-4.5.0.tar.bz2",
                "md5": "d947f59718ab66f720a34cd1a9efa7d6",
                "platform": "unknow",
                "size": 115213434,
                "size_string": "110M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.5/qt-all-opensource-src-4.5.0.tar.bz2",
                "version": "4.5.0"
            },
            {
                "file_name": "qt-win-opensource-4.5.0-mingw.exe",
                "md5": "4740cd2828027d6ca24a8f3a51e40e6a",
                "platform": "window",
                "size": 166825473,
                "size_string": "159M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.5/qt-win-opensource-4.5.0-mingw.exe",
                "version": "4.5.0"
            },
            {
                "file_name": "qt-mac-opensource-4.5.0.dmg",
                "md5": "eb333beb3af02d2a4d3203c7acac0261",
                "platform": "mac",
                "size": 136307179,
                "size_string": "130M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.5/qt-mac-opensource-4.5.0.dmg",
                "version": "4.5.0"
            },
            {
                "file_name": "qt-mac-opensource-4.5.0-debug-libs.dmg",
                "md5": "40e52a5dd6aaf36fccf176202b4404ce",
                "platform": "mac",
                "size": 272048448,
                "size_string": "259M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.5/qt-mac-opensource-4.5.0-debug-libs.dmg",
                "version": "4.5.0"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.5.0"
    },
    {
        "data": [
            {
                "file_name": "qt-embedded-wince-opensource-src-4.4.3.zip",
                "md5": "bd1a9808765e497cfe2eeb43c80e2de8",
                "platform": "unknow",
                "size": 129273763,
                "size_string": "123M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-embedded-wince-opensource-src-4.4.3.zip",
                "version": "4.4.3"
            },
            {
                "file_name": "qt-embedded-linux-opensource-src-4.4.3.tar.bz2",
                "md5": "9a639aec44a1e4c70040117183d247a3",
                "platform": "unknow",
                "size": 113431347,
                "size_string": "108M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-embedded-linux-opensource-src-4.4.3.tar.bz2",
                "version": "4.4.3"
            },
            {
                "file_name": "qt-mac-opensource-src-4.4.3.tar.bz2",
                "md5": "7c449562b89399da6fdb4cc3a3680f0b",
                "platform": "unknow",
                "size": 101627662,
                "size_string": "97M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-mac-opensource-src-4.4.3.tar.bz2",
                "version": "4.4.3"
            },
            {
                "file_name": "qt-all-opensource-src-4.4.3.tar.gz",
                "md5": "6e724637461d6bfc60a02daef8571c00",
                "platform": "unknow",
                "size": 114421877,
                "size_string": "109M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-all-opensource-src-4.4.3.tar.gz",
                "version": "4.4.3"
            },
            {
                "file_name": "qt-all-opensource-src-4.4.3.tar.bz2",
                "md5": "4f40c0f27589858c33a01b2e95307797",
                "platform": "unknow",
                "size": 105240344,
                "size_string": "100M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-all-opensource-src-4.4.3.tar.bz2",
                "version": "4.4.3"
            },
            {
                "file_name": "qt-x11-opensource-src-4.4.3.tar.bz2",
                "md5": "00e00c6324d342a7b0d8653112b4f08c",
                "platform": "unknow",
                "size": 103961247,
                "size_string": "99M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-x11-opensource-src-4.4.3.tar.bz2",
                "version": "4.4.3"
            },
            {
                "file_name": "qt-win-opensource-src-4.4.3.zip",
                "md5": "c6db318da026877bc490e5c7caa1d088",
                "platform": "unknow",
                "size": 128647880,
                "size_string": "123M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-win-opensource-src-4.4.3.zip",
                "version": "4.4.3"
            },
            {
                "file_name": "qt-mac-opensource-src-4.4.3.tar.gz",
                "md5": "766c02e23d2de1b19c9a25a68297b04e",
                "platform": "unknow",
                "size": 110706745,
                "size_string": "106M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-mac-opensource-src-4.4.3.tar.gz",
                "version": "4.4.3"
            },
            {
                "file_name": "qt-embedded-linux-opensource-src-4.4.3.tar.gz",
                "md5": "ff673d264dd5c8a5ebef0a86fb585540",
                "platform": "unknow",
                "size": 124238957,
                "size_string": "118M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-embedded-linux-opensource-src-4.4.3.tar.gz",
                "version": "4.4.3"
            },
            {
                "file_name": "qt-all-opensource-src-4.4.3.zip",
                "md5": "dc0c325cca2adb51fa9ec4667c242b27",
                "platform": "unknow",
                "size": 132758983,
                "size_string": "127M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-all-opensource-src-4.4.3.zip",
                "version": "4.4.3"
            },
            {
                "file_name": "qt-x11-opensource-src-4.4.3.tar.gz",
                "md5": "376c003317c4417326ba2116370227d0",
                "platform": "unknow",
                "size": 112939803,
                "size_string": "108M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-x11-opensource-src-4.4.3.tar.gz",
                "version": "4.4.3"
            },
            {
                "file_name": "qt-win-opensource-4.4.3-mingw.exe",
                "md5": "6947a219f4f0ea5b9c143cd0512ffe2e",
                "platform": "window",
                "size": 160007561,
                "size_string": "153M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.4/qt-win-opensource-4.4.3-mingw.exe",
                "version": "4.4.3"
            },
            {
                "file_name": "qt-mac-opensource-4.4.3-debug-libs.dmg",
                "md5": "60f0622f06f55a6f053125698ed4ffb8",
                "platform": "mac",
                "size": 200216877,
                "size_string": "191M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.4/qt-mac-opensource-4.4.3-debug-libs.dmg",
                "version": "4.4.3"
            },
            {
                "file_name": "qt-mac-opensource-4.4.3.dmg",
                "md5": "d75fc12844a3ea3fcd5a22266f3068ba",
                "platform": "mac",
                "size": 122285626,
                "size_string": "117M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.4/qt-mac-opensource-4.4.3.dmg",
                "version": "4.4.3"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.4.3"
    },
    {
        "data": [
            {
                "file_name": "qt-embedded-wince-opensource-src-4.4.2.zip",
                "md5": "141e6df0743adce3bdcd4114909901ce",
                "platform": "unknow",
                "size": 128423932,
                "size_string": "122M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-embedded-wince-opensource-src-4.4.2.zip",
                "version": "4.4.2"
            },
            {
                "file_name": "qt-embedded-linux-opensource-src-4.4.2.tar.bz2",
                "md5": "11633ad4d2888a64e5ec93aa02ad0381",
                "platform": "unknow",
                "size": 111786833,
                "size_string": "107M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-embedded-linux-opensource-src-4.4.2.tar.bz2",
                "version": "4.4.2"
            },
            {
                "file_name": "qt-all-opensource-src-4.4.2.zip",
                "md5": "c2f0386930b435a0ff4a6d7972f5304c",
                "platform": "unknow",
                "size": 132579766,
                "size_string": "126M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-all-opensource-src-4.4.2.zip",
                "version": "4.4.2"
            },
            {
                "file_name": "qt-all-opensource-src-4.4.2.tar.gz",
                "md5": "d07335586056f344590ba88cfd820e51",
                "platform": "unknow",
                "size": 113668703,
                "size_string": "108M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-all-opensource-src-4.4.2.tar.gz",
                "version": "4.4.2"
            },
            {
                "file_name": "qt-embedded-linux-opensource-src-4.4.2.tar.gz",
                "md5": "41669f7540fbfa469cc3f59cb88583e4",
                "platform": "unknow",
                "size": 123350954,
                "size_string": "118M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-embedded-linux-opensource-src-4.4.2.tar.gz",
                "version": "4.4.2"
            },
            {
                "file_name": "qt-all-opensource-src-4.4.2.tar.bz2",
                "md5": "fd4f376193b131aacb0d88cad2d2ede5",
                "platform": "unknow",
                "size": 104102690,
                "size_string": "99M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-all-opensource-src-4.4.2.tar.bz2",
                "version": "4.4.2"
            },
            {
                "file_name": "qt-x11-opensource-src-4.4.2.tar.bz2",
                "md5": "200faca22f861cd09f108df9924f195c",
                "platform": "unknow",
                "size": 102816706,
                "size_string": "98M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-x11-opensource-src-4.4.2.tar.bz2",
                "version": "4.4.2"
            },
            {
                "file_name": "qt-mac-opensource-src-4.4.2.tar.bz2",
                "md5": "5cd6f3aee2b54f1b907f14a7168ae9c3",
                "platform": "unknow",
                "size": 100715282,
                "size_string": "96M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-mac-opensource-src-4.4.2.tar.bz2",
                "version": "4.4.2"
            },
            {
                "file_name": "qt-mac-opensource-src-4.4.2.tar.gz",
                "md5": "2dfe193fa739597ada25662a42308800",
                "platform": "unknow",
                "size": 110051187,
                "size_string": "105M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-mac-opensource-src-4.4.2.tar.gz",
                "version": "4.4.2"
            },
            {
                "file_name": "qt-win-opensource-src-4.4.2.zip",
                "md5": "2ec170884755738e5a4262260a7eeac7",
                "platform": "unknow",
                "size": 128497209,
                "size_string": "123M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-win-opensource-src-4.4.2.zip",
                "version": "4.4.2"
            },
            {
                "file_name": "qt-x11-opensource-src-4.4.2.tar.gz",
                "md5": "d310e4aada6b726c141cb3cfa848edea",
                "platform": "unknow",
                "size": 112242405,
                "size_string": "107M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-x11-opensource-src-4.4.2.tar.gz",
                "version": "4.4.2"
            },
            {
                "file_name": "qt-win-opensource-4.4.2-mingw.exe",
                "md5": "2d682bbfd8b72443ae3552170d857a1f",
                "platform": "window",
                "size": 159414055,
                "size_string": "152M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.4/qt-win-opensource-4.4.2-mingw.exe",
                "version": "4.4.2"
            },
            {
                "file_name": "qt-mac-opensource-4.4.2.dmg",
                "md5": "129b90cb3385ed8009c652c459e6cc83",
                "platform": "mac",
                "size": 121658901,
                "size_string": "116M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.4/qt-mac-opensource-4.4.2.dmg",
                "version": "4.4.2"
            },
            {
                "file_name": "qt-mac-opensource-4.4.2-debug-libs.dmg",
                "md5": "a756039739e57471e9d18ba12adc4b89",
                "platform": "mac",
                "size": 200283617,
                "size_string": "191M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.4/qt-mac-opensource-4.4.2-debug-libs.dmg",
                "version": "4.4.2"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.4.2"
    },
    {
        "data": [
            {
                "file_name": "qt-all-opensource-src-4.4.1.tar.bz2",
                "md5": "e6d459b972c895aba962432c2bb30d4a",
                "platform": "unknow",
                "size": 100672304,
                "size_string": "96M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-all-opensource-src-4.4.1.tar.bz2",
                "version": "4.4.1"
            },
            {
                "file_name": "qt-embedded-linux-opensource-src-4.4.1.tar.gz",
                "md5": "0389060afeb90cbfbe95eefd7f3a1afd",
                "platform": "unknow",
                "size": 119866285,
                "size_string": "114M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-embedded-linux-opensource-src-4.4.1.tar.gz",
                "version": "4.4.1"
            },
            {
                "file_name": "qt-mac-opensource-src-4.4.1.tar.gz",
                "md5": "c925ab7adb68e5486bf8987ec32fdb0e",
                "platform": "unknow",
                "size": 106590772,
                "size_string": "102M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-mac-opensource-src-4.4.1.tar.gz",
                "version": "4.4.1"
            },
            {
                "file_name": "qt-all-opensource-src-4.4.1.tar.gz",
                "md5": "fc8fe8993629b453ae515486572ea913",
                "platform": "unknow",
                "size": 110175416,
                "size_string": "105M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-all-opensource-src-4.4.1.tar.gz",
                "version": "4.4.1"
            },
            {
                "file_name": "qt-win-opensource-src-4.4.1.zip",
                "md5": "9f8748ba6b1c66d2d036a1eac3fd6a69",
                "platform": "unknow",
                "size": 124912771,
                "size_string": "119M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-win-opensource-src-4.4.1.zip",
                "version": "4.4.1"
            },
            {
                "file_name": "qt-all-opensource-src-4.4.1.zip",
                "md5": "8ba31277bbfb8a18f9d2005ff2fd553e",
                "platform": "unknow",
                "size": 128989271,
                "size_string": "123M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-all-opensource-src-4.4.1.zip",
                "version": "4.4.1"
            },
            {
                "file_name": "qt-x11-opensource-src-4.4.1.tar.bz2",
                "md5": "a94a6f5ef01598bb9980891f2515ff45",
                "platform": "unknow",
                "size": 99481677,
                "size_string": "95M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-x11-opensource-src-4.4.1.tar.bz2",
                "version": "4.4.1"
            },
            {
                "file_name": "qt-embedded-linux-opensource-src-4.4.1.tar.bz2",
                "md5": "491d41cae681a2bab2656f8adc3ba503",
                "platform": "unknow",
                "size": 108363199,
                "size_string": "103M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-embedded-linux-opensource-src-4.4.1.tar.bz2",
                "version": "4.4.1"
            },
            {
                "file_name": "qt-embedded-wince-opensource-src-4.4.1.zip",
                "md5": "30709e461cbfeab657542006c9c5df11",
                "platform": "unknow",
                "size": 124839737,
                "size_string": "119M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-embedded-wince-opensource-src-4.4.1.zip",
                "version": "4.4.1"
            },
            {
                "file_name": "qt-x11-opensource-src-4.4.1.tar.gz",
                "md5": "b09decad252b5181f300135e3122481d",
                "platform": "unknow",
                "size": 108774120,
                "size_string": "104M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-x11-opensource-src-4.4.1.tar.gz",
                "version": "4.4.1"
            },
            {
                "file_name": "qt-mac-opensource-src-4.4.1.tar.bz2",
                "md5": "0ce82df48a606a1b25aa4a90f3a47839",
                "platform": "unknow",
                "size": 97299112,
                "size_string": "93M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-mac-opensource-src-4.4.1.tar.bz2",
                "version": "4.4.1"
            },
            {
                "file_name": "qt-win-opensource-4.4.1-mingw.exe",
                "md5": "60e85564c4b94c47e916d0a43b6f7ac2",
                "platform": "window",
                "size": 156435581,
                "size_string": "149M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.4/qt-win-opensource-4.4.1-mingw.exe",
                "version": "4.4.1"
            },
            {
                "file_name": "qt-mac-opensource-4.4.1-debug-libs.dmg",
                "md5": "66b1814cbf0014394ebdab43df187b36",
                "platform": "mac",
                "size": 203861147,
                "size_string": "194M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.4/qt-mac-opensource-4.4.1-debug-libs.dmg",
                "version": "4.4.1"
            },
            {
                "file_name": "qt-mac-opensource-4.4.1.dmg",
                "md5": "f6acc8fcbd74ec712d64ef814cded495",
                "platform": "mac",
                "size": 118800843,
                "size_string": "113M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.4/qt-mac-opensource-4.4.1.dmg",
                "version": "4.4.1"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.4.1"
    },
    {
        "data": [
            {
                "file_name": "qt-embedded-wince-opensource-src-4.4.0.zip",
                "md5": "0ccf2537ccfb7c1dbe76f926c43a6245",
                "platform": "unknow",
                "size": 124118898,
                "size_string": "118M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-embedded-wince-opensource-src-4.4.0.zip",
                "version": "4.4.0"
            },
            {
                "file_name": "qt-all-opensource-src-4.4.0.zip",
                "md5": "12e1d3b16c21d09b99e23d0d80931306",
                "platform": "unknow",
                "size": 128427784,
                "size_string": "122M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-all-opensource-src-4.4.0.zip",
                "version": "4.4.0"
            },
            {
                "file_name": "qt-mac-opensource-src-4.4.0.tar.bz2",
                "md5": "a3779e3c0803f9467f7ef7fe9b11cb8a",
                "platform": "unknow",
                "size": 96391735,
                "size_string": "92M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-mac-opensource-src-4.4.0.tar.bz2",
                "version": "4.4.0"
            },
            {
                "file_name": "qt-all-opensource-src-4.4.0.tar.gz",
                "md5": "2792aa7480ed6f44cf0881cb266ee662",
                "platform": "unknow",
                "size": 109675175,
                "size_string": "105M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-all-opensource-src-4.4.0.tar.gz",
                "version": "4.4.0"
            },
            {
                "file_name": "qt-x11-opensource-src-4.4.0.tar.bz2",
                "md5": "03ee0deb73908c84a7b4d9ceb5f8b3f6",
                "platform": "unknow",
                "size": 98584925,
                "size_string": "94M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-x11-opensource-src-4.4.0.tar.bz2",
                "version": "4.4.0"
            },
            {
                "file_name": "qt-embedded-linux-opensource-src-4.4.0.tar.gz",
                "md5": "6f249800af1174def0a0226e432cdfad",
                "platform": "unknow",
                "size": 119268018,
                "size_string": "114M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-embedded-linux-opensource-src-4.4.0.tar.gz",
                "version": "4.4.0"
            },
            {
                "file_name": "qt-mac-opensource-src-4.4.0.tar.gz",
                "md5": "628c1366545b7925c574d5931990ef19",
                "platform": "unknow",
                "size": 105863680,
                "size_string": "101M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-mac-opensource-src-4.4.0.tar.gz",
                "version": "4.4.0"
            },
            {
                "file_name": "qt-x11-opensource-src-4.4.0.tar.gz",
                "md5": "b333d68d14231b4b5c0a9142e44f26a0",
                "platform": "unknow",
                "size": 36651008,
                "size_string": "35M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-x11-opensource-src-4.4.0.tar.gz",
                "version": "4.4.0"
            },
            {
                "file_name": "qt-embedded-linux-opensource-src-4.4.0.tar.bz2",
                "md5": "e17ad8c9dfecc95a3fd2bd5ed1d7bd3a",
                "platform": "unknow",
                "size": 107676090,
                "size_string": "103M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-embedded-linux-opensource-src-4.4.0.tar.bz2",
                "version": "4.4.0"
            },
            {
                "file_name": "qt-win-opensource-src-4.4.0.zip",
                "md5": "170fc0f43bca9deeff7587bb7bd74fa1",
                "platform": "unknow",
                "size": 124174575,
                "size_string": "118M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-win-opensource-src-4.4.0.zip",
                "version": "4.4.0"
            },
            {
                "file_name": "qt-all-opensource-src-4.4.0.tar.bz2",
                "md5": "96bd581b13be072a50668bbffc56df3b",
                "platform": "unknow",
                "size": 99935838,
                "size_string": "95M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.4/qt-all-opensource-src-4.4.0.tar.bz2",
                "version": "4.4.0"
            },
            {
                "file_name": "qt-win-opensource-4.4.0-mingw.exe",
                "md5": "ca6d1f333064021264986f89dbd3ebd8",
                "platform": "window",
                "size": 94693111,
                "size_string": "90M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.4/qt-win-opensource-4.4.0-mingw.exe",
                "version": "4.4.0"
            },
            {
                "file_name": "qt-mac-opensource-4.4.0-debug-libs.dmg",
                "md5": "abfaf3ddcdf6128f4a95148cd18cc802",
                "platform": "mac",
                "size": 44768245,
                "size_string": "43M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.4/qt-mac-opensource-4.4.0-debug-libs.dmg",
                "version": "4.4.0"
            },
            {
                "file_name": "qt-mac-opensource-4.4.0.dmg",
                "md5": "b518638f836dcfe095edb3e611fad207",
                "platform": "mac",
                "size": 118334324,
                "size_string": "113M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.4/qt-mac-opensource-4.4.0.dmg",
                "version": "4.4.0"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.4.0"
    },
    {
        "data": [
            {
                "file_name": "qt-all-opensource-src-4.3.5.tar.bz2",
                "md5": "d5399ac73b52212b1abea4b4f83f9f2e",
                "platform": "unknow",
                "size": 56084289,
                "size_string": "53M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.3/qt-all-opensource-src-4.3.5.tar.bz2",
                "version": "4.3.5"
            },
            {
                "file_name": "qt-x11-opensource-src-4.3.5.tar.gz",
                "md5": "4e6234235431e768e18777ff32990cc2",
                "platform": "unknow",
                "size": 42719000,
                "size_string": "41M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.3/qt-x11-opensource-src-4.3.5.tar.gz",
                "version": "4.3.5"
            },
            {
                "file_name": "qt-x11-opensource-src-4.3.5.tar.bz2",
                "md5": "b29ccd007c223192e131ae573f4145df",
                "platform": "unknow",
                "size": 37009157,
                "size_string": "35M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.3/qt-x11-opensource-src-4.3.5.tar.bz2",
                "version": "4.3.5"
            },
            {
                "file_name": "qt-win-opensource-src-4.3.5.zip",
                "md5": "28fc5e2437f3ea3ee87695d9d72714a5",
                "platform": "unknow",
                "size": 51239536,
                "size_string": "49M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.3/qt-win-opensource-src-4.3.5.zip",
                "version": "4.3.5"
            },
            {
                "file_name": "qt-mac-opensource-src-4.3.5.tar.gz",
                "md5": "6307893b3b869296cf5cc95726827261",
                "platform": "unknow",
                "size": 40256530,
                "size_string": "38M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.3/qt-mac-opensource-src-4.3.5.tar.gz",
                "version": "4.3.5"
            },
            {
                "file_name": "qt-mac-opensource-src-4.3.5.tar.bz2",
                "md5": "daf316a0f526ad4673c4426b28d26468",
                "platform": "unknow",
                "size": 34502473,
                "size_string": "33M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.3/qt-mac-opensource-src-4.3.5.tar.bz2",
                "version": "4.3.5"
            },
            {
                "file_name": "qt-all-opensource-src-4.3.5.zip",
                "md5": "c8a643cb61428f30cd5b33a0789965e5",
                "platform": "unknow",
                "size": 74339663,
                "size_string": "71M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.3/qt-all-opensource-src-4.3.5.zip",
                "version": "4.3.5"
            },
            {
                "file_name": "qt-all-opensource-src-4.3.5.tar.gz",
                "md5": "02347cc6576964aaa6bde537905b7657",
                "platform": "unknow",
                "size": 62272413,
                "size_string": "59M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.3/qt-all-opensource-src-4.3.5.tar.gz",
                "version": "4.3.5"
            },
            {
                "file_name": "qt-win-opensource-4.3.5-mingw.exe",
                "md5": "60fbabdab78e67b851d5462cc421ac83",
                "platform": "window",
                "size": 74010454,
                "size_string": "71M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.3/qt-win-opensource-4.3.5-mingw.exe",
                "version": "4.3.5"
            },
            {
                "file_name": "qt-mac-opensource-4.3.5.dmg",
                "md5": "9505b312397b25e86186c1c10429d7e2",
                "platform": "mac",
                "size": 70664825,
                "size_string": "67M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.3/qt-mac-opensource-4.3.5.dmg",
                "version": "4.3.5"
            },
            {
                "file_name": "qt-mac-opensource-4.3.5-debug-libs.dmg",
                "md5": "d0ba6c911e56d2f76e66f37c58be0e08",
                "platform": "mac",
                "size": 218454697,
                "size_string": "208M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.3/qt-mac-opensource-4.3.5-debug-libs.dmg",
                "version": "4.3.5"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.3.5"
    },
    {
        "data": [
            {
                "file_name": "qt-win-opensource-src-4.3.4.zip",
                "md5": "5e28c253a26186bc9dae3a73661d2091",
                "platform": "unknow",
                "size": 51222949,
                "size_string": "49M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.3/qt-win-opensource-src-4.3.4.zip",
                "version": "4.3.4"
            },
            {
                "file_name": "qt-x11-opensource-src-4.3.4.tar.gz",
                "md5": "9499101ec54eb7b0de195b3c5e3ffa93",
                "platform": "unknow",
                "size": 43988710,
                "size_string": "42M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.3/qt-x11-opensource-src-4.3.4.tar.gz",
                "version": "4.3.4"
            },
            {
                "file_name": "qt-mac-opensource-src-4.3.4.tar.gz",
                "md5": "e3997f7c7620bcf03c635676c3b01fef",
                "platform": "unknow",
                "size": 41524198,
                "size_string": "40M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.3/qt-mac-opensource-src-4.3.4.tar.gz",
                "version": "4.3.4"
            },
            {
                "file_name": "qt-all-opensource-src-4.3.4.zip",
                "md5": "350f8737f1288f37480a2d3275c2c84c",
                "platform": "unknow",
                "size": 80492805,
                "size_string": "77M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.3/qt-all-opensource-src-4.3.4.zip",
                "version": "4.3.4"
            },
            {
                "file_name": "qt-all-opensource-src-4.3.4.tar.gz",
                "md5": "6c949df040cfe9e21ac40e49033172ed",
                "platform": "unknow",
                "size": 70060340,
                "size_string": "67M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.3/qt-all-opensource-src-4.3.4.tar.gz",
                "version": "4.3.4"
            },
            {
                "file_name": "qt-win-opensource-4.3.4-mingw.exe",
                "md5": "b144a026d05324a60839a01db426eba6",
                "platform": "window",
                "size": 73997299,
                "size_string": "71M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.3/qt-win-opensource-4.3.4-mingw.exe",
                "version": "4.3.4"
            },
            {
                "file_name": "qt-mac-opensource-4.3.4.dmg",
                "md5": "96a2a3b2f72b42f65ca9f0bd24a8cc8b",
                "platform": "mac",
                "size": 70457341,
                "size_string": "67M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.3/qt-mac-opensource-4.3.4.dmg",
                "version": "4.3.4"
            },
            {
                "file_name": "qt-mac-opensource-4.3.4-debug-libs.dmg",
                "md5": "d3aec262ce0f28e48e1ec9dcd3bbf5d5",
                "platform": "mac",
                "size": 218432917,
                "size_string": "208M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.3/qt-mac-opensource-4.3.4-debug-libs.dmg",
                "version": "4.3.4"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.3.4"
    },
    {
        "data": [
            {
                "file_name": "qt-mac-opensource-src-4.3.3.tar.gz",
                "md5": "62e53672dc843bb5fc4b4be1c7b92683",
                "platform": "unknow",
                "size": 41219944,
                "size_string": "39M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.3/qt-mac-opensource-src-4.3.3.tar.gz",
                "version": "4.3.3"
            },
            {
                "file_name": "qt-win-opensource-src-4.3.3.zip",
                "md5": "9d694d1b101e6c0d4433facce32f61af",
                "platform": "unknow",
                "size": 50532992,
                "size_string": "48M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.3/qt-win-opensource-src-4.3.3.zip",
                "version": "4.3.3"
            },
            {
                "file_name": "qt-x11-opensource-src-4.3.3.tar.gz",
                "md5": "19678fe35170559cd6a6fa531c57799c",
                "platform": "unknow",
                "size": 42341802,
                "size_string": "40M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.3/qt-x11-opensource-src-4.3.3.tar.gz",
                "version": "4.3.3"
            },
            {
                "file_name": "qt-all-opensource-src-4.3.3.tar.gz",
                "md5": "07d3e15d6d9a6aa219623897bd0aa2e5",
                "platform": "unknow",
                "size": 69737744,
                "size_string": "67M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.3/qt-all-opensource-src-4.3.3.tar.gz",
                "version": "4.3.3"
            },
            {
                "file_name": "qt-all-opensource-src-4.3.3.zip",
                "md5": "f448d933b72b7846b649977fd4f5e819",
                "platform": "unknow",
                "size": 79752066,
                "size_string": "76M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.3/qt-all-opensource-src-4.3.3.zip",
                "version": "4.3.3"
            },
            {
                "file_name": "qt-win-opensource-4.3.3-mingw.exe",
                "md5": "dd0ef73fff2c0e4296035a2f28f659fb",
                "platform": "window",
                "size": 73305385,
                "size_string": "70M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.3/qt-win-opensource-4.3.3-mingw.exe",
                "version": "4.3.3"
            },
            {
                "file_name": "qt-mac-opensource-4.3.3-debug-libs.dmg",
                "md5": "b880500eddeb688bc9587f776241556a",
                "platform": "mac",
                "size": 218416370,
                "size_string": "208M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.3/qt-mac-opensource-4.3.3-debug-libs.dmg",
                "version": "4.3.3"
            },
            {
                "file_name": "qt-mac-opensource-4.3.3.dmg",
                "md5": "7fbedce1795648827a9885b1a3974704",
                "platform": "mac",
                "size": 70220934,
                "size_string": "67M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.3/qt-mac-opensource-4.3.3.dmg",
                "version": "4.3.3"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.3.3"
    },
    {
        "data": [
            {
                "file_name": "qt-win-opensource-src-4.3.2.zip",
                "md5": "898082b12a3f4260fab24bc6148d3705",
                "platform": "unknow",
                "size": 50304274,
                "size_string": "48M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.3/qt-win-opensource-src-4.3.2.zip",
                "version": "4.3.2"
            },
            {
                "file_name": "qt-mac-opensource-src-4.3.2.tar.gz",
                "md5": "d22b10c59391028f4523c554436606a0",
                "platform": "unknow",
                "size": 40997458,
                "size_string": "39M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.3/qt-mac-opensource-src-4.3.2.tar.gz",
                "version": "4.3.2"
            },
            {
                "file_name": "qt-x11-opensource-src-4.3.2.tar.gz",
                "md5": "a60490b36099bdd10c4d2f55430075b3",
                "platform": "unknow",
                "size": 43462686,
                "size_string": "41M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.3/qt-x11-opensource-src-4.3.2.tar.gz",
                "version": "4.3.2"
            },
            {
                "file_name": "qt-all-opensource-src-4.3.2.zip",
                "md5": "5f04b96175a6565f7d5b5ff2f1c62ee2",
                "platform": "unknow",
                "size": 79399119,
                "size_string": "76M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.3/qt-all-opensource-src-4.3.2.zip",
                "version": "4.3.2"
            },
            {
                "file_name": "qt-all-opensource-src-4.3.2.tar.gz",
                "md5": "d2e6d80f2228fcff4910e39afa998421",
                "platform": "unknow",
                "size": 69384142,
                "size_string": "66M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.3/qt-all-opensource-src-4.3.2.tar.gz",
                "version": "4.3.2"
            },
            {
                "file_name": "qt-win-opensource-4.3.2-mingw.exe",
                "md5": "704409b6b79a6df91fe1d69b3a08721c",
                "platform": "window",
                "size": 73081421,
                "size_string": "70M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.3/qt-win-opensource-4.3.2-mingw.exe",
                "version": "4.3.2"
            },
            {
                "file_name": "qt-mac-opensource-4.3.2.dmg",
                "md5": "2a4076119a36161a31a3e3d7d1a09711",
                "platform": "mac",
                "size": 37761024,
                "size_string": "36M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.3/qt-mac-opensource-4.3.2.dmg",
                "version": "4.3.2"
            },
            {
                "file_name": "qt-mac-opensource-4.3.2-debug-libs.dmg",
                "md5": "2765975e2fc7da27f583b4321b030537",
                "platform": "mac",
                "size": 217732967,
                "size_string": "208M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.3/qt-mac-opensource-4.3.2-debug-libs.dmg",
                "version": "4.3.2"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.3.2"
    },
    {
        "data": [
            {
                "file_name": "qt-all-opensource-src-4.3.1.zip",
                "md5": "42e1d9dc12430b649f69b5de0354647f",
                "platform": "unknow",
                "size": 53556009,
                "size_string": "51M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.3/qt-all-opensource-src-4.3.1.zip",
                "version": "4.3.1"
            },
            {
                "file_name": "qt-mac-opensource-src-4.3.1.tar.gz",
                "md5": "adfb4196ee569d6ff5f7c7e539a76cd2",
                "platform": "unknow",
                "size": 40663473,
                "size_string": "39M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.3/qt-mac-opensource-src-4.3.1.tar.gz",
                "version": "4.3.1"
            },
            {
                "file_name": "qt-win-opensource-src-4.3.1.zip",
                "md5": "856701b07155fe9fd33f652ed34857e1",
                "platform": "unknow",
                "size": 49915986,
                "size_string": "48M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.3/qt-win-opensource-src-4.3.1.zip",
                "version": "4.3.1"
            },
            {
                "file_name": "qt-x11-opensource-src-4.3.1.tar.gz",
                "md5": "ec255dcc2b30f5efe0ded85ae8a75e13",
                "platform": "unknow",
                "size": 43118858,
                "size_string": "41M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.3/qt-x11-opensource-src-4.3.1.tar.gz",
                "version": "4.3.1"
            },
            {
                "file_name": "qt-all-opensource-src-4.3.1.tar.gz",
                "md5": "0b38a8316a8bebf19e7a9cd7af7fc5e6",
                "platform": "unknow",
                "size": 44398699,
                "size_string": "42M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.3/qt-all-opensource-src-4.3.1.tar.gz",
                "version": "4.3.1"
            },
            {
                "file_name": "qt-win-opensource-4.3.1-mingw.exe",
                "md5": "092452f3bafc4612d580a198229a5fe4",
                "platform": "window",
                "size": 72404998,
                "size_string": "69M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.3/qt-win-opensource-4.3.1-mingw.exe",
                "version": "4.3.1"
            },
            {
                "file_name": "qt-mac-opensource-4.3.1.dmg",
                "md5": "a79f6c064173f0595a5ffbca198e9858",
                "platform": "mac",
                "size": 69557562,
                "size_string": "66M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.3/qt-mac-opensource-4.3.1.dmg",
                "version": "4.3.1"
            },
            {
                "file_name": "qt-mac-opensource-4.3.1-debug-libs.dmg",
                "md5": "c5cd454a65a2ce21d1fcef2f870a94ac",
                "platform": "mac",
                "size": 217672665,
                "size_string": "208M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.3/qt-mac-opensource-4.3.1-debug-libs.dmg",
                "version": "4.3.1"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.3.1"
    },
    {
        "data": [
            {
                "file_name": "qt-all-opensource-src-4.3.0.tar.gz",
                "md5": "36ab1caf1cd22ec6108a334d36bcc516",
                "platform": "unknow",
                "size": 43183614,
                "size_string": "41M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.3/qt-all-opensource-src-4.3.0.tar.gz",
                "version": "4.3.0"
            },
            {
                "file_name": "qt-all-opensource-src-4.3.0.zip",
                "md5": "7d8ce2ba42fdfc655c5b12216894730f",
                "platform": "unknow",
                "size": 51421876,
                "size_string": "49M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.3/qt-all-opensource-src-4.3.0.zip",
                "version": "4.3.0"
            },
            {
                "file_name": "qt-mac-opensource-src-4.3.0.tar.gz",
                "md5": "97f81a43d75569eb0d2e34a1da0ad68f",
                "platform": "unknow",
                "size": 39502560,
                "size_string": "38M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.3/qt-mac-opensource-src-4.3.0.tar.gz",
                "version": "4.3.0"
            },
            {
                "file_name": "qt-x11-opensource-src-4.3.0.tar.gz",
                "md5": "8012acea71b35c18247bd92c4721589d",
                "platform": "unknow",
                "size": 41955515,
                "size_string": "40M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.3/qt-x11-opensource-src-4.3.0.tar.gz",
                "version": "4.3.0"
            },
            {
                "file_name": "qt-win-opensource-src-4.3.0.zip",
                "md5": "2898ad4a851735ff4d719377a7083e04",
                "platform": "unknow",
                "size": 47827361,
                "size_string": "46M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.3/qt-win-opensource-src-4.3.0.zip",
                "version": "4.3.0"
            },
            {
                "file_name": "qt-win-opensource-4.3.0-mingw.exe",
                "md5": "43e4930c1c97997fe3ecb12c9c384eda",
                "platform": "window",
                "size": 70123990,
                "size_string": "67M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.3/qt-win-opensource-4.3.0-mingw.exe",
                "version": "4.3.0"
            },
            {
                "file_name": "qt-mac-opensource-4.3.0-debug-libs.dmg",
                "md5": "e05fb1ccb78950c4d7bd748f1c44a52e",
                "platform": "mac",
                "size": 217400363,
                "size_string": "207M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.3/qt-mac-opensource-4.3.0-debug-libs.dmg",
                "version": "4.3.0"
            },
            {
                "file_name": "qt-mac-opensource-4.3.0.dmg",
                "md5": "d3f320008be9cb7bee82a776f74b8f3a",
                "platform": "mac",
                "size": 67638554,
                "size_string": "65M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.3/qt-mac-opensource-4.3.0.dmg",
                "version": "4.3.0"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.3.0"
    },
    {
        "data": [
            {
                "file_name": "qt-mac-opensource-src-4.2.3.tar.gz",
                "md5": "bc3230b553145033e26a89fac495508f",
                "platform": "unknow",
                "size": 35021093,
                "size_string": "33M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.2/qt-mac-opensource-src-4.2.3.tar.gz",
                "version": "4.2.3"
            },
            {
                "file_name": "qt-win-opensource-src-4.2.3.zip",
                "md5": "02995c1a149368f1d22e388d401d6d81",
                "platform": "unknow",
                "size": 42347766,
                "size_string": "40M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.2/qt-win-opensource-src-4.2.3.zip",
                "version": "4.2.3"
            },
            {
                "file_name": "qt-x11-opensource-src-4.2.3.tar.gz",
                "md5": "13f12bf58a32ebf15837fcd605cb3c99",
                "platform": "unknow",
                "size": 37635710,
                "size_string": "36M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.2/qt-x11-opensource-src-4.2.3.tar.gz",
                "version": "4.2.3"
            },
            {
                "file_name": "qt-win-opensource-4.2.3-mingw.exe",
                "md5": "781dde6cde6c7cb441beb53f888c70d3",
                "platform": "window",
                "size": 59547935,
                "size_string": "57M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.2/qt-win-opensource-4.2.3-mingw.exe",
                "version": "4.2.3"
            },
            {
                "file_name": "qt-mac-opensource-4.2.3-debug-libs.dmg",
                "md5": "0fa6be5707d6f9457bfac0afd1a06eb1",
                "platform": "mac",
                "size": 186053841,
                "size_string": "177M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.2/qt-mac-opensource-4.2.3-debug-libs.dmg",
                "version": "4.2.3"
            },
            {
                "file_name": "qt-mac-opensource-4.2.3.dmg",
                "md5": "28c023a6fe3a8967039226e63d444b68",
                "platform": "mac",
                "size": 56671010,
                "size_string": "54M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.2/qt-mac-opensource-4.2.3.dmg",
                "version": "4.2.3"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.2.3"
    },
    {
        "data": [
            {
                "file_name": "qt-mac-opensource-src-4.2.2.tar.gz",
                "md5": "bda29aff2875687d20478dcc9203932f",
                "platform": "unknow",
                "size": 35016663,
                "size_string": "33M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.2/qt-mac-opensource-src-4.2.2.tar.gz",
                "version": "4.2.2"
            },
            {
                "file_name": "qt-win-opensource-src-4.2.2.zip",
                "md5": "c230597b767cd6fd6bacf18d59c487b5",
                "platform": "unknow",
                "size": 42346101,
                "size_string": "40M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.2/qt-win-opensource-src-4.2.2.zip",
                "version": "4.2.2"
            },
            {
                "file_name": "qt-x11-opensource-src-4.2.2.tar.gz",
                "md5": "19f6374fe7924e33775cb87ee02669cb",
                "platform": "unknow",
                "size": 37636491,
                "size_string": "36M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.2/qt-x11-opensource-src-4.2.2.tar.gz",
                "version": "4.2.2"
            },
            {
                "file_name": "qt-win-opensource-4.2.2-mingw.exe",
                "md5": "5d9ac6358aa9f5a31762c6bcb95a6df0",
                "platform": "window",
                "size": 59529053,
                "size_string": "57M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.2/qt-win-opensource-4.2.2-mingw.exe",
                "version": "4.2.2"
            },
            {
                "file_name": "qt-mac-opensource-4.2.2-debug-libs.dmg",
                "md5": "fe6b0adc707970ddf88a2f47f6f62a8c",
                "platform": "mac",
                "size": 185714186,
                "size_string": "177M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.2/qt-mac-opensource-4.2.2-debug-libs.dmg",
                "version": "4.2.2"
            },
            {
                "file_name": "qt-mac-opensource-4.2.2.dmg",
                "md5": "0b25b40b6724d1473112ba1eb3a99cda",
                "platform": "mac",
                "size": 56716229,
                "size_string": "54M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.2/qt-mac-opensource-4.2.2.dmg",
                "version": "4.2.2"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.2.2"
    },
    {
        "data": [
            {
                "file_name": "qt-mac-opensource-src-4.2.1.tar.gz",
                "md5": "19667639943d5bae0325ec9d286f0c76",
                "platform": "unknow",
                "size": 34446487,
                "size_string": "33M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.2/qt-mac-opensource-src-4.2.1.tar.gz",
                "version": "4.2.1"
            },
            {
                "file_name": "qt-win-opensource-src-4.2.1.zip",
                "md5": "e5c7c3a5c6d3242c7fd54117bb27e258",
                "platform": "unknow",
                "size": 41787874,
                "size_string": "40M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.2/qt-win-opensource-src-4.2.1.zip",
                "version": "4.2.1"
            },
            {
                "file_name": "qt-x11-opensource-src-4.2.1.tar.gz",
                "md5": "2ab1c88084f55b94809f025a8503bf18",
                "platform": "unknow",
                "size": 37069122,
                "size_string": "35M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.2/qt-x11-opensource-src-4.2.1.tar.gz",
                "version": "4.2.1"
            },
            {
                "file_name": "qt-win-opensource-4.2.1-mingw.exe",
                "md5": "b408d3f35414060ac298d65a42fd66aa",
                "platform": "window",
                "size": 58953098,
                "size_string": "56M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.2/qt-win-opensource-4.2.1-mingw.exe",
                "version": "4.2.1"
            },
            {
                "file_name": "qt-mac-opensource-4.2.1-debug-libs.dmg",
                "md5": "340cbb745f5ea644664b31e1c1983a0f",
                "platform": "mac",
                "size": 185675188,
                "size_string": "177M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.2/qt-mac-opensource-4.2.1-debug-libs.dmg",
                "version": "4.2.1"
            },
            {
                "file_name": "qt-mac-opensource-4.2.1.dmg",
                "md5": "b7eb00b063ca6d4475a5358f34b9131d",
                "platform": "mac",
                "size": 56129667,
                "size_string": "54M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.2/qt-mac-opensource-4.2.1.dmg",
                "version": "4.2.1"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.2.1"
    },
    {
        "data": [
            {
                "file_name": "qt-mac-opensource-src-4.2.0.tar.gz",
                "md5": "913cc5ead7c34e5c4aa0e6112627056c",
                "platform": "unknow",
                "size": 34471288,
                "size_string": "33M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.2/qt-mac-opensource-src-4.2.0.tar.gz",
                "version": "4.2.0"
            },
            {
                "file_name": "qt-win-opensource-src-4.2.0.zip",
                "md5": "18b8cd685c93e9a2b8fa6c17b53318a3",
                "platform": "unknow",
                "size": 41803653,
                "size_string": "40M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.2/qt-win-opensource-src-4.2.0.zip",
                "version": "4.2.0"
            },
            {
                "file_name": "qt-x11-opensource-src-4.2.0.tar.gz",
                "md5": "2c062a125d5ca66397e3430ba537fbec",
                "platform": "unknow",
                "size": 37084418,
                "size_string": "35M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.2/qt-x11-opensource-src-4.2.0.tar.gz",
                "version": "4.2.0"
            },
            {
                "file_name": "qt-win-opensource-4.2.0-mingw.exe",
                "md5": "7bbce311aa969184b4a74ffef0917440",
                "platform": "window",
                "size": 58967998,
                "size_string": "56M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.2/qt-win-opensource-4.2.0-mingw.exe",
                "version": "4.2.0"
            },
            {
                "file_name": "qt-mac-opensource-4.2.0.dmg",
                "md5": "65237306eaca1dd1a7217a71849f6315",
                "platform": "mac",
                "size": 56025813,
                "size_string": "53M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.2/qt-mac-opensource-4.2.0.dmg",
                "version": "4.2.0"
            },
            {
                "file_name": "qt-mac-opensource-4.2.0-debug-libs.dmg",
                "md5": "70bb6d766fc76dbf972fd412c184074f",
                "platform": "mac",
                "size": 185673425,
                "size_string": "177M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.2/qt-mac-opensource-4.2.0-debug-libs.dmg",
                "version": "4.2.0"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.2.0"
    },
    {
        "data": [
            {
                "file_name": "qt-mac-opensource-src-4.1.5.tar.gz",
                "md5": "60f91a1aa218fb48b0824f2af6cf0381",
                "platform": "unknow",
                "size": 28229556,
                "size_string": "27M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.1/qt-mac-opensource-src-4.1.5.tar.gz",
                "version": "4.1.5"
            },
            {
                "file_name": "qt-win-opensource-src-4.1.5.zip",
                "md5": "55ff6f9c51f140203f01b3980b4ec296",
                "platform": "unknow",
                "size": 34916626,
                "size_string": "33M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.1/qt-win-opensource-src-4.1.5.zip",
                "version": "4.1.5"
            },
            {
                "file_name": "qt-x11-opensource-src-4.1.5.tar.gz",
                "md5": "4ef549ca642c57f3b217ee88670e56cb",
                "platform": "unknow",
                "size": 32173294,
                "size_string": "31M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.1/qt-x11-opensource-src-4.1.5.tar.gz",
                "version": "4.1.5"
            },
            {
                "file_name": "qt-win-opensource-4.1.5-mingw.exe",
                "md5": "32d2db74a8890763a9cf25b2999976c0",
                "platform": "window",
                "size": 49095043,
                "size_string": "47M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.1/qt-win-opensource-4.1.5-mingw.exe",
                "version": "4.1.5"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.1.5"
    },
    {
        "data": [
            {
                "file_name": "qt-win-opensource-src-4.1.4.zip",
                "md5": "ec7f27a2ab1ae8ee0951f5a2e40007a6",
                "platform": "unknow",
                "size": 34917071,
                "size_string": "33M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.1/qt-win-opensource-src-4.1.4.zip",
                "version": "4.1.4"
            },
            {
                "file_name": "qt-mac-opensource-src-4.1.4.tar.gz",
                "md5": "0aa268c8388e79897de9444fba46e6c8",
                "platform": "unknow",
                "size": 28230009,
                "size_string": "27M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.1/qt-mac-opensource-src-4.1.4.tar.gz",
                "version": "4.1.4"
            },
            {
                "file_name": "qt-x11-opensource-src-4.1.4.tar.gz",
                "md5": "6737b178e44f909fd93310c3bbad48f7",
                "platform": "unknow",
                "size": 32195638,
                "size_string": "31M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.1/qt-x11-opensource-src-4.1.4.tar.gz",
                "version": "4.1.4"
            },
            {
                "file_name": "qt-win-opensource-4.1.4-mingw.exe",
                "md5": "2cb4df90c728940152c697b217a705d3",
                "platform": "window",
                "size": 49106076,
                "size_string": "47M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.1/qt-win-opensource-4.1.4-mingw.exe",
                "version": "4.1.4"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.1.4"
    },
    {
        "data": [
            {
                "file_name": "qt-mac-opensource-src-4.1.3.tar.gz",
                "md5": "fda42772317ba5c22b68368a324d2ecf",
                "platform": "unknow",
                "size": 28257681,
                "size_string": "27M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.1/qt-mac-opensource-src-4.1.3.tar.gz",
                "version": "4.1.3"
            },
            {
                "file_name": "qt-win-opensource-src-4.1.3.zip",
                "md5": "5b80c58627875985e5b281b83e86fea1",
                "platform": "unknow",
                "size": 34938034,
                "size_string": "33M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.1/qt-win-opensource-src-4.1.3.zip",
                "version": "4.1.3"
            },
            {
                "file_name": "qt-x11-opensource-src-4.1.3.tar.gz",
                "md5": "c6fc6934bfca458dde6e6370a2ed0101",
                "platform": "unknow",
                "size": 32200887,
                "size_string": "31M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.1/qt-x11-opensource-src-4.1.3.tar.gz",
                "version": "4.1.3"
            },
            {
                "file_name": "qt-win-opensource-4.1.3-mingw.exe",
                "md5": "d03e7069d235d724d8ed37d2c906ec96",
                "platform": "window",
                "size": 49119555,
                "size_string": "47M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.1/qt-win-opensource-4.1.3-mingw.exe",
                "version": "4.1.3"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.1.3"
    },
    {
        "data": [
            {
                "file_name": "qt-mac-opensource-src-4.1.2.tar.gz",
                "md5": "59eb51a4ff7551093e36b54779d02b3b",
                "platform": "unknow",
                "size": 27924231,
                "size_string": "27M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.1/qt-mac-opensource-src-4.1.2.tar.gz",
                "version": "4.1.2"
            },
            {
                "file_name": "qt-x11-opensource-src-4.1.2.tar.gz",
                "md5": "18bca010d09b98e94210710047baca0a",
                "platform": "unknow",
                "size": 27924277,
                "size_string": "27M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.1/qt-x11-opensource-src-4.1.2.tar.gz",
                "version": "4.1.2"
            },
            {
                "file_name": "qt-win-opensource-src-4.1.2.zip",
                "md5": "3a3f189e436a3fbc7c526f495fbcb9dc",
                "platform": "unknow",
                "size": 33463990,
                "size_string": "32M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.1/qt-win-opensource-src-4.1.2.zip",
                "version": "4.1.2"
            },
            {
                "file_name": "qt-win-opensource-4.1.2-mingw.exe",
                "md5": "0cb951a54922779d5b83258caa11ae22",
                "platform": "window",
                "size": 47555512,
                "size_string": "45M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.1/qt-win-opensource-4.1.2-mingw.exe",
                "version": "4.1.2"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.1.2"
    },
    {
        "data": [
            {
                "file_name": "qt-mac-opensource-src-4.1.1.tar.gz",
                "md5": "a09fb1a86d9a090ffcc6f713990efce7",
                "platform": "unknow",
                "size": 27755736,
                "size_string": "26M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.1/qt-mac-opensource-src-4.1.1.tar.gz",
                "version": "4.1.1"
            },
            {
                "file_name": "qt-win-opensource-src-4.1.1.zip",
                "md5": "f11947758f041cffc3500c3a2098b18c",
                "platform": "unknow",
                "size": 33299152,
                "size_string": "32M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.1/qt-win-opensource-src-4.1.1.zip",
                "version": "4.1.1"
            },
            {
                "file_name": "qt-x11-opensource-src-4.1.1.tar.gz",
                "md5": "d82b0374d481f93e481aad4e3f26893a",
                "platform": "unknow",
                "size": 27761487,
                "size_string": "26M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.1/qt-x11-opensource-src-4.1.1.tar.gz",
                "version": "4.1.1"
            },
            {
                "file_name": "qtcanvas-opensource-4.1.1.tar.gz",
                "md5": "162ba37cf5bfd88c116b67fc2f81a396",
                "platform": "unknow",
                "size": 387867,
                "size_string": "379K",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.1/qtcanvas-opensource-4.1.1.tar.gz",
                "version": "4.1.1"
            },
            {
                "file_name": "qtcanvas-opensource-4.1.1.zip",
                "md5": "9ca372ea3285f803d04c07184b461704",
                "platform": "unknow",
                "size": 416474,
                "size_string": "407K",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.1/qtcanvas-opensource-4.1.1.zip",
                "version": "4.1.1"
            },
            {
                "file_name": "qt-win-opensource-4.1.1-mingw.exe",
                "md5": "09fd2c8ef220a3f660acd302dac0721b",
                "platform": "window",
                "size": 47389343,
                "size_string": "45M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.1/qt-win-opensource-4.1.1-mingw.exe",
                "version": "4.1.1"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.1.1"
    },
    {
        "data": [
            {
                "file_name": "qt-mac-opensource-src-4.1.0.tar.gz",
                "md5": "6afd1fb082b38bca7809fdd240cfaf33",
                "platform": "unknow",
                "size": 25624505,
                "size_string": "24M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.1/qt-mac-opensource-src-4.1.0.tar.gz",
                "version": "4.1.0"
            },
            {
                "file_name": "qt-x11-opensource-src-4.1.0.tar.gz",
                "md5": "0e3982a54f56b6420d3062b20524410a",
                "platform": "unknow",
                "size": 25634393,
                "size_string": "24M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.1/qt-x11-opensource-src-4.1.0.tar.gz",
                "version": "4.1.0"
            },
            {
                "file_name": "qt-win-opensource-src-4.1.0.zip",
                "md5": "830a87cd6192e545846c0edac318cd6c",
                "platform": "unknow",
                "size": 31478357,
                "size_string": "30M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.1/qt-win-opensource-src-4.1.0.zip",
                "version": "4.1.0"
            },
            {
                "file_name": "qt-win-opensource-4.1.0-mingw.exe",
                "md5": "741c22db60e7b16a07e49e7a155c88a7",
                "platform": "window",
                "size": 45495249,
                "size_string": "43M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.1/qt-win-opensource-4.1.0-mingw.exe",
                "version": "4.1.0"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.1.0"
    },
    {
        "data": [
            {
                "file_name": "qt-embedded-preview-src-4.0.1.tar.gz",
                "md5": "8d51b15a1f5445dd0d467288883b3d81",
                "platform": "unknow",
                "size": 25544179,
                "size_string": "24M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.0/qt-embedded-preview-src-4.0.1.tar.gz",
                "version": "4.0.1"
            },
            {
                "file_name": "qt-x11-opensource-src-4.0.1.tar.gz",
                "md5": "2db4a4d797d37d0605b776420327503a",
                "platform": "unknow",
                "size": 20141717,
                "size_string": "19M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.0/qt-x11-opensource-src-4.0.1.tar.gz",
                "version": "4.0.1"
            },
            {
                "file_name": "qt-win-opensource-src-4.0.1.zip",
                "md5": "9d485bc43223e0ceacf8d45bcec3f923",
                "platform": "unknow",
                "size": 25862201,
                "size_string": "25M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.0/qt-win-opensource-src-4.0.1.zip",
                "version": "4.0.1"
            },
            {
                "file_name": "qt-mac-opensource-src-4.0.1.tar.gz",
                "md5": "caaa089d49832ec96582248a4426ba6f",
                "platform": "unknow",
                "size": 20130910,
                "size_string": "19M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.0/qt-mac-opensource-src-4.0.1.tar.gz",
                "version": "4.0.1"
            },
            {
                "file_name": "qt-win-opensource-4.0.1-mingw.exe",
                "md5": "b3885cb5c0bb7d6a13d1bb15f6c4b52e",
                "platform": "window",
                "size": 37219052,
                "size_string": "35M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.0/qt-win-opensource-4.0.1-mingw.exe",
                "version": "4.0.1"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.0.1"
    },
    {
        "data": [
            {
                "file_name": "qt-mac-opensource-desktop-4.0.0.tar.gz",
                "md5": "d3d42f17b713d2c1410e5e404030319d",
                "platform": "unknow",
                "size": 19161322,
                "size_string": "18M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.0/qt-mac-opensource-desktop-4.0.0.tar.gz",
                "version": "4.0.0"
            },
            {
                "file_name": "qt-x11-opensource-desktop-4.0.0.tar.gz",
                "md5": "58735ad4a8e982f8b1b3bfc71841f1f6",
                "platform": "unknow",
                "size": 19200686,
                "size_string": "18M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.0/qt-x11-opensource-desktop-4.0.0.tar.gz",
                "version": "4.0.0"
            },
            {
                "file_name": "qt-win-opensource-desktop-4.0.0.zip",
                "md5": "fa8ad4c3e722d12b01ada1ccba3d66b6",
                "platform": "unknow",
                "size": 25574853,
                "size_string": "24M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.0/qt-win-opensource-desktop-4.0.0.zip",
                "version": "4.0.0"
            },
            {
                "file_name": "qt-mac-opensource-desktop-4.0.0.tar.bz2",
                "md5": "df117a5dae42b3a60f9aa96a0a563fc1",
                "platform": "unknow",
                "size": 15634917,
                "size_string": "15M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.0/qt-mac-opensource-desktop-4.0.0.tar.bz2",
                "version": "4.0.0"
            },
            {
                "file_name": "qt-x11-opensource-desktop-4.0.0.tar.bz2",
                "md5": "a6183269fab293282daf2da9ac940577",
                "platform": "unknow",
                "size": 15636509,
                "size_string": "15M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/4.0/qt-x11-opensource-desktop-4.0.0.tar.bz2",
                "version": "4.0.0"
            },
            {
                "file_name": "qt-win-opensource-desktop-4.0.0-mingw.exe",
                "md5": "c44c43e5aaa84b48934d958e108cff20",
                "platform": "window",
                "size": 36838373,
                "size_string": "35M",
                "type": "bin",
                "url": "https://download.qt.io/archive/qt/4.0/qt-win-opensource-desktop-4.0.0-mingw.exe",
                "version": "4.0.0"
            }
        ],
        "preview_list": [
            "source",
            "bin"
        ],
        "version": "4.0.0"
    },
    {
        "data": [
            {
                "file_name": "qt-embedded-2.3.10-free.tar.gz",
                "md5": "1f7ad30113afc500cab7f5b2f4dec0d7",
                "platform": "unknow",
                "size": 18178103,
                "size_string": "17M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/2/qt-embedded-2.3.10-free.tar.gz",
                "version": "2.3.10"
            }
        ],
        "preview_list": [
            "source"
        ],
        "version": "2.3.10"
    },
    {
        "data": [
            {
                "file_name": "qt-embedded-2.3.8.tar.gz",
                "md5": "929a49159fb3545e98b8c3c9243e9bc4",
                "platform": "unknow",
                "size": 15633004,
                "size_string": "15M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/2/qt-embedded-2.3.8.tar.gz",
                "version": "2.3.8"
            },
            {
                "file_name": "qt-embedded-2.3.8.tar.bz2",
                "md5": "17c4e2af42bedf9bec89fcbfcf560efa",
                "platform": "unknow",
                "size": 13526629,
                "size_string": "13M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/2/qt-embedded-2.3.8.tar.bz2",
                "version": "2.3.8"
            }
        ],
        "preview_list": [
            "source"
        ],
        "version": "2.3.8"
    },
    {
        "data": [
            {
                "file_name": "qt-embedded-2.3.7.tar.bz2",
                "md5": "6ba9206270365a283746503df6c1b993",
                "platform": "unknow",
                "size": 13539549,
                "size_string": "13M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/2/qt-embedded-2.3.7.tar.bz2",
                "version": "2.3.7"
            },
            {
                "file_name": "qt-embedded-2.3.7.tar.gz",
                "md5": "d435922e6278b19febc561c47c64d940",
                "platform": "unknow",
                "size": 15815386,
                "size_string": "15M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/2/qt-embedded-2.3.7.tar.gz",
                "version": "2.3.7"
            }
        ],
        "preview_list": [
            "source"
        ],
        "version": "2.3.7"
    },
    {
        "data": [
            {
                "file_name": "qt-embedded-2.3.6.tar.gz",
                "md5": "cc56f76ee5c92babde6ebeb90380975b",
                "platform": "unknow",
                "size": 15810564,
                "size_string": "15M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/2/qt-embedded-2.3.6.tar.gz",
                "version": "2.3.6"
            }
        ],
        "preview_list": [
            "source"
        ],
        "version": "2.3.6"
    },
    {
        "data": [
            {
                "file_name": "qt-embedded-2.3.5.tar.gz",
                "md5": "219270bef60207af7f4289446c75d575",
                "platform": "unknow",
                "size": 16108533,
                "size_string": "15M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/2/qt-embedded-2.3.5.tar.gz",
                "version": "2.3.5"
            }
        ],
        "preview_list": [
            "source"
        ],
        "version": "2.3.5"
    },
    {
        "data": [
            {
                "file_name": "qt-embedded-2.3.4.tar.gz",
                "md5": "619db49e09c84d50fae052601033cb89",
                "platform": "unknow",
                "size": 16910130,
                "size_string": "16M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/2/qt-embedded-2.3.4.tar.gz",
                "version": "2.3.4"
            }
        ],
        "preview_list": [
            "source"
        ],
        "version": "2.3.4"
    },
    {
        "data": [
            {
                "file_name": "qt-embedded-2.3.2.tar.gz",
                "md5": "2ec26eab6252d1e1d5c9a19f5b11ecda",
                "platform": "unknow",
                "size": 15140710,
                "size_string": "14M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/2/qt-embedded-2.3.2.tar.gz",
                "version": "2.3.2"
            },
            {
                "file_name": "qt-x11-2.3.2.tar.gz",
                "md5": "8b74c7bd9ff0c4752a009f2e5a6482e4",
                "platform": "unknow",
                "size": 8978412,
                "size_string": "8.6M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/2/qt-x11-2.3.2.tar.gz",
                "version": "2.3.2"
            }
        ],
        "preview_list": [
            "source"
        ],
        "version": "2.3.2"
    },
    {
        "data": [
            {
                "file_name": "qt-embedded-2.3.1.tar.gz",
                "md5": "d8eef21389da840aa6ff96ef91e191d4",
                "platform": "unknow",
                "size": 14846928,
                "size_string": "14M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/2/qt-embedded-2.3.1.tar.gz",
                "version": "2.3.1"
            },
            {
                "file_name": "qt-x11-2.3.1.tar.gz",
                "md5": "358d6dfbcbdc353b65b5236866b0f3a7",
                "platform": "unknow",
                "size": 8967271,
                "size_string": "8.6M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/2/qt-x11-2.3.1.tar.gz",
                "version": "2.3.1"
            }
        ],
        "preview_list": [
            "source"
        ],
        "version": "2.3.1"
    },
    {
        "data": [
            {
                "file_name": "qt-embedded-2.3.0.tar.gz",
                "md5": "b4fc2e3aabcd7b61327f091379cf3218",
                "platform": "unknow",
                "size": 14351758,
                "size_string": "14M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/2/qt-embedded-2.3.0.tar.gz",
                "version": "2.3.0"
            },
            {
                "file_name": "qt-x11-2.3.0.tar.gz",
                "md5": "f4993d952b9380a5009735aa1b7b9c03",
                "platform": "unknow",
                "size": 8664303,
                "size_string": "8.3M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/2/qt-x11-2.3.0.tar.gz",
                "version": "2.3.0"
            }
        ],
        "preview_list": [
            "source"
        ],
        "version": "2.3.0"
    },
    {
        "data": [
            {
                "file_name": "qt-embedded-2.2.4.tar.gz",
                "md5": "b075d940b0e86ca2cde5b4cb3d7de73a",
                "platform": "unknow",
                "size": 14154738,
                "size_string": "13M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/2/qt-embedded-2.2.4.tar.gz",
                "version": "2.2.4"
            },
            {
                "file_name": "qt-x11-2.2.4.tar.gz",
                "md5": "0a5cb173ded05c6b6ea620396d16889d",
                "platform": "unknow",
                "size": 8547718,
                "size_string": "8.2M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/2/qt-x11-2.2.4.tar.gz",
                "version": "2.2.4"
            }
        ],
        "preview_list": [
            "source"
        ],
        "version": "2.2.4"
    },
    {
        "data": [
            {
                "file_name": "qt-embedded-2.2.3.tar.gz",
                "md5": "4ca1cfca360ac2cf8f16465144b2ab88",
                "platform": "unknow",
                "size": 12867461,
                "size_string": "12M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/2/qt-embedded-2.2.3.tar.gz",
                "version": "2.2.3"
            },
            {
                "file_name": "qt-x11-2.2.3.tar.gz",
                "md5": "964ca0e7e641271a2f5706a0e4383fcf",
                "platform": "unknow",
                "size": 8494575,
                "size_string": "8.1M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/2/qt-x11-2.2.3.tar.gz",
                "version": "2.2.3"
            }
        ],
        "preview_list": [
            "source"
        ],
        "version": "2.2.3"
    },
    {
        "data": [
            {
                "file_name": "qt-embedded-2.2.2.tar.gz",
                "md5": "5eda372dc155a913c20c98f784f93bb4",
                "platform": "unknow",
                "size": 13102873,
                "size_string": "12M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/2/qt-embedded-2.2.2.tar.gz",
                "version": "2.2.2"
            },
            {
                "file_name": "qt-x11-2.2.2.tar.gz",
                "md5": "682e8f62af48770aea52036eb6329792",
                "platform": "unknow",
                "size": 8468985,
                "size_string": "8.1M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/2/qt-x11-2.2.2.tar.gz",
                "version": "2.2.2"
            },
            {
                "file_name": "qt-embedded-2.2.2-4bpp_grey.diff.gz",
                "md5": "0b55e6876d0616121ce049ef735664d1",
                "platform": "unknow",
                "size": 4968,
                "size_string": "4.9K",
                "type": "other",
                "url": "https://download.qt.io/archive/qt/2/qt-embedded-2.2.2-4bpp_grey.diff.gz",
                "version": "2.2.2"
            }
        ],
        "preview_list": [
            "source",
            "other"
        ],
        "version": "2.2.2"
    },
    {
        "data": [
            {
                "file_name": "qt-x11-2.2.1.tar.gz",
                "md5": "c5a65c4ded0330fa6b44c24fd93b938e",
                "platform": "unknow",
                "size": 8567286,
                "size_string": "8.2M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/2/qt-x11-2.2.1.tar.gz",
                "version": "2.2.1"
            }
        ],
        "preview_list": [
            "source"
        ],
        "version": "2.2.1"
    },
    {
        "data": [
            {
                "file_name": "qt-x11-2.2.0.tar.gz",
                "md5": "dd0a736e44a6a2229ade3822e94b86ef",
                "platform": "unknow",
                "size": 8436571,
                "size_string": "8.0M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/2/qt-x11-2.2.0.tar.gz",
                "version": "2.2.0"
            }
        ],
        "preview_list": [
            "source"
        ],
        "version": "2.2.0"
    },
    {
        "data": [
            {
                "file_name": "qt-x11-2.1.1.tar.gz",
                "md5": "4af3035d2ce5ada50faa6a092539efe4",
                "platform": "unknow",
                "size": 5651883,
                "size_string": "5.4M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/2/qt-x11-2.1.1.tar.gz",
                "version": "2.1.1"
            }
        ],
        "preview_list": [
            "source"
        ],
        "version": "2.1.1"
    },
    {
        "data": [
            {
                "file_name": "qt-x11-2.1.0.tar.gz",
                "md5": "b5d53d50de7f1ad7c1b8b5b0e6745b98",
                "platform": "unknow",
                "size": 5611121,
                "size_string": "5.4M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/2/qt-x11-2.1.0.tar.gz",
                "version": "2.1.0"
            }
        ],
        "preview_list": [
            "source"
        ],
        "version": "2.1.0"
    },
    {
        "data": [
            {
                "file_name": "qt-2.0.2.tar.gz",
                "md5": "afeaf554f538b415a2f46ff6b36657f4",
                "platform": "unknow",
                "size": 4405389,
                "size_string": "4.2M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/2/qt-2.0.2.tar.gz",
                "version": "2.0.2"
            }
        ],
        "preview_list": [
            "source"
        ],
        "version": "2.0.2"
    },
    {
        "data": [
            {
                "file_name": "qt-2.0.1.tar.gz",
                "md5": "32fe2ccb734d019d1156667e1f303495",
                "platform": "unknow",
                "size": 4349940,
                "size_string": "4.1M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/2/qt-2.0.1.tar.gz",
                "version": "2.0.1"
            }
        ],
        "preview_list": [
            "source"
        ],
        "version": "2.0.1"
    },
    {
        "data": [
            {
                "file_name": "qt-2.00.tar.gz",
                "md5": "53d26f7adcf916c3f5e884982e6821ec",
                "platform": "unknow",
                "size": 3902129,
                "size_string": "3.7M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/2/qt-2.00.tar.gz",
                "version": "2.0.0"
            }
        ],
        "preview_list": [
            "source"
        ],
        "version": "2.0.0"
    },
    {
        "data": [
            {
                "file_name": "qt-1.45.tar.gz",
                "md5": "0602a23f58e436dda670ae50e37c9765",
                "platform": "unknow",
                "size": 2725237,
                "size_string": "2.6M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/1/qt-1.45.tar.gz",
                "version": "1.45.0"
            }
        ],
        "preview_list": [
            "source"
        ],
        "version": "1.45.0"
    },
    {
        "data": [
            {
                "file_name": "qt-1.44.tar.gz",
                "md5": "143a6b7001c04843c304c96cfe608ed8",
                "platform": "unknow",
                "size": 2659999,
                "size_string": "2.5M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/1/qt-1.44.tar.gz",
                "version": "1.44.0"
            }
        ],
        "preview_list": [
            "source"
        ],
        "version": "1.44.0"
    },
    {
        "data": [
            {
                "file_name": "qt-1.43.tar.gz",
                "md5": "a2b9fdddf448957be71cbf8c91895b14",
                "platform": "unknow",
                "size": 2640306,
                "size_string": "2.5M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/1/qt-1.43.tar.gz",
                "version": "1.43.0"
            }
        ],
        "preview_list": [
            "source"
        ],
        "version": "1.43.0"
    },
    {
        "data": [
            {
                "file_name": "qt-1.42.tar.gz",
                "md5": "0d1b32b6838d33074fe84088a2942028",
                "platform": "unknow",
                "size": 2643205,
                "size_string": "2.5M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/1/qt-1.42.tar.gz",
                "version": "1.42.0"
            }
        ],
        "preview_list": [
            "source"
        ],
        "version": "1.42.0"
    },
    {
        "data": [
            {
                "file_name": "qt-1.41.tar.gz",
                "md5": "a44bc8972a6997c63f5c34820b0f4901",
                "platform": "unknow",
                "size": 2632942,
                "size_string": "2.5M",
                "type": "source",
                "url": "https://download.qt.io/archive/qt/1/qt-1.41.tar.gz",
                "version": "1.41.0"
            }
        ],
        "preview_list": [
            "source"
        ],
        "version": "1.41.0"
    }
]

                    export default {
                        qt_package_url_list: function() {
                        return qt_package_url_list;
                    }
                    }