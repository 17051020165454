<template>
  <div id="app">
    <div v-wechat-title="$route.meta.title" ></div>
    <v-app>
      <v-app-bar id="app-bar" dense app>
        <v-toolbar-title>
          <router-link to="/" style="color: gray; text-decoration: none;">Qt小站</router-link> 
        </v-toolbar-title>

        <v-divider inset vertical class="mx-4"></v-divider>

        <v-toolbar-items>
            <v-btn v-for="item in items" :key="item.title" v-bind="{to: item.href}" text tile>
                {{item.title}}
            </v-btn>
        </v-toolbar-items>
      </v-app-bar>
      <v-main>
        <router-view/>
      </v-main>
    </v-app>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
		  		{
					title: "首页",
					href: "/"
				},
				{
					title: "文档",
					href: "/doc"
				},
				{
					title: "下载",
					href: "/download"
				},
				{
					title: "Qml在线工具",
					href: "/tool/qmlonline"
				},
				{
					title: "正则测试工具",
					href: "/tool/regularexpression"
				},
				{
					title: "正则代码生成器",
					href: "/tool/RegexpCode"
				},
				{
					title: "窗口置顶工具",
					href: "/tool/WindowTop"
				},
				{
					title: "关于",
					href: "/about"
				},
			]
    }
  },
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
